import { Store, createStore, applyMiddleware, compose, Reducer, AnyAction } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { mergePersistedState } from 'redux-localstorage';
import rootSage from './store/sagas';

import { AppState, createRootReducer } from './store';
import { FormStateMap } from 'redux-form';
import { LoadingType } from './store/loading/types';
import { Profile } from './store/profile/types';
import { Authentication } from "./store/authentication/types";
import { Cultures } from "./store/cultures/types";
import { LegalInfo } from "./store/legalInfo/types";
import { FieldsMetaData } from './store/fieldsMetadata/types';
import { DashboardItems } from './store/dashboadItems/types';

export default function configureStore(): Store<AppState> {
	const composeEnhancers =
		(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const sagaMiddleware = createSagaMiddleware();

  const reducer = (createRootReducer()) as Reducer<{
		form: FormStateMap,
		profile: Profile,
		loading: LoadingType,
		authentication: Authentication,
		cultures: Cultures,
		legalInfo: LegalInfo,
		fieldsMetadata: FieldsMetaData,
		dashboardItems: DashboardItems
  }, AnyAction>;

	const store = createStore(
		reducer,
		composeEnhancers(applyMiddleware(sagaMiddleware))
	);

  sagaMiddleware.run(rootSage);


  return store;
}
