import fetchApiWrapperWithTimeout from './fetchApiWrapperWithTimeout';
import settings from '../config/settings';
import { RecommendedApplicationsClientIds } from './user';
import { ItemTranslations } from '../components/UserDashboard/Types';

export interface ApplicationData {
    "application_infos": Application[];
}

export interface Application {
    client_id: string;
    name: string,
    description?: string,
    url: string,
    allowed_callback: string,
    app_id: string,
    app_type: string,
    dip_app_type: string,
    mobile_app_info: MobileAppInfo[],
    uses_mfa: boolean,
    display_order?: number,
    hidden?: boolean
    translations?: ItemTranslations
}

export interface MobileAppInfo {
    os: string,
    id? : string,
    deep_link: string    
}

export const getApplication = (clientId: string, pageLanguage: string): Promise<Application> => {

    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Accept-Language', pageLanguage);
        
    return fetchApiWrapperWithTimeout(`${settings.backend.endpoint}/applications/${clientId}`, {
        headers: requestHeaders,
        method: 'GET',
      }, (!isNaN(settings.backend.timeout) && settings.backend.timeout) || 10000);
}

export const getApplicationByName = (clientName: string, pageLanguage: string): Promise<Application> => {

    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Accept-Language', pageLanguage);
    
    return fetchApiWrapperWithTimeout(`${settings.backend.endpoint}/applications/${clientName}?idType=name`, {
        headers: requestHeaders,
        method: 'GET',
      }, (!isNaN(settings.backend.timeout) && settings.backend.timeout) || 10000);
}

export const getApplicationData = (client_ids: RecommendedApplicationsClientIds, pageLanguage: string): Promise<ApplicationData> => {

    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Accept-Language', pageLanguage);
    
    return fetchApiWrapperWithTimeout(`${settings.backend.endpoint}/applications?idType=client_id`, {
        body: JSON.stringify(client_ids),
        headers: requestHeaders,
        method: 'POST',
      }, (!isNaN(settings.backend.timeout) && settings.backend.timeout) || 10000);
}