import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	margin: {
		margin: '20px 0 20px',
		maxWidth: '466px'
	},
	close: {
		cursor: 'pointer',
		width: '16px',
		right: '12px',
		top: '12px',
		position: 'absolute'
	},
	root: {
		width: 'auto',
		maxWidth: '468px',
		margin: '26px 36px 36px'
	},
	header: {
		fontSize: '28px',
		fontWeight: 'bold',
		letterSpacing: '0.14px',
		color: colors.webexuiDarkGrayish,
		marginBottom: '10px',
		lineHeight: '48px'
	},
	buttonContainer: {
		marginTop: '40px'
	},
	button: {
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			margin: '0px 0px 10px !important'
		},
		[theme.breakpoints.up('sm')]: {
			marginRight: '20px'
		}
	},
	popupContainer: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		position: 'absolute',
		top: '-50px'
	},
	popup: {
		top: '300px',
		maxWidth: '562px',
		position: 'relative',
		padding: '0px !important',
		zIndex: 10000
	},
	icon: {
		marginRight: '7px'
	},
	validationErrorContainer: {
		marginBottom: '40px',
		maxWidth: '468px',
		flexFlow: 'row'
	},
	validationErrorContainerLeaveConfirm: {
		maxWidth: '468px',
		flexFlow: 'row',
		marginTop: '10px'
	},
	validationErrorText: {
		color: colors.webexuiRed,
		fontSize: '16px'
	},
	validationErrorIcon: {
		lineHeight: '28px'
	},
	formEditConfirmRoot: {
		[theme.breakpoints.up('sm')]: {
			maxWidth: '468px',
			margin: '26px 36px 36px'
		},
		[theme.breakpoints.only('xs')]: {
			display: 'table-cell',
			padding: '30px 20px 20px'
		}
	},
	formEditConfirmHeader: {
		fontSize: '28px',
		fontWeight: 'bold',
		letterSpacing: '0.14px',
		color: colors.webexuiDarkGrayish,
		marginBottom: '10px',
		[theme.breakpoints.up('sm')]: {
			lineHeight: '48px'
		},
		[theme.breakpoints.only('xs')]: {
			lineHeight: '35px'
		}
	},
	formEditConfirmButtonContainer: {
		[theme.breakpoints.up('sm')]: {
			marginTop: '40px'
		},
		[theme.breakpoints.only('xs')]: {
			marginTop: '25px'
		}
	},
	formEditConfirmButton: {
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			margin: '0px 0px 10px !important'
		},
		[theme.breakpoints.up('sm')]: {
			marginRight: '20px'
		}
	},
	formEditConfirmClose: {
		cursor: 'pointer',
		width: '16px',
		[theme.breakpoints.only('xs')]: {
			margin: '12px -28px',
		},
		[theme.breakpoints.up('sm')]: {
			right: '12px',
			top: '12px'
		},
		position: 'absolute'
	},
	formEditConfirmConfirmationPopupContainer: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		position: 'absolute',
		top: '-50px'
	},
	formEditConfirmConfirmationPopup: {
		[theme.breakpoints.only('xs')]: {
			top: '20px'
		},
		[theme.breakpoints.up('sm')]: {
			top: '100px'
		},
		maxWidth: '562px',
		position: 'relative',
		padding: '0px !important'
	}

}));