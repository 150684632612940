import React, { useEffect, useState } from 'react';
import { InjectedFormProps, reduxForm, formValueSelector, reset } from 'redux-form';
import { connect, useDispatch } from 'react-redux'
import { urlJoin } from 'url-join-ts';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { useStyles } from './Styles';
import { useSharedStyles } from '../../sharedStyles/Styles';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import { renderUsername, renderCurrentPassword, renderNewPassword, renderRetypeNewPassword } from './FieldRenderer';
import validate from './validation'
import { useTranslation } from 'react-i18next';
import settings from '../../config/settings';
import { ChangePasswordProps, MissingRulesDescription, RuleItem, Rules, Values } from './Types';
import { isMobileDevice } from '../../utils';
import ModalPopup from '../Modal/ModalPopup';
import { useNavigate } from "react-router-dom";
import { PasswordComplexityApiModel } from '../../api/connection';
import Button from '@material-ui/core/Button';

// Password complexity 
// @ts-ignore
import { PasswordPolicy } from 'password-sheriff';
// @ts-ignore
import policies from 'auth0-password-policies';
// @ts-ignore
import { sprintf } from 'sprintf-js';
import parse from 'html-react-parser';

const asyncValidate = (values: Values, dispatch: any, props: any) => {
  return new Promise((resolve, reject) => {
    const errors = validate(values, props);
    if (Object.keys(errors).length === 0) {
      resolve(true);
    }
    throw (errors);
  });
}

const getMissingRulesDescriptions = (missingRulesDescriptions: MissingRulesDescription, classes: any, t: Function) => {
  const errorMessageDesc = missingRulesDescriptions.rules.reduce((ruleErrorMessage: string, rule: Rules) => {

    let itemCode = '';

    if (rule.items) {
      const itemErrorMessage = rule.items.reduce((errorMes: string, item: RuleItem) => {
        itemCode = `privacy_and_security.validation_rules.${item.code}`;
        errorMes += `<span class="${classes.complexityRule}"><img src="${item.verified ? urlJoin(settings.staticFiles.endpoint, '/images/system/ErrorOptionChecked.svg') : urlJoin(settings.staticFiles.endpoint, '/images/system/ErrorOption.svg')}" class="${classes.complexityInfoIcon}"/> <span class="${classes.complexityInfoText}">${t(itemCode)}</span></span>`
        return errorMes;
      }, "")

      itemCode = `privacy_and_security.validation_rules.${rule.code}`;
      ruleErrorMessage += `<span class="${classes.complexityRuleDescription}"><img src="${rule.verified ? urlJoin(settings.staticFiles.endpoint, '/images/system/ErrorOptionChecked.svg') : urlJoin(settings.staticFiles.endpoint, '/images/system/ErrorInformationRed.svg')}" class="${classes.complexityInfoIcon}"/> <div class="${classes.complexityInfoText}">${sprintf(t(itemCode), ...rule.format)}</div></span> ${itemErrorMessage}`
      return ruleErrorMessage;

    } else {
      itemCode = `privacy_and_security.validation_rules.${rule.code}`;
      ruleErrorMessage += `<span class="${classes.complexityLength}"><img src="${rule.verified ? urlJoin(settings.staticFiles.endpoint, '/images/system/ErrorOptionChecked.svg') : urlJoin(settings.staticFiles.endpoint, '/images/system/ErrorInformationRed.svg')}" class="${classes.complexityInfoIcon}"/> <span class="${classes.complexityInfoText}">${sprintf(t(itemCode), rule.format)}</span></span>`
      return ruleErrorMessage;
    }
  }, "")

  return parse(errorMessageDesc);
}

const checkAgainstPasswordRules = (newPassword: string, passwordComplexity: PasswordComplexityApiModel, classes: any, t: Function) => {
  if (!passwordComplexity || Object.keys(passwordComplexity).length === 0) return '';

  let auth0PasswordPolicy = passwordComplexity.password_policy || 'none'

  // Auth0 will not return any password_policy if the policy is set to none, so this check is added 
  // to ensure, that the password policy should really be set to none. We know that if auth0 password
  // policy is set to none, then min length of the password is 1. If min length is different from 1
  // then an error has happened and we should default to a password policy of good.
  if (auth0PasswordPolicy === 'none' && passwordComplexity.password_complexity_options.min_length > 1) {
    auth0PasswordPolicy = 'good';
  }
  //set policies matching Auth0 settings and check new password against that. Lenght can be different 
  // from the policy's default length, so we should replace length
  var containsAtLeastPolicy = new PasswordPolicy({ ...policies[`${auth0PasswordPolicy}`], length: { minLength: passwordComplexity.password_complexity_options.min_length } });

  if (!containsAtLeastPolicy.check(newPassword)) {
    return getMissingRulesDescriptions(containsAtLeastPolicy.missing(newPassword), classes, t) || '';
  }

  return '';

}

const getFormInvalid = (submitting: boolean, pristine: boolean, invalid: boolean, anyTouched: boolean, newPW: string, retypeNewPW: string, passwordRulesReturnMessage: any) => {
  return submitting || pristine || invalid || !anyTouched || newPW.trim() === '' || retypeNewPW.trim() === '' || (passwordRulesReturnMessage !== undefined && passwordRulesReturnMessage !== '')
}

const FormChangePassword: React.FC<ChangePasswordProps & InjectedFormProps<{}>> = ({ errorMessage, connectionName, passwordComplexity, onSubmit, setActiveTab, isMultifactorEnrolled, isFederation, anyTouched, pristine, invalid, submitting, submitSucceeded, submitFailed, systemMaintenanceSettings, isPageDownForMaintenance, profile, setValidationErrors, setLeaveConfirmValues, setShowChangePasswordPopup, setIsPasswordBeingSaved }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  let navigate = useNavigate()
  const dispatch = useDispatch()

  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPasswordClass, setShowPasswordClass] = useState(sharedClasses.eyeInactive);
  const [showCurrentPasswordClass, setShowCurrentPasswordClass] = useState(sharedClasses.eyeInactive);
  const [currentPW, setCurrentPW] = useState('');
  const [newPW, setNewPW] = useState('');
  const [retypeNewPW, setRetypeNewPW] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setShowTooltip(newPW !== '' && checkAgainstPasswordRules(newPW, passwordComplexity, classes, t) !== undefined)
  }, [newPW]);

  useEffect(() => {
    if (submitSucceeded) {      
      setIsPasswordBeingSaved(false);
      setShowChangePasswordPopup(true); 
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto"; 
    }
  }, [submitSucceeded]);
  
  useEffect(() => {
    !submitting && setIsPasswordBeingSaved(false);
  }, [submitting]);

  const isFormInvalid = () => {
    const passwordRulesReturnMessage = checkAgainstPasswordRules(newPW, passwordComplexity, classes, t);
    const isInvalid = getFormInvalid(submitting, pristine, invalid, anyTouched, newPW, retypeNewPW, passwordRulesReturnMessage);

    setTimeout(() => {
      setValidationErrors && setValidationErrors(isInvalid ? t('user_profile.validationErrors') : "");  
    }, 500);

    return isInvalid;
  }

  const resetAndClear = () => {
    dispatch(reset('change-password-form'));
    setCurrentPW('');
    setNewPW('');
    setRetypeNewPW('');
  }

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPasswordBeingSaved(true);

    onSubmit && onSubmit({ currentPassword: currentPW, newPassword: newPW })

    // we need to tell the clickoutsidewrapper that data was saved and that there are no change in data. No need to send 
    // actual values of the changed events, just send two string that match 
    setLeaveConfirmValues && setLeaveConfirmValues("1", "1", true, resetAndClear, document.getElementById('saveButton'))
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
    setShowTooltip(checkAgainstPasswordRules(newPW, passwordComplexity, classes, t) !== undefined);
  }

  const handleFieldChange = (key: string, value: string) => {
    switch (key) {
      case 'currentPassword':
        setCurrentPW(value);
        break;
      case 'newPassword':
        setNewPW(value);
        break;
      case 'retypeNewPassword':
        setRetypeNewPW(value);
        break;
    }

    setShowTooltip(false);

    // we need to tell the clickoutsidewrapper that a change have happened. No need to send 
    // actual values of the changed events, just send two string that doesn't match 
    setLeaveConfirmValues && setLeaveConfirmValues("1", "2", true, resetAndClear, document.getElementById('saveButton'))

  }

  const toggleChangePasswordPopup = () => {
    isMultifactorEnrolled ? setActiveTab('multifactor_settings') : navigate(-1);
  }

  return (

    
    <Grid container direction="row" id="clickoutsideContainer">
      {isPageDownForMaintenance(systemMaintenanceSettings, classes.maintenanceAlertPopup, classes.maintenanceAlertPopupContainer, classes.maintenanceAlertOverlay)}
      {isFederation && (
        <Grid container className={classes.popupContainer}>
          <ModalPopup paperClassName={classes.popup} overlayClassName={classes.overlay}>
            <Grid item xs={12} className={classes.popupHeader} >
              {connectionName === 'danfoss-waad' ? t('privacy_and_security.change_password_not_allowed_overlay_waad.header') : t('privacy_and_security.change_password_not_allowed_overlay.header', { property_name: (profile?.name || '') })}
            </Grid>
            <Grid item xs={12}  >
              <span className={classes.popupContent}>
                {connectionName === 'danfoss-waad' ? parse(t('privacy_and_security.change_password_not_allowed_overlay_waad.content')) : parse(t('privacy_and_security.change_password_not_allowed_overlay.content'))}
              </span>
            </Grid>
            <Grid item xs={12} className={classes.popupButtonContainer} >
              <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={toggleChangePasswordPopup}
              >
                {t('button.ok')}
              </Button>
            </Grid>
          </ModalPopup>
        </Grid>)
      }
      <Grid item xs={12} className={classes.fullWidth}>
        {!isMobileDevice() && (
          <Typography className={classes.subHeader}>
            {t('privacy_and_security.header_change_password')}
          </Typography>
        )}
        <Grid item xs={12} className={classes.descriptionText}>
          {errorMessage && <div><>{errorMessage}</></div>}
          <p>{t('privacy_and_security.description_change_password')}</p>
        </Grid>
        <form onSubmit={submitForm} name="change-password-form" className={classes.changePasswordForm}>
         
              <Grid container direction="row">
                <Grid item xs={12}>
                  {renderUsername(classes)}
                </Grid>
                <Grid item xs={12}>
                  {renderCurrentPassword(currentPW, showCurrentPassword, { showCurrentPasswordClass: showCurrentPasswordClass, classes: classes, sharedClasses: sharedClasses }, handleFieldChange, setShowCurrentPassword, setShowCurrentPasswordClass, t)}
                </Grid>
                <Grid item xs={12}>
                  <Tooltip
                    placement="top-start"
                    disableFocusListener
                    disableTouchListener
                    disableHoverListener
                    open={showTooltip}
                    title={checkAgainstPasswordRules(newPW, passwordComplexity, classes, t)}
                    className="MuiTooltip"
                  >
                    {renderNewPassword(newPW, showPassword, { showPasswordClass: showPasswordClass, classes: classes, sharedClasses: sharedClasses }, setShowTooltip, handleFieldChange, toggleShowPassword, setShowPasswordClass, t)}
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  {renderRetypeNewPassword(retypeNewPW, showPassword, classes, handleFieldChange, t)}
                </Grid>
              </Grid>
          
          <Grid container alignItems="flex-start" justifyContent="flex-start" direction="row" className={classes.buttonContainer}>
              <SpinnerButton
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={isFormInvalid()}
                pathToImagesFolder={urlJoin(settings.staticFiles.endpoint, '/images')}
                spinnerVisible={submitting}
                id="saveButton"
              >
                {t('button.change_password')}
              </SpinnerButton>
            </Grid>
         

        </form>
      </Grid>
    </Grid>
  );
}


const selector = formValueSelector('change-password-form')

const ExtendedComponent = connect((state: any) => {
  const newPassword = selector(state, 'newPassword')
  const retypeNewPassword = selector(state, 'retypeNewPassword')
  return {
    newPassword,
    retypeNewPassword,
    profile: state.profile
  }
})(FormChangePassword)

export default reduxForm({
  asyncValidate,
  form: 'change-password-form',
  asyncBlurFields: ['currentPassword', 'retypeNewPassword', 'newPassword'],
  asyncChangeFields: ['retypeNewPassword']
})(ExtendedComponent);