import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './Styles';
import './Styles.scss';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../../Providers/AuthenticationProvider';
import { MultifactorSetting } from '../../api/multifactor';
import { Application } from '../../api/application';
import { MultiFactorProps, MultifactorContentParameters } from './Types';
import { urlJoin } from 'url-join-ts';
import settings from '../../config/settings';
import parse from 'html-react-parser';
import { getApplications } from '../../api/user';
import { isMobileDevice } from '../../utils';
import { Profile } from '../../store/profile/types';
import i18n from "i18next";

const getUserApplications = async (getTokenSilently: Function, profile: Profile | undefined, setApplications: Function, setIsLoading: Function, pageLanguage: string) => {
    // Get user applications in order to display the ones that uses MFA
    
    await getTokenSilently().then((result:string) => {
        
        getApplications(((profile && profile.sub) || ''), result, pageLanguage).then(userApplications => {

            const applicationsUsingMFA = userApplications.user_applications.reduce((appsUsingMFA: string[], application: Application) => {
                if (application.uses_mfa) {
                    appsUsingMFA.push((application.translations?.name || application.name || ''));
                }

                return appsUsingMFA;
            },[]);

            setApplications(applicationsUsingMFA as []);
        }).finally (() => {
            setIsLoading(false);
        })
    })
}

const getContent = (multifactorContentParameters: MultifactorContentParameters) => {
    
    const t = multifactorContentParameters.t;
    const classes = multifactorContentParameters.classes;

    return multifactorContentParameters.isMFALogin ?
    (
        <>
            <Grid item xs={12} className={`${classes.MFALinkText} ${classes.spacerTop} ${classes.descriptionText}`}>
                {t('privacy_and_security.mfa_settings.reset_mfa_settings_text_mfa_login')}
            </Grid>
            <Grid item xs={12} className={classes.MFALinkContainer}>
                { !multifactorContentParameters.resetMFASuccess.current && (
                    <span onClick={() => {multifactorContentParameters.resetMFASettings()}} className={classes.linkText}>
                        <img src={urlJoin(settings.staticFiles.endpoint,'/images/system/LinkArrow.svg')} className={classes.linkArrow} alt="" /> {t('privacy_and_security.mfa_settings.reset_mfa_settings_link_text')}
                    </span>
                )}
            </Grid>
            { multifactorContentParameters.resetMFASuccess.current && (
                <Grid item xs={12} className={`${classes.descriptionText} ${classes.redText}`}>
                    {parse(t('privacy_and_security.mfa_settings.reset_mfa_settings_success_text'))}
                </Grid>
            )}
        </>
    ) : (
        <>
            <Grid item xs={12} className={`${classes.descriptionText} ${classes.spacerTop} ${classes.DescriptionTextBold}`}>
                {parse(t('privacy_and_security.mfa_settings.reset_mfa_settings_header_normal_login'))}
            </Grid>
            <Grid item xs={12} className={classes.descriptionText}>
                {parse(t('privacy_and_security.mfa_settings.reset_mfa_settings_text_normal_login'))}
            </Grid>
            <Grid item xs={12} className={classes.MFALinkContainer}>
                <span onClick={() => {multifactorContentParameters.loginWithMFA()}} className={classes.linkText}>
                    <img src={urlJoin(settings.staticFiles.endpoint,'/images/system/LinkArrow.svg')} className={classes.linkArrow} alt="" /> {t('privacy_and_security.mfa_settings.login_with_mfa_link_text')}
                </span>
            </Grid>
            <Grid item xs={12} className={`${classes.descriptionText} ${classes.DescriptionTextBold}`}>
                {parse(t('privacy_and_security.mfa_settings.lost_device_text'))}
            </Grid>
            <Grid item xs={12} className={`${classes.MFALinkText} ${classes.descriptionText}`}>
                {t('privacy_and_security.mfa_settings.send_recovery_code_text')}
            </Grid>
            <Grid item xs={12} className={classes.MFALinkContainer}>
                { !multifactorContentParameters.recoveryCodeSent.current && (
                    <span onClick={() => {multifactorContentParameters.sendRecoveryCode()}} className={classes.linkText}>
                        <img src={urlJoin(settings.staticFiles.endpoint,'/images/system/LinkArrow.svg')} className={classes.linkArrow} alt="" /> {t('privacy_and_security.mfa_settings.send_recovery_link_text')}
                    </span>
                )}
            </Grid>
            { multifactorContentParameters.recoveryCodeSent.current && (
                <Grid item xs={12} className={`${classes.descriptionText} ${classes.redText}`}>
                    {parse(t('privacy_and_security.mfa_settings.send_recovery_code_success_text'))}
                </Grid>
            )}
            
        </>
    )
}

const setMFASettingsTypeDescription = (mfaSettings: MultifactorSetting[], setMFATypeDescription: Function, t: Function) => {
    
    const mfaSettingsFiltered = mfaSettings.filter((mfaSetting: MultifactorSetting) => mfaSetting.confirmed);
    
    mfaSettingsFiltered.forEach((mfaSetting: MultifactorSetting) => { 
        // if type === 'push' we know that it is guardian MFA
        if (mfaSetting.type === 'push' && mfaSettingsFiltered.length === 3 && mfaSetting.confirmed) {
            setMFATypeDescription(t("privacy_and_security.mfa_settings.mfa_types.guardian"));
        } else if (mfaSetting.type === 'totp' && mfaSettingsFiltered.length === 2 && mfaSetting.confirmed) {
            setMFATypeDescription(t("privacy_and_security.mfa_settings.mfa_types.one_time_password"));
        } else if (mfaSetting.type === 'sms' && mfaSetting.confirmed) {
            setMFATypeDescription(t('privacy_and_security.mfa_settings.mfa_types.sms', { property_phonenumber: mfaSetting.name }));
        } 
    });
}

const MultiFactor: React.FC<MultiFactorProps> = ({ profile, mfaSettings, cultures, setShowRecoveryCodePasswordConfim, resetMFASettings, recoveryCodeSent, resetMFASuccess, systemMaintenanceSettings, isPageDownForMaintenance }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [isMFALogin, setIsMFALogin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    
    const [mfaTypeDescription, setMFATypeDescription] = useState('');
    const [applications, setApplications] = useState([])
    
    const { getIdTokenClaims, getTokenSilently, loginWithRedirect } = useAuthentication();
    
    useEffect(() => {
        const getClaims = async () => {
            await getIdTokenClaims().then((response:any) => { 
                // If the "amr" claim contains "mfa" then the user did login using MFA
                setIsMFALogin((response.hasOwnProperty('amr') && response.amr.indexOf("mfa") !== -1) || false );
              })
              .catch((error:any) => {
                console.log("Error", error);
              });
        }

        getClaims();

        getUserApplications(getTokenSilently, profile, setApplications, setIsLoading, cultures.currentCulture);

    }, []);

    useEffect(() => {
        setMFASettingsTypeDescription(mfaSettings, setMFATypeDescription, t);
    }, [mfaSettings, i18n.language]);

    const loginWithMFA = () => {
        loginWithRedirect(
            { 
                appState: {redirectUri: '/multifactor-settings'},
                ui_locales: (cultures && cultures.currentCulture) || 'en',
                acr_values: "http://schemas.openid.net/pape/policies/2007/06/multi-factor",
                scope: "user-mfa-deletion.write"
            }
        );
    }

    const sendRecoveryCode = () => {
        setShowRecoveryCodePasswordConfim(true);
    }
    
    if (mfaSettings.length === 0) {
        return null;
    }

    return !isLoading ? (
        <Grid container direction="row" className={classes.MFAOuterContainer}>
            {isPageDownForMaintenance(systemMaintenanceSettings, classes.maintenanceAlertPopup, classes.maintenanceAlertPopupContainer, classes.maintenanceAlertOverlay)}
            <Grid item xs={11} className={classes.fullWidth}>
                {!isMobileDevice() && (
                    <Typography className={classes.subHeader}>
                        {t('privacy_and_security.header_multifactor_settings')}
                    </Typography>
                )}
                <Grid item xs={12} className={`${classes.descriptionText} ${classes.spacerTop} ${classes.DescriptionTextBold}`}>
                    {t('privacy_and_security.mfa_settings.types_description')}
                </Grid>
                <Grid item xs={12} className={classes.descriptionText}>
                    {mfaTypeDescription}
                </Grid>
                {applications.length > 0 && (
                    <React.Fragment>
                    <Grid item xs={12} className={`${classes.descriptionText} ${classes.spacerTop} ${classes.DescriptionTextBold}`}>
                        {t('privacy_and_security.mfa_settings.application_description')}
                    </Grid>
                    <Grid item xs={12} className={classes.descriptionText}>
                        {applications.join(', ')}
                    </Grid>
                    </React.Fragment>
                )}
                {
                    getContent({ t, isMFALogin, classes, resetMFASettings, resetMFASuccess, loginWithMFA, sendRecoveryCode, recoveryCodeSent} as MultifactorContentParameters)
                }
            </Grid>
        </Grid>
    ): null;
}

export default MultiFactor;