import i18n from "i18next";
import { FieldMetaData } from "../../../store/fieldsMetadata/types";
import settings from '../../../config/settings';

const validate = (values: any, props: any) => {

  let errors = {};

  props && props.initialValues && props.initialValues.fieldsMetadata && props.initialValues.fieldsMetadata.fields.forEach((fmd: FieldMetaData) => {
    if (fmd.required && values[fmd.name] !== undefined && values[fmd.name] === '') {
      errors = { ...errors, [fmd.name]: i18n.t(fmd.data_type === 'text' ? 'validation_messages.required' : 'validation_messages.required_select', { property_name: i18n.t(`user_profile.${fmd.name}.label`) }) }
    }
  });

  const embargoCountries = settings.embargoRestrictions.countries.split(',');
  const embargoRegions = settings.embargoRestrictions.regions.split(',');

  if (embargoCountries.includes(values.country || "")) {
    errors = { ...errors, country: i18n.t('embargo.country_not_allowed') }
  }

  if (embargoRegions.includes(values.state || "")) {
    errors = { ...errors, state: i18n.t('embargo.region_not_allowed') }
  }
  return errors;
};

export default validate;