import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import settings from '../../config/settings';
import Footer from '../Footer/Footer';
import { Application, getApplication } from '../../api/application';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { useSharedStyles } from '../../sharedStyles/Styles';
import { Spinner } from '@danfoss/webex-ui';
import { pushEvent } from '../../Services/AnalyticsService';
import LogoAndBackground from '../Header/LogoAndBackground';
import ConfirmationContent from '../ConfirmationContent';
import DOMPurify from 'dompurify';
import { isMobileDevice } from '../../utils';
import { goToApplication } from '../../utils/applications';
import { cookieRemove, getCookieValueByName, getDomainName } from '../../utils/cookies';
import Information from '../Information/Information';
import { Container, Grid } from '@material-ui/core';
import { AppState } from '../../store';
import { connect } from 'react-redux';
import { getUserLanguage } from '../../utils/userLanguage';

const PasswordResetConfirmation: React.FC<Props> = ({ profile, cultures }) => {
    const { t } = useTranslation();
    const classes = useSharedStyles();

    const [application, setApplication] = useState({} as Application);
    const [loading, setIsLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [isInviteBasedFlow, setIsInviteBasedFlow] = useState(false);
    const translationKey = isInviteBasedFlow ? 'password_reset.congratulations.body_invite_based_flow' : 'password_reset.congratulations.body';

    useEffect(() => {
        setIsLoading(true);

        //Get querystring parameters
        const parsed = queryString.parse(window.location.search);

        const getApp = async () => {

            // take clientId if part of querystring otherwise default to My Profile clientId
            // @ts-ignore
            const cId = DOMPurify.sanitize(parsed.clientId) || settings.auth0.clientId;

            const lan = getUserLanguage(profile, cultures.cultures);

            setApplication(await getApplication(cId as string, lan));
            setMessage(DOMPurify.sanitize(parsed.message as string).toLowerCase());
            setSuccess(DOMPurify.sanitize(parsed.success as string).toLowerCase() === 'true');
            setIsLoading(false);
        }

        getApp();

        // Check if invite based flow
        setIsInviteBasedFlow(getCookieValueByName("forward_initial_password") !== "false");

        // Push an event that a user entered the password reset page
        pushEvent(
            {
                'event': 'virtualPageview',
                'page': `${(window as any).location.hostname}/passwordreset/${success}/${message}`
            }
        );

    }, []);

    if (success) {

        cookieRemove('forward_initial_password', getDomainName(window.location.hostname));

        if (!loading && !isMobileDevice()) {
            goToApplication(application);
            return null;
        }
    }

    const goToApp = () => {
        goToApplication(application);
    }

    return loading ? (<Spinner visible={loading} />) : (
        <React.Fragment>
            <LogoAndBackground classes={classes} />
            {message === 'reset-email-sent' && (
                <Container className={classes.containerRoot}>
                    <Container maxWidth="md" className={`${classes.outerContainer} ${classes.relativeContainer}`}>
                        <Grid container className={classes.contentContainerRoot}>
                            <Grid item xs={12}>
                                <Information
                                    handleButtonClick={() => goToApp}
                                    headerText={t('password_reset.reset_email_sent.header')}
                                    bodyText={parse(t('password_reset.reset_email_sent.body'))}
                                    buttonText={parse(t('password_reset.reset_email_sent.button', { application: (application.translations?.name || application.name || '') }))}
                                    dip_app_type={application.dip_app_type}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
            )}
            {message !== 'reset-email-sent' && (
                <ConfirmationContent
                    classes={classes}
                    success={success}
                    message={message}
                    application={application}
                    usedOnceHeader={t('password_reset.this_url_can_be_used_only_once.header')}
                    usedOnceBody={parse(t('password_reset.this_url_can_be_used_only_once.body'))}
                    usedOnceButton={parse(t('password_reset.this_url_can_be_used_only_once.button', { application: (application.translations?.name || application.name || '') }))}
                    blockedHeader={t('password_reset.congratulations.header')}
                    blockedBody={parse(t(translationKey))}
                    blockedButton={parse(t('password_reset.congratulations.button', { application: (application.translations?.name || application.name || '') }))}
                    expiredHeader={t('password_reset.link_expired.header')}
                    expiredBody={parse(t('password_reset.link_expired.body'))}
                    expiredButton={parse(t('password_reset.link_expired.button', { application: (application.translations?.name || application.name || '') }))}
                    showResendIfSuccessFalse={true}
                />
            )}
            <Footer contentClass={classes.footerContent} />
        </React.Fragment>
    );
}

const mapStateToProps = (state: AppState) => ({
    profile: state.profile,
    cultures: state.cultures
});

type Props = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(PasswordResetConfirmation);