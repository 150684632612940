import { takeLatest, all, put, fork, select } from 'redux-saga/effects';
import {
  setRelevantWebsites,
  setRecommendedApplications,
  setUsedApplications,
  setUsedApplicationsPreferences as setUsedApplicationsPreferencesAction,
  setRecommendedApplicationsPreferences as setRecommendedApplicationsPreferencesAction,
  setRelevantWebsitesPreferences as setRelevantWebsitesPreferencesAction,
  setLoadedFalse as setLoadedFalseAction,
  setRelevantWebsitesLoaded,
  setRecommendedApplicationsLoaded,
  setUsedApplicationsLoaded
} from './actions';
import {
  getApplications,
  getRecommendedApplications,
  getRelevantWebsites,
  getRecommendedApplicationsPreferences,
  RecommendedApplicationPreference,
  getUsedApplicationsPreferences as getUsedApplicationsPreferencesAction,
  getRelevantWebsitesPreferences,
  RelevantWebsitePreference,
  RecommendedApplicationsClientIds,
  UsedApplicationPreference,
  updateUsedApplicationsPreferences,
  updateRecommendedApplicationsPreferences,
  updateRelevantWebsitesPreferences,
  RelevantWebsite
} from '../../api/user';
import { DashboardItemsActionType } from './types';
import { AppState } from "../";
import { Application, getApplicationData } from '../../api/application';
import { authenticationClient } from '../../Providers/AuthenticationProvider';
import settings from '../../config/settings';
import { isMobileDevice } from '../../utils';
import { CulturesActionType } from '../cultures/types';


export function* loadUserApplications() {

  if (!authenticationClient) {
    return;
  }

  const isAuthenticated: boolean = yield authenticationClient.isAuthenticated();

  if (!isAuthenticated) {
    return;
  }

  yield loadUsedApplications();
  yield loadRecommendedApplications();
  yield loadRelevantWebsites();
}

export function* loadUsedApplications() {
  const getState = (state: AppState) => state;
  const { profile, cultures } = yield select(getState);

  const isAuthenticated: boolean = yield authenticationClient.isAuthenticated();

  if (!isAuthenticated) {
    return;
  }

  const accessToken: string = yield authenticationClient.getTokenSilently();
  const accessTokenMyDanfossApi: string = yield authenticationClient.getTokenSilently({ audience: settings.myDanfossAPI.audience, analytics: false });

  try {
    let [userApplications, usedApplicationsPreferenses] = yield all([
      getApplications(profile.user_id, accessToken, cultures.currentCulture),
      getUsedApplicationsPreferencesAction(accessTokenMyDanfossApi, isMobileDevice())
    ]);

    const usedApplicationsWithPreferences = userApplications.user_applications.map((application: Application) => {
      const preference = usedApplicationsPreferenses.preferences.filter((pref: UsedApplicationPreference) => pref.client_id === application.client_id)
      return { ...application, ...preference[0] };
    })

    yield all([
      put(setUsedApplicationsPreferencesAction(usedApplicationsPreferenses.preferences)),
      put(setUsedApplications(usedApplicationsWithPreferences)),
    ]);

    yield put(setUsedApplicationsLoaded({ loaded: true, hasError: false }));

  } catch (error) {
    // In case we get an error loading used applications and preferences, set as loaded to prevent the
    // dashboard description to not being shown.
    yield put(setUsedApplicationsLoaded({ loaded: true, hasError: true }))
  }
}

export function* loadRecommendedApplications() {
  const isAuthenticated: boolean = yield authenticationClient.isAuthenticated();

  const getState = (state: AppState) => state;
  const { cultures } = yield select(getState);

  if (!isAuthenticated) {
    return;
  }

  const accessTokenMyDanfossApi: string = yield authenticationClient.getTokenSilently({ audience: settings.myDanfossAPI.audience, analytics: false });

  let recommendedApplicationsClientIds: RecommendedApplicationsClientIds = { client_ids: [] };

  try {
    recommendedApplicationsClientIds = yield getRecommendedApplications(accessTokenMyDanfossApi)

    let [recommendedApplications, recommendedApplicationsPreferences] = yield all([
      getApplicationData(recommendedApplicationsClientIds, cultures.currentCulture),
      getRecommendedApplicationsPreferences(accessTokenMyDanfossApi, isMobileDevice())
    ]);

    const recommendedApplicationsWithPreferences = recommendedApplications.application_infos.map((application: Application) => {
      const preference = recommendedApplicationsPreferences.preferences.filter((pref: RecommendedApplicationPreference) => pref.client_id === application.client_id)
      return { ...application, ...preference[0] };
    })

    yield all([
      put(setRecommendedApplicationsPreferencesAction(recommendedApplicationsPreferences.preferences)),
      put(setRecommendedApplications(recommendedApplicationsWithPreferences)),
    ]);

    yield put(setRecommendedApplicationsLoaded({ loaded: true, hasError: false }));

  } catch (error) {
    // In case we get an error loading recommended applications, set as loaded to prevent the
    // dashboard description to not being shown.
    yield put(setRecommendedApplicationsLoaded({ loaded: true, hasError: true }))
  }

}

export function* loadRelevantWebsites() {
  const getState = (state: AppState) => state;
  const { profile, cultures } = yield select(getState);

  const isAuthenticated: boolean = yield authenticationClient.isAuthenticated();

  if (!isAuthenticated) {
    return;
  }

  const accessTokenMyDanfossApi: string = yield authenticationClient.getTokenSilently({ audience: settings.myDanfossAPI.audience, analytics: false });

  try {

    let [relevantWebsites, relevantWebsitesPreferences] = yield all([
      getRelevantWebsites(cultures.currentCulture, profile.country.id, accessTokenMyDanfossApi),
      getRelevantWebsitesPreferences(accessTokenMyDanfossApi, isMobileDevice())
    ]);

    const relevantWebsitesWithPreferences = relevantWebsites.relevant_websites.map((relevantWebsite: RelevantWebsite) => {
      const preference = relevantWebsitesPreferences.preferences.filter((pref: RelevantWebsitePreference) => pref.id === relevantWebsite.id)
      return { ...relevantWebsite, ...preference[0] };
    })

    yield all([
      put(setRelevantWebsitesPreferencesAction(relevantWebsitesPreferences.preferences)),
      put(setRelevantWebsites(relevantWebsitesWithPreferences)),
    ]);

    yield put(setRelevantWebsitesLoaded({ loaded: true, hasError: false }));

  } catch (error) {
    // In case we get an error loading relevant applications, set as loaded to prevent the
    // dashboard description to not being shown.
    yield put(setRelevantWebsitesLoaded({ loaded: true, hasError: true }))
  }
}

export function* updateUserPreferences(action: any) {
  const { isMobile, used_application_preferences, recommended_application_preferences, relevant_websites_preferences } = action.payload;

  const isAuthenticated: boolean = yield authenticationClient.isAuthenticated();

  if (!isAuthenticated) {
    return;
  }

  const accessTokenMyDanfossApi: string = yield authenticationClient.getTokenSilently({ audience: settings.myDanfossAPI.audience, analytics: false });

  yield put(setLoadedFalseAction());

  try {
    yield all([
      put(setUsedApplicationsPreferencesAction(used_application_preferences.preferences)),
      updateUsedApplicationsPreferences(accessTokenMyDanfossApi, used_application_preferences, isMobile),
    ]);

    yield put(setUsedApplicationsLoaded({ loaded: true, hasError: false }));

    yield all([
      put(setRecommendedApplicationsPreferencesAction(recommended_application_preferences.preferences)),
      updateRecommendedApplicationsPreferences(accessTokenMyDanfossApi, recommended_application_preferences, isMobile),
    ]);

    yield put(setRecommendedApplicationsLoaded({ loaded: true, hasError: false }));

    yield all([
      put(setRelevantWebsitesPreferencesAction(relevant_websites_preferences.preferences)),
      updateRelevantWebsitesPreferences(accessTokenMyDanfossApi, relevant_websites_preferences, isMobile),
    ]);

    yield put(setRelevantWebsitesLoaded({ loaded: true, hasError: false }));

  } catch (error) {
    // In case we get an error loading preferences, set as loaded to prevent the
    // dashboard description to not being shown.
    yield put(setUsedApplicationsLoaded({ loaded: true, hasError: true }));
    yield put(setRelevantWebsitesLoaded({ loaded: true, hasError: true }));
    yield put(setRecommendedApplicationsLoaded({ loaded: true, hasError: true }));
  }
}

export function* watchLoadUsedApplications() {
  yield takeLatest(DashboardItemsActionType.GET_USED_APPLICATIONS, loadUsedApplications);
}

export function* watchLoadRecommendedApplications() {
  yield takeLatest(DashboardItemsActionType.GET_RECOMMENDED_APPLICATIONS, loadRecommendedApplications);
}

export function* watchLoadRelevantWebsites() {
  yield takeLatest(DashboardItemsActionType.GET_RELEVANT_WEBSITES, loadRelevantWebsites);
}

export function* watchUpdateUserPreferences() {
  yield takeLatest(DashboardItemsActionType.UPDATE_USER_PREFERENCES, updateUserPreferences);
}

export function* watchClutureChange() {
  yield takeLatest(CulturesActionType.CULTURE_CHANGE, loadUserApplications);
}

export default function* dashboardItemsSagas() {
  yield all([
    fork(watchLoadUsedApplications),
    fork(watchLoadRecommendedApplications),
    fork(watchLoadRelevantWebsites),
    fork(watchUpdateUserPreferences),
    fork(watchClutureChange)
  ]);
}
