
export interface EmailVerificationData {
    email: string,
    password: string,
    clientId: string,
    language: string
}

export interface PasswordResetData {
    email: string,
    clientId: string
}

export enum emailResendActionTypes {
    RESEND_VERIFICATION_EMAIL = '@@emailResend/RESEND_VERIFICATION_EMAIL',
    RESEND_RESET_PASSWORD = '@@emailResend/RESEND_RESET_PASSWORD' 
}