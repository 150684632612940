
export interface Cultures {
    currentCulture: string;
    cultures: Culture[];
}

export interface Culture {
    id: string,
    text: string,
    has_been_translated: boolean
}

export enum CulturesActionType {
    SET_CULTURES = '@@translation/SET_CULTURES',
    GET_CULTURES = '@@translation/GET_CULTURES',
    CULTURE_CHANGE = '@@translation/CULTURE_CHANGE'
}