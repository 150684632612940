import React from 'react';
import { getCookieCategoriesAcceptance } from './CookieInformation';
import settings from '../../config/settings';
import { cookieRemove } from '../../utils/cookies';

const GoogleAnalytics: React.FC = () => {
       
    // When cookieinformation is loaded we need to trigger this function
    window.addEventListener('CookieInformationConsentGiven', function (event:any) { 
        
        event = {...event, origin: event.target.location.origin}

        if (event.origin !== settings.application.url) {
            return 
        }
        
        // Get user cookie acceptance from cookieinformation
        const acceptance = getCookieCategoriesAcceptance();
        // If the user has accepted statistic cookies we can continue
        if (acceptance && acceptance.statistic) { 
            (function () { 
            
                // Enable google analytics
                (window as any)['ga-disable-' + settings.googleAnalytics.id] = false

                const existingHeadElement = document.getElementById("googleTagManager_head");

                if(!existingHeadElement) {
                    const googleTagManager_head = document.createElement('script');

                    // Add a generated nonce to the script include for the googleTagManager script when adding inline scripts to our page  
                    googleTagManager_head.setAttribute('nonce', (document.getElementById('googleNonce') as HTMLMetaElement).content);

                    googleTagManager_head.id = "googleTagManager_head";
                    googleTagManager_head.innerText = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.id='googleTagManager_head_async';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','" + settings.googleTagManager.id + "');";
                    document.head.insertBefore(googleTagManager_head, document.head.lastChild);
                }

                const existingBodyElement = document.getElementById("googleTagManager_body");

                if (!existingBodyElement) {
                    const googleTagManager_body = document.createElement('noscript');
                    googleTagManager_body.id = "googleTagManager_body";

                    const googleTagManager_body_iframe = document.createElement('iframe');
                    googleTagManager_body_iframe.src = "https://www.googletagmanager.com/ns.html?id=" + settings.googleTagManager.id;
                    googleTagManager_body_iframe.height = "0";
                    googleTagManager_body_iframe.width = "0";
                    googleTagManager_body_iframe.style.display = "none";
                    googleTagManager_body_iframe.style.visibility = "hidden";
                    
                    googleTagManager_body.appendChild(googleTagManager_body_iframe);

                    document.body.insertBefore(googleTagManager_body, document.body.firstChild);
                }
            })(); 
        } else {
            
            // Disable google analytics
            (window as any)['ga-disable-' + settings.googleAnalytics.id] = true

            // Delete Google Analytics cookies

            const hostname = (window as any).location.hostname

            cookieRemove(`_gat_${settings.googleAnalytics.id}`, hostname);
		    cookieRemove('_gid', hostname);
		    cookieRemove('_ga=', hostname);
        }
    }, false); 

    return null;
} 

export default GoogleAnalytics;