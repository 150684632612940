import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './Styles';
import { useTranslation } from 'react-i18next';
import { DeleteUserProps } from './Types';
import { urlJoin } from 'url-join-ts'
import settings from '../../config/settings';
import { isMobileDevice } from '../../utils';
import ModalPopup from '../Modal/ModalPopup';
import parse from 'html-react-parser';
import { useNavigate } from "react-router-dom";
import Button from '@material-ui/core/Button';

const DeleteUser: React.FC<DeleteUserProps> = ({ setShowUserDeletionConfirm, isMultifactorEnrolled, isFederation, connectionName, setActiveTab, systemMaintenanceSettings, isPageDownForMaintenance, profile }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    let navigate = useNavigate();

    const toggleDeleteUserPopup = () => {
        isMultifactorEnrolled ? setActiveTab('multifactor_settings') : navigate(-1);
	}

    return (
        <Grid container direction="row">
            {isPageDownForMaintenance(systemMaintenanceSettings, classes.maintenanceAlertPopup, classes.maintenanceAlertPopupContainer, classes.maintenanceAlertOverlay)}
            {isFederation && (
                <Grid container className={classes.popupContainer}>
                    <ModalPopup paperClassName={classes.popup} overlayClassName={classes.overlay}>
                        <Grid item xs={12} className={classes.popupHeader} >
                            {connectionName === 'danfoss-waad' ? t('privacy_and_security.change_password_not_allowed_overlay_waad.header') : t('privacy_and_security.change_password_not_allowed_overlay.header', { property_name: (profile?.name || '')}) }
                        </Grid>
                        <Grid item xs={12} >
                            <span className={classes.popupContent}>
                                {connectionName === 'danfoss-waad' ? parse(t('privacy_and_security.change_password_not_allowed_overlay_waad.content')) : parse(t('privacy_and_security.change_password_not_allowed_overlay.content')) }
                            </span>
                        </Grid>
                        <Grid item xs={12} className={classes.popupButtonContainer} >
                            <Button 
                                type="button"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={toggleDeleteUserPopup}
                            >
                                {t('button.ok')} 
                            </Button>
                        </Grid>
                    </ModalPopup>
                </Grid>)
            }
            <Grid item xs={11} className={classes.fullWidth}>
                {!isMobileDevice() && (
                    <Typography className={classes.subHeader}>
                        {t('privacy_and_security.header_delete_account')}
                    </Typography>
                )}
                <Grid item xs={12} className={classes.descriptionText}>
                    <p>{t('privacy_and_security.description_delete_account')}</p>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" className={classes.deleteLinkContainer}>
                    <span onClick={() => {setShowUserDeletionConfirm(true)}} className={classes.linkText}>
                        <img src={urlJoin(settings.staticFiles.endpoint,'/images/system/LinkArrow.svg')} className={classes.linkArrow} alt="" /> {t('privacy_and_security.delete_account')}
                    </span>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default DeleteUser;