import settings from '../config/settings';
import fetchApiWrapper from './fetchApiWrapper';
import fetchApiWrapperWithTimeout from './fetchApiWrapperWithTimeout';
import { UserData } from '../store/profile/types'
import { Application, MobileAppInfo } from './application'
import { getEncryptedData } from '../utils'
import { ItemTranslations } from '../components/UserDashboard/Types';

interface UserIdentity {
  connection: string,
  provicer: string,
  user_id: string
}

export interface UserModel {
  email: string,
  identities: UserIdentity[]
  user_metadata: UserData
}

interface Response {
  ok: boolean
}

interface IsUserVerifiedResponse {
  is_email_verified: boolean
}

export interface DeletionResponse {
  responseStatus: string
}

export interface UserApplications {
  user_applications: Application[]
}

export interface UsedApplications {
  used_applications: UsedApplicationWithPreference[]
}

export interface RelevantWebsite {
  id?: string,
  name: string,
  description: string
  url: string,
  app_type: string
}

export interface RelevantWebsites {
  relevant_websites: RelevantWebsiteWithPreference[]
}

export interface RecommendedApplications {
  client_ids: string[]
}

export interface RecommendedApplicationsPreferences {
  preferences: RecommendedApplicationPreference[]
}

export interface UsedApplicationsPreferences {
  preferences: UsedApplicationPreference[]
}

export interface RecommendedApplicationPreference {
  client_id: string,
  hidden: boolean
}

export interface UsedApplicationWithPreference {
  client_id: string,
  name: string,
  description?: string,
  url: string,
  dip_app_type: string,
  display_order?: number,
  hidden?: boolean,
  allowed_callback: string,
  app_id: string,
  app_type: string,
  mobile_app_info: MobileAppInfo[],
  uses_mfa: boolean,
  translations?: ItemTranslations
}

export interface RelevantWebsiteWithPreference {
  id?: string,
  name: string,
  description: string
  url: string,
  app_type: string,
  display_order?: number,
  hidden?: boolean
}

export interface RecommendedApplicationWithPreference {
  dip_app_type: string,
  client_id: string;
  description?: string
  name?: string,
  display_order?: number,
  hidden?: boolean,
  meta_data?: RecommendedApplicationMetaData
  url: string,
  translations?: ItemTranslations
}

export interface RecommendedApplicationMetaData {
  customer_facing: boolean,
  segment: string,
  mobile_app_info: MobileAppInfo[]
  uses_mfa?: boolean,
  app_id?: string
}

export interface RecommendedApplicationsClientIds {
  client_ids: string[]
}

export interface RelevantWebsitesPreferences {
  preferences: RelevantWebsitePreference[]
}

export interface RelevantWebsitePreference {
  id: string,
  hidden?: boolean,
  display_order?: number
}

export interface UsedApplicationPreferences {
  preferences: UsedApplicationPreference[]
}

export interface UsedApplicationPreference {
  client_id: string,
  hidden?: boolean,
  display_order?: number,
  dip_app_type: string
}

export interface UserPreferences {
  isMobile: boolean,
  used_application_preferences: UsedApplicationsPreferences,
  recommended_application_preferences: RecommendedApplicationsPreferences,
  relevant_websites_preferences: RelevantWebsitesPreferences
}

export const getAuth0User = (userId: string, pageLanguage: string | null, accessToken: string): Promise<UserModel> => {

  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
  requestHeaders.append('Authorization', `BEARER ${accessToken}`)

  if (pageLanguage) {
    requestHeaders.append('Accept-Language', pageLanguage)
  }

  return fetchApiWrapperWithTimeout(`${settings.backend.endpoint}/users/${userId}`, {
    headers: requestHeaders,
    method: 'GET',
  }, (!isNaN(settings.backend.timeout) && settings.backend.timeout) || 10000);
}

export const isUserVerifed = (email: string, accessToken: string): Promise<IsUserVerifiedResponse> => {

  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
  requestHeaders.append('Authorization', `BEARER ${accessToken}`)


  return getEncryptedData(email, accessToken).then((encryptedData: string) => {
    return fetchApiWrapper(`${settings.backend.endpoint}/users/${encryptedData}/verification-status`, {
      headers: requestHeaders,
      method: 'GET',
    });
  });


}

export const deleteUser = (userId: string, accessToken: string, password: string) => {

  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
  requestHeaders.append('Authorization', `BEARER ${accessToken}`)

  return fetch(`${settings.backend.endpoint}/users/${userId}`, {
    body: `{"password":"${password}"}`,
    headers: requestHeaders,
    method: 'DELETE',
  })
}

export const updateUser = (userId: string, pageLanguage: string, accessToken: string, userMetadata: UserData) => {

  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
  requestHeaders.append('Authorization', `BEARER ${accessToken}`)

  if (pageLanguage) {
    requestHeaders.append('Accept-Language', pageLanguage)
  }

  return fetch(`${settings.backend.endpoint}/users/${userId}?are_empty_values_allowed=true`, {
    body: JSON.stringify(userMetadata),
    headers: requestHeaders,
    method: 'PATCH',
  })
};

export const changePassword = (userId: string, pageLanguage: string, accessToken: string, oldPassword: string, newPassword: string): Promise<Response> => {

  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
  requestHeaders.append('Authorization', `BEARER ${accessToken}`)

  if (pageLanguage) {
    requestHeaders.append('Accept-Language', pageLanguage)
  }

  return fetchApiWrapper(`${settings.backend.endpoint}/users/${userId}/password`, {
    body: JSON.stringify({ old_password: oldPassword, new_password: newPassword }),
    headers: requestHeaders,
    method: 'PUT',
  })
};

export const getApplications = (userId: string, accessToken: string, pageLanguage: string): Promise<UserApplications> => {

  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
  requestHeaders.append('Accept-Language', pageLanguage)
  requestHeaders.append('Authorization', `BEARER ${accessToken}`)

  return fetchApiWrapperWithTimeout(`${settings.backend.endpoint}/users/${userId}/applications`, {
    headers: requestHeaders,
    method: 'GET',
  }, (!isNaN(settings.backend.timeout) && settings.backend.timeout) || 10000);
}

export const getRecommendedApplications = (accessToken: string): Promise<RecommendedApplications> => {

  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
  requestHeaders.append('Authorization', `BEARER ${accessToken}`)

  return fetchApiWrapperWithTimeout(`${settings.myDanfossAPI.endpoint}/users/me/recommended-applications`, {
    headers: requestHeaders,
    method: 'GET',
  }, (!isNaN(settings.backend.timeout) && settings.backend.timeout) || 10000);
}

export const getRecommendedApplicationsPreferences = (accessToken: string, isMobile: boolean): Promise<RecommendedApplicationsPreferences> => {

  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
  requestHeaders.append('Authorization', `BEARER ${accessToken}`)

  const recommendedApplicationsPreferencesPromise = fetchApiWrapperWithTimeout(`${settings.myDanfossAPI.endpoint}/users/me/preferences/recommended-applications?is_mobile=${isMobile}`, {
    headers: requestHeaders,
    method: 'GET',
  }, (!isNaN(settings.backend.timeout) && settings.backend.timeout) || 10000).catch(() => {
    // Added to make sure used applications are shown if MyDanfoss API is down
    return Promise.resolve({ preferences: [] }) as Promise<RecommendedApplicationsPreferences>;
  });

  return recommendedApplicationsPreferencesPromise as Promise<RecommendedApplicationsPreferences>
}

export const getRelevantWebsitesPreferences = (accessToken: string, isMobile: boolean): Promise<RelevantWebsitesPreferences> => {

  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
  requestHeaders.append('Authorization', `BEARER ${accessToken}`)

  const relevantWebsitesPreferencesPromise = fetchApiWrapperWithTimeout(`${settings.myDanfossAPI.endpoint}/users/me/preferences/relevant-websites?is_mobile=${isMobile}`, {
    headers: requestHeaders,
    method: 'GET',
  }, (!isNaN(settings.backend.timeout) && settings.backend.timeout) || 10000).catch(() => {
    // Added to make sure used applications are shown if MyDanfoss API is down
    return Promise.resolve({ preferences: [] }) as Promise<RelevantWebsitesPreferences>;
  });

  return relevantWebsitesPreferencesPromise as Promise<RelevantWebsitesPreferences>
}

export const getUsedApplicationsPreferences = (accessToken: string, isMobile: boolean): Promise<UsedApplicationsPreferences> => {

  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
  requestHeaders.append('Authorization', `BEARER ${accessToken}`)

  const usedApplicationsPreferencesPromise = fetchApiWrapperWithTimeout(`${settings.myDanfossAPI.endpoint}/users/me/preferences/used-applications?is_mobile=${isMobile}`, {
    headers: requestHeaders,
    method: 'GET',
  }, (!isNaN(settings.backend.timeout) && settings.backend.timeout) || 10000).catch(() => {
    // Added to make sure used applications are shown if MyDanfoss API is down
    return Promise.resolve({ preferences: [] }) as Promise<UsedApplicationsPreferences>;
  });

  return usedApplicationsPreferencesPromise as Promise<UsedApplicationsPreferences>
}

export const updateRecommendedApplicationsPreferences = (accessToken: string, preferenceObject: any, isMobile: boolean) => {

  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
  requestHeaders.append('Authorization', `BEARER ${accessToken}`)

  return fetchApiWrapper(`${settings.myDanfossAPI.endpoint}/users/me/preferences/recommended-applications/?is_mobile=${isMobile}`, {
    body: JSON.stringify(preferenceObject),
    headers: requestHeaders,
    method: 'PUT',
  });
}

export const updateUsedApplicationsPreferences = (accessToken: string, preferenceObject: any, isMobile: boolean) => {

  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
  requestHeaders.append('Authorization', `BEARER ${accessToken}`)

  return fetchApiWrapper(`${settings.myDanfossAPI.endpoint}/users/me/preferences/used-applications/?is_mobile=${isMobile}`, {
    body: JSON.stringify(preferenceObject),
    headers: requestHeaders,
    method: 'PUT',
  });
}

export const updateRelevantWebsitesPreferences = (accessToken: string, preferenceObject: any, isMobile: boolean) => {

  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
  requestHeaders.append('Authorization', `BEARER ${accessToken}`)

  return fetchApiWrapper(`${settings.myDanfossAPI.endpoint}/users/me/preferences/relevant-websites/?is_mobile=${isMobile}`, {
    body: JSON.stringify(preferenceObject),
    headers: requestHeaders,
    method: 'PUT',
  });
}

export const getRelevantWebsites = (pageLanguage: string | null, country: string, accessToken: string): Promise<RelevantWebsites> => {

  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
  requestHeaders.append('Authorization', `BEARER ${accessToken}`)

  return fetchApiWrapperWithTimeout(`${settings.myDanfossAPI.endpoint}/users/me/relevant-websites?status=published&country=${country}&language=${pageLanguage}`, {
    headers: requestHeaders,
    method: 'GET',
  }, (!isNaN(settings.backend.timeout) && settings.backend.timeout) || 10000);
}
