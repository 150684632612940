import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';
import { urlJoin } from 'url-join-ts';
import settings from '../config/settings';

export const useSharedStyles = makeStyles(theme => ({
	big: {
		[theme.breakpoints.down('sm')]: {
			display: 'none !important' 
        }
    },
    small: {
        [theme.breakpoints.up('md')]: {
            display: 'none !important'
        }
    },
    eyeActive: {
        backgroundImage: `url(${urlJoin(settings.staticFiles.endpoint,'/images/system/Eye_Active.svg')})`,
        backgroundSize: "contain",
        backgroundRepeat: 'no-repeat',
        width: "28px",
        height: "15px",   
        cursor: "pointer",
        display: "table"     
    },
    eyeHover: {
        backgroundImage: `url(${urlJoin(settings.staticFiles.endpoint,'/images/system/Eye_Hover.svg')})`,
        backgroundSize: "contain",
        backgroundRepeat: 'no-repeat',
        width: "28px",
        height: "15px",   
        cursor: "pointer",
        display: "table"   
    },
    eyeInactive: {
        backgroundImage: `url(${urlJoin(settings.staticFiles.endpoint,'/images/system/Eye_Inactive.svg')})`,
        backgroundSize: "contain",
        backgroundRepeat: 'no-repeat',
        width: "28px",
        height: "15px",   
        cursor: "pointer",
        display: "table"     
    },    
    wrapper: {
        position: 'absolute',
        [theme.breakpoints.down('sm')]: {
			marginLeft: 13
        }
    },
    containerRoot: {
        flexGrow: 1,
        [theme.breakpoints.down('md')]: {
            paddingLeft: '0px',
            paddingRight: '0px'
		},
        [theme.breakpoints.only('xs')]: {
            paddingLeft: '0px',
            paddingRight: '0px'
		}
    },
    footerContent: {
        color: colors.webexuiWhite,
        fontSize: '16px',
        lineHeight: '19px',
        maxWidth: '1236px',
        backgroundColor: colors.webexuiDarkGrayish,
        margin: '0 auto',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: '0px',
    },
    outerContainer: {
        maxWidth: '524px',
        [theme.breakpoints.down('xl')]: {
          paddingTop: '132px',
          paddingLeft: '17px',
          paddingRight: '17px'
        },
        [theme.breakpoints.down('sm')]: {
          paddingTop: theme.spacing(9)
        },
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 0,
            paddingRight: 0
        }
      },
      relativeContainer: {
        [theme.breakpoints.up('sm')]: {
            marginBottom: '15px',
        },
        position: 'relative',
        minHeight: '100%',
        [theme.breakpoints.up('sm')]: {
          paddingBottom: '80px'
        }
      },
      formContainer: {
        [theme.breakpoints.up('sm')]: {
            padding: '0px 10px 23px',
        },
        [theme.breakpoints.only('xs')]: {
            padding: '0px 8px 23px',
        },
        marginTop: '8px',
        marginBottom: '15px'
    },
      contentContainerRoot: {
        flexGrow: 0,
        backgroundColor: 'white',
        [theme.breakpoints.up('sm')]: {
            borderRadius: 6,
        },
        paddingTop: '16px',
        [theme.breakpoints.down('lg')]: {
            marginTop: '20px'
        },
        [theme.breakpoints.only('xs')]: {
            marginTop: '0px',
            paddingTop: '10px',
            minHeight: '77vh'
		}
      }
}));