import { Auth0ClientOptions } from '@auth0/auth0-spa-js/dist/typings/global';

interface Auth0ClientExtensions {
  customDomain: string
}

interface Auth0ClientOptionsWithExtensions extends Auth0ClientOptions, Auth0ClientExtensions {

}

interface AppSettings {
  auth0: Auth0ClientOptionsWithExtensions,
  backend: {
    endpoint: string,
    timeout: number
  },
  myDanfossAPI: {
    endpoint: string,
    audience: string
  },
  appInsights: {
    key: string
  },
  staticFiles: {
    endpoint: string,
    defaultApplicationBanner: string
  },
  generalInformation: {
    endpoint: string
  },
  faq: {
    endpoint: string
  },  
  application: {
    appId: string,
    name: string,
    url: string
  },  
  cookieinformation: {
    id: string,
    url: string
  },
  regCenterApi: {
    endpoint: string,
    audience: string,
    hashKey: string
  },
  googleTagManager: {
    id: string
  },
  googleAnalytics: {
    id: string
  },
  webkitJavascriptForTest: {
    inject: boolean,
    operatingsystem: string
  },
  mobilestores: {
    googlePlayAppUrl: string,
    googlePlayWebUrl: string,
    appStoreUrl: string
  },
  analyticsEmailHashKey: {
    key: string
  },
  releaseNumber: {
    value: string
  },
  embargoRestrictions: {
    countries: string,
    regions: string
  }
}

const settings: AppSettings = {
  auth0: JSON.parse(process.env.REACT_APP_AUTH0 || "{}"),
  backend: JSON.parse(process.env.REACT_APP_BACKEND || "{}"),
  myDanfossAPI: JSON.parse(process.env.REACT_APP_MYDANFOSS_API || "{}"), 
  appInsights: JSON.parse(process.env.REACT_APP_APPINSIGHTS || "{}"),
  staticFiles: JSON.parse(process.env.REACT_APP_STATICFILES || "{}"),
  generalInformation: JSON.parse(process.env.REACT_APP_GENERALINFORMATION || "{}"),
  faq: JSON.parse(process.env.REACT_APP_FAQ || ""),
  application: JSON.parse(process.env.REACT_APP_APPLICATION || "{}"),
  cookieinformation: JSON.parse(process.env.REACT_APP_COOKIEINFORMATION || "{}"),
  regCenterApi: JSON.parse(process.env.REACT_APP_REG_CENTER_API || "{}"),
  googleTagManager: JSON.parse(process.env.REACT_APP_GOOGLETAGMANAGER || "{}"),
  googleAnalytics: JSON.parse(process.env.REACT_APP_GOOGLEANALYTICS || "{}"),
  webkitJavascriptForTest: JSON.parse(process.env.REACT_APP_WEBKIT_JAVASCRIPT_TEST || "{}"),
  mobilestores: JSON.parse(process.env.REACT_APP_MOBILE_STORES || "{}"),
  analyticsEmailHashKey: JSON.parse(process.env.REACT_APP_ANALYTICSEMAILHASHKEY || ""),
  releaseNumber: JSON.parse(process.env.REACT_APP_RELEASENUMBER || ""),
  embargoRestrictions: JSON.parse(process.env.REACT_APP_EMBARGO || ""),
};

export default settings;
