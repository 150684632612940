import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    black: {
        backgroundColor: colors.webexuiDarkGrayish,
        zIndex: 1201
    },
	root: {
        display: 'table',
        overflow: 'hidden',
        flexWrap: 'wrap',
        backgroundColor: colors.webexuiDarkGrayish,
        maxWidth: '1440px',
        margin: '0 auto',
        padding: '26px 0px 16px 19px',
        [theme.breakpoints.only('lg')]: {
            padding: '26px 0px 16px 89px',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            padding: '26px 0px 16px 40px',
        },
        [theme.breakpoints.only('xs')]: {
			padding: '26px 0px 16px 16px'
		},
        zIndex: 1201
    },
    content: {
        display: 'table',
        color: colors.webexuiWhite,
        fontSize: '16px',
        lineHeight: '19px',
        maxWidth: '1236px',
        backgroundColor: colors.webexuiDarkGrayish,
        margin: '0 auto',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    padding: {
        [theme.breakpoints.up('xl')]: {
            paddingLeft: '70px'
        },
        [theme.breakpoints.only('lg')]: {
            paddingLeft: 0
        },
        [theme.breakpoints.between('sm','md')]: {
            paddingLeft: '7px'
        },
        [theme.breakpoints.between('xs', 'sm')]: {
			paddingLeft: '7px',
        },
        [theme.breakpoints.only('xs')]: {
			padding: '0 0 35px'
		},
        display: 'table',
        color: colors.webexuiWhite,
        fontSize: '16px',
        lineHeight: '19px',
        maxWidth: '1280px',
        backgroundColor: colors.webexuiDarkGrayish,
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    item: {
        color: colors.webexuiWhite,
        float: 'left',
        marginRight: '35px',
        [theme.breakpoints.only('xs')]: {
            minWidth: '150px',
        },
        minHeight: '30px',
        cursor: 'pointer',
        '&:hover': {
            color: '#676767',
        }
    },
    link: {
        textDecoration: 'none',
        color: colors.webexuiWhite,
        '&:hover': {
            color: '#676767',
        }
    }
}));