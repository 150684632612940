import { DashboardItemsActionType, DashboardItems } from "./types";
import { createReducer } from 'typesafe-actions';
import { DashboardItemActionType } from './actions';
import { RecommendedApplicationPreference, 
         RecommendedApplicationWithPreference, 
         RelevantWebsitePreference, 
         RelevantWebsiteWithPreference, 
         UsedApplicationPreference, 
         UsedApplicationWithPreference 
} from "../../api/user";

const reducer = createReducer<DashboardItems, DashboardItemActionType>({
        used_applications_loaded: {loaded: false, hasError: false},
        recommended_applications_loaded: {loaded: false, hasError: false},
        relevant_websites_loaded: {loaded: false, hasError: false}
    }, {
    [DashboardItemsActionType.SET_USED_APPLICATIONS]: (state: DashboardItems, action) => {
        return { ...state, used_applications: [...action.payload] }
    },
    [DashboardItemsActionType.SET_RECOMMENDED_APPLICATIONS]: (state: DashboardItems, action: any) => {
        return { ...state, recommended_applications: [...action.payload] }
    },
    [DashboardItemsActionType.SET_RELEVANT_WEBSITES]: (state: DashboardItems, action: any) => {
        return { ...state, relevant_websites: [...action.payload] }
    },
    [DashboardItemsActionType.SET_RECOMMENDED_APPLICATION_PREFERENCES]: (state: DashboardItems, action) => {

        const recommendedApplicationsWithPreferences = (state.recommended_applications || []).map((application: RecommendedApplicationWithPreference) => {
            const preference = action.payload.filter((pref: RecommendedApplicationPreference) => pref.client_id === application.client_id)
            
            if (preference.length === 0) {
                delete application.hidden;
                delete application.display_order;

                return {...application}
            }
            
            return {...application, ...preference[0]};
        })

        return { ...state, recommended_applications: recommendedApplicationsWithPreferences, recommended_applications_preferences: action.payload }
    },
    [DashboardItemsActionType.SET_RELEVANT_WEBSITES_PREFERENCES]: (state: DashboardItems, action) => {

        const relevantWebsitesWithPreferences = (state.relevant_websites || []).map((relevantWebsite: RelevantWebsiteWithPreference) => {
            const preference = action.payload.filter((pref: RelevantWebsitePreference) => pref.id === relevantWebsite.id)
            
            if (preference.length === 0) {
                delete relevantWebsite.hidden;
                delete relevantWebsite.display_order;

                return {...relevantWebsite}
            }

            return {...relevantWebsite, ...preference[0]};
        })

        return { ...state, relevant_websites: relevantWebsitesWithPreferences, relevant_websites_preferences: action.payload }
    },
    [DashboardItemsActionType.SET_USED_APPLICATION_PREFERENCES]: (state: DashboardItems, action) => {

        const usedApplicationsWithPreferences = (state.used_applications || []).map((application: UsedApplicationWithPreference) => {
            const preference = action.payload.filter((pref: UsedApplicationPreference) => pref.client_id === application.client_id)
            
            if (preference.length === 0) {
                delete application.hidden;
                delete application.display_order;

                return {...application}
            }

            return {...application, ...preference[0]};
        })

        return { ...state, used_applications: usedApplicationsWithPreferences, used_applications_preferences: action.payload }
    },
    [DashboardItemsActionType.SET_LOADED_FALSE]: (state: DashboardItems) => {
        return { ...state, used_applications_loaded: {loaded: false, hasError: false}, recommended_applications_loaded: {loaded: false, hasError: false}, relevant_websites_loaded: {loaded: false, hasError: false}}
    },
    [DashboardItemsActionType.SET_RECOMMENDED_APPLICATIONS_LOADED]: (state: DashboardItems, action) => {
        return { ...state, recommended_applications_loaded: action.payload }
    },
    [DashboardItemsActionType.SET_USED_APPLICATIONS_LOADED]: (state: DashboardItems, action) => {
        return { ...state, used_applications_loaded: action.payload }
    },
    [DashboardItemsActionType.SET_RELEVANT_WEBSITES_LOADED]: (state: DashboardItems, action) => {
        return { ...state, relevant_websites_loaded: action.payload }
    }
});

export { reducer as dashboardItemsReducer };