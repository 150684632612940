import React from 'react';
import { Field } from 'redux-form';
import { LabeledTextbox } from '../../components/RenderFields/RenderFields';
import { InputAdornment } from '@material-ui/core';

export const renderPassword = (showPassword: boolean, showCurrentPasswordClass: string, classCollection: any, setPasswordValue: Function, toggleShowPassword: Function, setShowPasswordClass: Function,  t: any) => {
    return (<Field
        name="password"
        id="dip_resend_password"
        label={t('login_signup.password.label')}
        component={LabeledTextbox}
        autoComplete="password"
        type={showPassword ? "text" : "password"}
        required={true}
        props={{showsubmitfailed: true}}
        onChange={({ target }: {target: HTMLInputElement}) => { setPasswordValue(target.value); }}
        InputProps={{
            endAdornment: (
              <InputAdornment position="end" >
                <i
                  className={showCurrentPasswordClass}
                  onClick={() => {setShowPasswordClass(!showPassword ? classCollection.sharedClasses.eyeActive : classCollection.sharedClasses.eyeInactive); toggleShowPassword(!showPassword)}}
                  onMouseOver= {() => {setShowPasswordClass(classCollection.sharedClasses.eyeHover)}}
                  onMouseOut= {() => {setShowPasswordClass(showPassword ? classCollection.sharedClasses.eyeActive : classCollection.sharedClasses.eyeInactive)}}
                />
              </InputAdornment>
            ),
            classes: {
                root: classCollection.classes.cssOutlinedInput,
                focused: classCollection.classes.cssFocused,
                input: classCollection.classes.blackText
            }
          }}
      />);
}

export const renderEmail = (emailValue: string, setEmailValue: Function, classes: any,  t: any) => {
    return (<Field
        name="email"
        id="dip_resend_email"
        label={t('login_signup.email.label')}
        component={LabeledTextbox}
        autoComplete="email"
        type="text"
        defaultValue={emailValue}
        required={true}
        props={{showsubmitfailed: true}}
        inputProps={{
          inputMode:"email",
          autoCapitalize:"off"
        }}
        InputProps={{
          classes: {
            input: classes.blackText
        }}}
        onChange={({ target }: {target: HTMLInputElement}) => { setEmailValue(target.value); }}
      />);
}