import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	mobileStoreLink: {
        textDecoration: 'none'
    },
    appStoreIcon: {
        height: '80px',
    },
    googlePlayIcon: {
        height: '80px'
    }
}));