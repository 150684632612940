import settings from '../config/settings';
import fetchApiWrapper from './fetchApiWrapper';

export interface PasswordComplexityApiModel {
    password_policy: string;
    password_no_personal_info: {
        enable: boolean
    };
    password_history: {
        enable: boolean,
        size: number
    };
    password_complexity_options: {
        min_length: number
    };
}

export const getPasswordComplexityData = (connection: string, accessToken: string): Promise<PasswordComplexityApiModel> => {
    return fetchApiWrapper(`${settings.backend.endpoint}/connections/${connection}/password-complexity`, {
        headers: {
          Authorization: `BEARER ${accessToken}`,
          'Content-Type': 'application/json-patch+json; charset=utf-8',
        },
        method: 'GET',
      });
}
  