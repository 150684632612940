import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './Styles';
import settings from '../../config/settings';
import { urlJoin } from 'url-join-ts';
import { useTranslation } from 'react-i18next';
import BenefitItem from './BenefitItem';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import { useAuthentication } from '../../Providers/AuthenticationProvider';
import { PreLoginContentProps } from './Types';
import { Spinner } from '@danfoss/webex-ui';
import parse from 'html-react-parser';

const PreLoginContent: React.FC<PreLoginContentProps> = ({ cultures }) => {
    const [backgroundImage, setBackgroundImage] = React.useState(urlJoin(settings.staticFiles.endpoint, `/images/system/PreLoginBackground0.jpg`)); 
    const [isLoading, setIsLoading] = React.useState(true);
    const classes = useStyles();
    const { t } = useTranslation();
    const { loginWithRedirect } = useAuthentication();
    
    useEffect(() => {
		const getImage = async () => {
            await new Promise(resolve => {
                const img = new Image();
                const imageUrl = urlJoin(settings.staticFiles.endpoint, `/images/system/PreLoginBackground${generateRandomNumber()}.jpg`);
                img.src = imageUrl;
                img.alt = '';
                img.onload = () => resolve(imageUrl);
            }).then(function(result:any) {
                setBackgroundImage(result);
                setIsLoading(false);
            })
        }

        getImage();

    }, []);

    const loginClick = () => {
        loginWithRedirect({ ui_locales: (cultures && cultures.currentCulture) || 'en', appState: {redirectUri: '/'} });
    };

    const signupClick = () => {
        loginWithRedirect({ ui_locales: (cultures && cultures.currentCulture) || 'en', appState: {redirectUri: '/'}, signup: true });
    };

    const generateRandomNumber = () => { 
        return (Math.floor(Math.random() * (20 - 1 + 1)) + 1)
    }
    
    return isLoading ? (<Spinner visible={isLoading}/>) : (
        <>
            <Grid container className={`${classes.root}`}>
                <Grid container className={classes.applicationTextContainer} style={{backgroundImage:`url(${backgroundImage})`}}>
                    
                    <Grid container className={`${classes.pictureBox}`}>
                        <Typography className={classes.pictureBoxHeader}> 
                            {parse(t('pre_login_screen.login_box_header').replace('<br>', ' '))}
                        </Typography>
                        <Grid item xs={12} className={`${classes.description} center`}>
                            {t('pre_login_screen.login_box_description')}
                        </Grid>
                        <Grid item className={classes.pictureBoxButtonContainer}>
                            <Grid item className={classes.buttonContainer}>
                                <SpinnerButton
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    pathToImagesFolder={urlJoin(settings.staticFiles.endpoint, '/images/system')}
                                    onClick={ () => {loginClick()}}
                                    >
                                    {t("login_signup.button.login")}
                                </SpinnerButton>
                            </Grid>
                            <Grid item className={classes.pictureBoxSignUpLinkContainer}>
                                <span className={classes.pictureBoxSignUpLink} onClick={() => signupClick()} id="SignUpLink">
                                    {t('pre_login_screen.login_box_signup_text')}
                                </span>
                            </Grid>
                        </Grid>
					</Grid>
                </Grid>
                <Grid container className={classes.descriptionContainer}>
                    <Grid container className={classes.innerContentMargin}>
                        <Grid item xs={12} className={classes.containerMaxWidth}>
                                <Typography className={classes.header}>
									{t('pre_login_screen.Description_box_header')}
								</Typography>
								<Grid item xs={12} className={classes.description}>
                                    {t('pre_login_screen.Description_box_description')}
								</Grid>
						</Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.benefitsContainer}>
                    <Grid container className={classes.innerContentMargin}>
                        <Grid item xs={12} className={classes.containerMaxWidth}>
                                <Typography className={classes.header}>
									{t('pre_login_screen.Benefits_box_header')}
								</Typography>
								<Grid container className={classes.benefitContainer} justifyContent="center">
                                    <BenefitItem
                                        icon={urlJoin(settings.staticFiles.endpoint, '/images/system/Home_Applications.svg')}
                                        header={t('pre_login_screen.Benefits_box_app_dashboard_header')}
                                        description={t('pre_login_screen.Benefits_box_app_dashboard_description')}
                                    />
                                    <BenefitItem
                                        icon={urlJoin(settings.staticFiles.endpoint, '/images/system/Home_Fingerprint.svg')}
                                        header={t('pre_login_screen.Benefits_box_your_details_header')}
                                        description={t('pre_login_screen.Benefits_box_your_details_description')}
                                    />
                                    <BenefitItem
                                        icon={urlJoin(settings.staticFiles.endpoint, '/images/system/Home_Lock.svg')}
                                        header={t('pre_login_screen.Benefits_box_password_header')}
                                        description={t('pre_login_screen.Benefits_box_password_description')}
                                    />
								</Grid>
						</Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

const mapStateToProps = (state: AppState) => ({
    cultures: state.cultures
});

export default connect(mapStateToProps)(PreLoginContent);