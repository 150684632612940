export interface ErrorInfo {
    statusText: string
    error_code?: string
}

export const getErrorText = (error: ErrorInfo ) : string | undefined => {
    if (error) {
        if (error.statusText === 'Unauthorized') {
            return "privacy_and_security.error_messages.cannot_authenticate_the_user"
        }
        else if (error.error_code === '429') { // Rate limits exceeded
            return "login_signup.error_messages.too_many_attempts"
        }
        else if (error.statusText === 'User or password not valid' || error.error_code === 'unauthenticated_user') {
            if (window.location.pathname.includes("/verification")) {
                return "email_verification.error_messages.user_or_password_not_valid"
            } else {
                return "privacy_and_security.error_messages.password_invalid"
            }
            
        }
        else if (error.statusText === 'Users email already verified' || error.error_code === 'email_already_verified') {
            return "email_verification.error_messages.users_email_already_verified"
        }
        else 
            return "privacy_and_security.error_messages.unknown";    
    }

    return undefined;
}