import settings from '../config/settings';
import fetchApiWrapper from './fetchApiWrapper';

export const resendVerificationEmail = (email: string, password: string, clientId: string, language: string) => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');

    return fetchApiWrapper(`${settings.backend.endpoint}/verification-email?version=v2`, {
        body: JSON.stringify({ email, password, client_id: clientId, language }),
        headers: requestHeaders,
        method: 'POST',
    })
}

export const sendWelcomeEmail = (email: string, language: string, token: string) => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${token}`)

    return fetchApiWrapper(`${settings.backend.endpoint}/welcome-emails`, {
        body: JSON.stringify({ email, language }),
        headers: requestHeaders,
        method: 'POST',
    })
}