import fetchApiWrapper from './fetchApiWrapper';
import settings from '../config/settings';
import { getRegistrationCenterApiKey } from '../utils';
import { FieldsMetaData } from '../store/fieldsMetadata/types';

export const getFieldsMetaData = (pageLanguage: string): Promise<FieldsMetaData> => {
    
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', getRegistrationCenterApiKey(settings.regCenterApi.hashKey));
    
    if (pageLanguage) {
        requestHeaders.append('Accept-Language', pageLanguage)
    }
    return fetchApiWrapper(`${settings.regCenterApi.endpoint}/fields-metadata`, {
        headers: requestHeaders,
        method: 'GET',
      });
}