export default <T>(input: RequestInfo, init: RequestInit, timeout: number): Promise<T> => {
	const controller = new AbortController();
	const id = setTimeout(() => controller.abort(), timeout);

	return fetch(input, { ...init, signal: controller.signal })
		.then(response => {
			clearTimeout(id);
			if (!response.ok) {
				return response
					.json()
					.then(data => {
						// eslint-disable-next-line
						throw { response, ...data };
					})
					.catch(data => {
						// eslint-disable-next-line
						throw { response, ...data };
					});
			}
			return response
				.json()
				.then(data => data as T)
				.catch(() => (response as unknown) as T);
		})
};