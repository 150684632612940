import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import Main from '../Main/Main';
import { AppState } from '../../store';
import { Profile } from '../../store/profile/types';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './Styles';
import { pushEvent } from '../../Services/AnalyticsService';
import PreLoginContent from './PreLoginContent';
import DashboardWrapper  from '../UserDashboard/DashboardWrapper';
import ClickOutsideWrapper from '../ClickOutsideWrapper';

interface HomeProps {
	profile?: Profile;
}

const Home: React.FC<HomeProps> = ({ profile }) => {
	const classes = useStyles();

	useEffect(() => {

		// Push an event that a user entered the home page
		pushEvent(
		  {
			  'event': 'virtualPageview',
			  'page': `${(window as any).location.hostname}/home${profile?'/loggedIn':''}`
		  }
		);
		
	  }, []);
	  
	return (
		<Main itemClass={classes.fullWidth}>
			<Grid container className={classes.fullHeight} id="clickoutsideContainer">
					{!profile ? (
						<PreLoginContent />
					) : (
						<ClickOutsideWrapper classNamePopupContainer={classes.leaveConfirmContainer}>
							<DashboardWrapper />
						</ClickOutsideWrapper>
					)}
					
			</Grid>
		</Main>
	);
}

const mapStateToProps = (state: AppState) => ({
	profile: state.profile,
});

export default connect(mapStateToProps)(Home);
