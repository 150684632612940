// Password complexity 
// @ts-ignore
import { PasswordPolicy }  from 'password-sheriff';
// @ts-ignore
import policies from 'auth0-password-policies';

const checkAgainstPasswordRules = (newPassword: string, pwComplexity: any) => {
  if (!pwComplexity || !newPassword) return;

  let auth0PasswordPolicy = pwComplexity.password_policy || 'none'

  // Auth0 will not return any password_policy if the policy is set to none, so this check is added 
  // to ensure, that the password policy should really be set to none. We know that if auth0 password
  // policy is set to none, then min length of the password is 1. If min length is different from 1
  // then an error has happened and we should default to a password policy of good.
  if(auth0PasswordPolicy === 'none' && pwComplexity.password_complexity_options.min_length > 1) {
    auth0PasswordPolicy = 'good';
  }
  //set policies matching Auth0 settings and check new password against that. Lenght can be different 
  // from the policy's default length, so we should replace length
  var containsAtLeastPolicy = new PasswordPolicy({...policies[`${auth0PasswordPolicy}`], length: {minLength: pwComplexity.password_complexity_options.min_length}});

  return !containsAtLeastPolicy.check(newPassword);
}

const validate = (values: any, props: any) => {
    const errors = {
      currentPassword: '',
      newPassword: '',
      retypeNewPassword: ''
    };

    if (props && checkAgainstPasswordRules(values.newPassword, props.passwordComplexity)) {
      errors.newPassword = 'privacy_and_security.error_messages.password_policy_not_met'; 
    }

    if (!values.currentPassword) {
      errors.currentPassword = 'privacy_and_security.currentPassword.validation';
    }
  
    if (values.newPassword === "") {
      errors.newPassword = 'privacy_and_security.newPassword.validation';
    }
  
    if (values.retypeNewPassword === "") {
      errors.retypeNewPassword = 'privacy_and_security.retypeNewPassword.validation';
    }
  
    if (values.newPassword && values.retypeNewPassword && values.newPassword !== values.retypeNewPassword) {
        errors.retypeNewPassword = 'privacy_and_security.no_match';
    }

    if (values.currentPassword && values.retypeNewPassword && !values.newPassword) {
      errors.newPassword = 'privacy_and_security.newPassword.validation';
    }
  
    if (values.newPassword && values.currentPassword && values.currentPassword === values.newPassword) {
        errors.newPassword = 'privacy_and_security.different_from_current';
    }
  
    if (values.retypeNewPassword && values.currentPassword && values.currentPassword === values.retypeNewPassword) {
        errors.retypeNewPassword = 'privacy_and_security.different_from_current';
    }

    return errors;
    
  };

  export default validate;