import { IJsHostService, jsHostService } from "./JsHostService";

export interface IAuthHostService {
    readonly jsHostService: IJsHostService
    getAccessToken(): Promise<{ token: string }>
    isAvailable: boolean;
}
export default class AuthHostService implements IAuthHostService {
    readonly scope: string
    readonly jsHostService: IJsHostService
    constructor(hostService: IJsHostService, scope: string = "app") {
        this.jsHostService = hostService;
        this.scope = scope;
    }

    /** Returns a promise that resolve with a valid access token. If user is not logged in or the host was unable to provide a valid token, the promise will be rejected */
    getAccessToken = () => this.jsHostService.call<{ token: string }>(this.scope, "auth", "getAccessToken");

    get isAvailable() { return this.jsHostService.isAvailable }
}

/** An instance of AuthHostService that uses the default jsHostService*/
export const authHostService = new AuthHostService(jsHostService) as IAuthHostService;