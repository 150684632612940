import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    breadCrumb: {
        color: colors.webexuiGrayLight,
        textDecoration: 'none',
        fontSize: '16px'       
    },
    breadCrumbItemSpacer: {
        margin: '0 9px'
    },
    breadCrumbContainer: {
        margin: '0 80px 22px 0px',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '10px'
        }
    }
}));