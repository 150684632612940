import { RecommendedApplicationPreference, RecommendedApplicationWithPreference, RelevantWebsitePreference, RelevantWebsiteWithPreference, UsedApplicationPreference, UsedApplicationWithPreference } from "../../api/user";

export interface DashboardItems {
    used_applications?: UsedApplicationWithPreference[],
    used_applications_preferences?: UsedApplicationPreference[],
    recommended_applications?: RecommendedApplicationWithPreference[],
    recommended_applications_preferences?: RecommendedApplicationPreference[],
    relevant_websites?: RelevantWebsiteWithPreference[],
    relevant_websites_preferences?: RelevantWebsitePreference[],
    used_applications_loaded: LoadingInformation,
    recommended_applications_loaded: LoadingInformation,
    relevant_websites_loaded: LoadingInformation
}

export enum DashboardItemsActionType {
    SET_USED_APPLICATION_PREFERENCES = '@@dashboardItems/SET_USED_APPLICATIONS_PREFERENCES',
    SET_RECOMMENDED_APPLICATION_PREFERENCES = '@@dashboardItems/SET_RECOMMENDED_APPLICATIONS_PREFERENCES',
    SET_RELEVANT_WEBSITES_PREFERENCES = '@@dashboardItems/SET_RELEVANT_WEBSITES_PREFERENCES',
    GET_DASHBOARD_ITEMS = '@@dashboardItems/GET_DASHBOARD_ITEMS',
    GET_USED_APPLICATIONS = '@@dashboardItems/GET_USED_APPLICATIONS',
    GET_RECOMMENDED_APPLICATIONS = '@@dashboardItems/GET_RECOMMENDED_APPLICATIONS',
    GET_RELEVANT_WEBSITES = '@@dashboardItems/GET_RELEVANT_WEBSITES',
    SET_DASHBOARD_ITEMS = '@@dashboardItems/SET_DASHBOARD_ITEMS',
    SET_USED_APPLICATIONS = '@@dashboardItems/SET_USED_APPLICATIONS',
    SET_RECOMMENDED_APPLICATIONS = '@@dashboardItems/SET_RECOMMENDED_APPLICATIONS',
    SET_RELEVANT_WEBSITES = '@@dashboardItems/SET_RELEVANT_WEBSITES',
    UPDATE_USER_PREFERENCES = '@@dashboardItems/UPDATE_USER_PREFERENCES',
    SET_RELEVANT_WEBSITES_LOADED = '@@dashboardItems/SET_RELEVANT_WEBSITES_LOADED',
    SET_RECOMMENDED_APPLICATIONS_LOADED = '@@dashboardItems/SET_RECOMMENDED_APPLICATIONS_LOADED',
    SET_USED_APPLICATIONS_LOADED = '@@dashboardItems/SET_USED_APPLICATIONS_LOADED',
    SET_LOADED_FALSE = '@@dashboardItems/SET_LOADED_FALSE'
}

export interface LoadingInformation {
    loaded: boolean,
    hasError: boolean
}