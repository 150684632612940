import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	sidebar: {
        backgroundColor: colors.webexuiGray
    },
    container: {
        margin: '0 80px',
        [theme.breakpoints.between('sm', 'md')]: {
            margin: '0 40px'
        },
        [theme.breakpoints.only('xs')]: {
            margin: 0
        },
        maxWidth: '100%'
    },
    containerFullWidth: {
        margin: '0',
        maxWidth: '100%'
    },
    marginLeft: {
        marginLeft: '5px !important'
    },
    popupContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        position: 'absolute',
        top: '-50px',
      },
      popup: {
        top: '233px',
        maxWidth: '650px',
        display: 'table',
        marginTop: 'auto !important',
        [theme.breakpoints.up('sm')]: {
          marginLeft: '-27px'
        }
      },
      overlay: {
        [theme.breakpoints.only('xs')]: {
          marginTop: '10px',
          height: '100%'
        }
      },
      close: {
        cursor: 'pointer',
        width: '16px',
        [theme.breakpoints.only('xs')]: {
            margin: '10px -16px',
        },
        [theme.breakpoints.up('sm')]: {
                right: '16px'
            },
        position: 'absolute'
    },
    maintenanceHeader: {
        fontSize: '24px',
        fontWeight: 'bold',
        margin: '20px 0 16px'
      },
}));