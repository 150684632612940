import { IJsHostService, jsHostService } from "./JsHostService";

export interface ISubActivityHostService {
    readonly jsHostService: IJsHostService
    close(): Promise<boolean>
}
export default class SubActivityHostService implements SubActivityHostService {
    readonly scope: string
    readonly jsHostService: IJsHostService
    constructor(hostService: IJsHostService, scope: string = "app") {
        this.jsHostService = hostService;
        this.scope = scope;
    }

    /** Call the host to close this activity */
    close = () => this.jsHostService.call<boolean>(this.scope, "activity", "close");

    get isAvailable() { return this.jsHostService.isAvailable }
}

/** An instance of SubActivityHostService that uses the default jsHostService*/
export const subActivityHostService = new SubActivityHostService(jsHostService) as ISubActivityHostService;