import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Main from '../Main/Main';
import { useStyles } from './Styles';
import { urlJoin } from 'url-join-ts';
import settings from '../../config/settings';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import Banner from '../Banner';
import { useNavigate } from 'react-router-dom'
import { YourProfileProps} from './Types';
import parse from 'html-react-parser';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const YourProfile: React.FC<YourProfileProps> = ({ profile }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const navigate = useNavigate();

    const handleBannerClick = (link: string) => {
        return navigate(link);
    }

    return (
        <Main>
            <Grid container className={`${classes.root} test`}>
                <Typography className={classes.header}>
                    {t('your_profile.header')}
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.description}>
                {t('your_profile.description')}
            </Grid>
            <Grid container className={`${classes.bannerIconContainer}`} spacing={3}>
                { /* @ts-ignore */ }
                <DndProvider backend={HTML5Backend}>
                    <Banner
                        bannerClick={() => handleBannerClick('/edit-profile')}
                        bannerClass={classes.banner}
                        bannerIconBorderClass={classes.bannerIconBorder}
                        bannerTextClass={classes.bannerText}
                        bannerIcon={urlJoin(settings.staticFiles.endpoint, '/images/system/YourProfile_UserAccount.svg')}
                        bannerIconHover={urlJoin(settings.staticFiles.endpoint, '/images/system/YourProfile_UserAccount_hover.svg')}
                        bannerText={t('header.edit-profile')}
                        id="banner-edit-profile"
                        itemType={"yourProfile"}
                        hidden={false}
                        userEmail={profile?.email || ""}
                    />
                    <Banner
                        bannerClick={() => handleBannerClick('/change-password')}
                        bannerClass={classes.banner}
                        bannerIconBorderClass={classes.bannerIconBorder}
                        bannerTextClass={classes.bannerText}
                        bannerIcon={urlJoin(settings.staticFiles.endpoint, '/images/system/YourProfile_Password.svg')}
                        bannerIconHover={urlJoin(settings.staticFiles.endpoint, '/images/system/YourProfile_PasswordHover.svg')}
                        bannerText={t('header.multifactor-settings')}
                        id="banner-multifactor-settings"
                        itemType={"yourProfile"}
                        hidden={false}
                        userEmail={profile?.email || ""}
                    />
                </DndProvider>
            </Grid>
        </Main>
    );
};

const mapStateToProps = (state: AppState) => ({
    profile: state.profile
});

export default connect(mapStateToProps)(YourProfile);
