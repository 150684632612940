import { action } from 'typesafe-actions';
import { LegalInfoActionTypes, LegalInfo } from './types';

export interface GetLegalInfo {
	type: LegalInfoActionTypes.GET_LEGALINFO;
}

export interface SetLegalInfo {
    type: LegalInfoActionTypes.SET_LEGALINFO;
    payload: LegalInfo
}

export const getLegalInfo = (): GetLegalInfo =>
    action(LegalInfoActionTypes.GET_LEGALINFO)
    
export const setLegalInfo = (legalInfo: LegalInfo): SetLegalInfo =>
	action(LegalInfoActionTypes.SET_LEGALINFO, {...legalInfo})

export type LegalInfoActionType = SetLegalInfo;