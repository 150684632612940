import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm, hasSubmitSucceeded, FormErrors, stopSubmit } from 'redux-form';
import { Container, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import queryString from 'query-string';
import { Spinner } from '@danfoss/webex-ui';
import { renderPassword, renderEmail } from './FieldRenderer';
import i18n from "i18next";
import { AppState } from "../../store";
import { urlJoin } from 'url-join-ts';
import settings from '../../config/settings';

import { FormContainerStyle } from '@danfoss/webex-ui/dist/mui';
import { resendEmailVerification, resendPasswordReset } from '../../store/emailResend/actions';
import { EmailVerificationData, PasswordResetData } from '../../store/emailResend/types';
import { useStyles } from './Styles';
import { useSharedStyles } from '../../sharedStyles/Styles';
import DOMPurify from 'dompurify';
import { Cultures } from '../../store/cultures/types';

interface Links {
    linkText: string,
    onClick: Function,
    id?: string
}

interface Props {
    cultures: Cultures
    submitting: any,
    validationForm: any,
    handleSubmit: Function,
    clientId: string,
    sendEmailVerification: Function,
    headerText: string,
    bodyText: string,
    buttonText: string,
    links?: Links[],
    sendPasswordReset: Function,
    type: string,
    showPasswordField?: boolean
}

const validEmail = (email: string) => {
    // eslint-disable-next-line
    return (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email));
}

const validate = (values: any, _props: any) => {
    const errors: FormErrors<any, string> = {
    };
  
    const { email } = values;
    if (!email) {
      errors.email = '';
    }
    else {
      if (!validEmail(email)) {
        errors.email = i18n.t(`login_signup.email.validation.valid_email`);
      }
    }
  
    errors.password = '';  
  
    return errors;
};
  
const asyncValidate = (values: any, _dispath: any, props: any) => {
    return new Promise((resolve, _reject) => {
        
      const errors = validate(values, props);
      if (Object.keys(errors).length === 0) {
        resolve(true);
      }
      throw (errors);
    });
}
  
const Resend: React.FC<Props> = ({cultures, submitting, validationForm, clientId, sendEmailVerification, headerText, bodyText, buttonText, links, sendPasswordReset, type, showPasswordField}) => {
    const classes = useStyles();
    const sharedClasses = useSharedStyles();
    const [showPassword, toggleShowPassword] = useState(false);
    const [showPasswordClass, setShowPasswordClass] = useState(sharedClasses.eyeInactive);
    const [emailValue, setEmailValue] = useState('');
    const [clientIdValue, setClientIdValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const { t } = useTranslation();
    const formClasses = FormContainerStyle();

    useEffect(() => {
        const parsed = queryString.parse(window.location.search); 
        setEmailValue(DOMPurify.sanitize(parsed.email as string) || '') 

        if (clientId) {
            setClientIdValue(clientId);
        } else {
            setClientIdValue(DOMPurify.sanitize(parsed.clientId as string) || '')      
        }
    }, []);
    
    const submitForm = ( e: React.FormEvent<HTMLFormElement> ) => {
        e.preventDefault();

        if (emailValue !== '') {
            if (type === 'passwordReset') {
                sendPasswordReset({email: emailValue, clientId: clientIdValue });
            } else {
                sendEmailVerification({email: emailValue, password: passwordValue, clientId: clientIdValue, language: cultures.currentCulture })
            }
            
        }
    }
    
    const validateForm = () => {
        if (emailValue === "" || !validEmail(emailValue)) {
            return true;
        }
        
        if ((showPasswordField !== false) && passwordValue === "") {
            return true;
        }

        if (validationForm.submitFailed) {
            return true;
        }
        
        return false; 
    }

    if (validationForm.submitSucceeded) {
        if (type === 'passwordReset') {
            document.location.href = `/passwordreset?clientId=${clientIdValue}&message=reset-email-sent`;
        } else {
            document.location.href = `/verification?clientId=${clientIdValue}&code=verification-email-sent`;
        }
        
    }

    return (
        <Container>
            <Grid container>
                <div className={classes.spinner}>
                    <Spinner visible={submitting} />
                </div>
                <Grid item xs={12} className={sharedClasses.formContainer}>
                    <form onSubmit={e => submitForm(e)} name="emailResendForm">
                        <Grid item xs={12}>
                            <Typography className={`${classes.mainTitle} ${formClasses.mainTitle}`}>
                                {headerText}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.textContainer}>
                            {bodyText}
                        </Grid>
                        <Grid container className={classes.inputContainer}>
                            <Grid item xs={12}>
                                {renderEmail(emailValue, setEmailValue, classes, t)}
                            </Grid>
                            {(showPasswordField !== false) && (
                                <Grid item xs={12}>
                                    {renderPassword(showPassword, showPasswordClass, {classes: classes, sharedClasses: sharedClasses}, setPasswordValue, toggleShowPassword, setShowPasswordClass, t)}
                                </Grid>
                            )}
                        </Grid>                      
                        <Grid container className={classes.buttonContainer}>
                            <Grid item xs={12}>
                                <Button 
                                    disabled={validateForm() || submitting}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    
                                >
                                    {buttonText} 
                                </Button>
                            </Grid>
                        </Grid>
                        { links && (<Grid container>
                            {links.map(link => {
                                return (
                                    <Grid item xs={12} className={classes.linkContainer} key={links.indexOf(link)}>
                                        <span onClick={() => link.onClick()} id={link.id}>
                                            <img src={urlJoin(settings.staticFiles.endpoint,'/images/system/LinkArrow.svg')} className={classes.linkArrow} alt="" /> {link.linkText}
                                        </span>
                                    </Grid>
                                )
                            })}
                        </Grid>
                        )}
                    </form>
                </Grid>
            </Grid> 
        </Container>
    );
}

const mapStateToProps = (state: AppState) => ({
    cultures: state.cultures,
    submitSucceeded: hasSubmitSucceeded('emailResendForm')(state),
    validationForm: state.form!.emailResendForm || {}
});

const mapDispatchToProps = (dispatch: any) => () => ({
    sendEmailVerification: (data: EmailVerificationData) => dispatch(resendEmailVerification(data)),
    sendPasswordReset: (data: PasswordResetData) => dispatch(resendPasswordReset(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    asyncValidate,
    form: 'emailResendForm',
    asyncBlurFields: ['email'],
    asyncChangeFields: [],
    onChange: (_values, dispatch, props) => {
        if (props.submitFailed) {
          dispatch(stopSubmit('emailResendForm'));
        }
      }
  })(Resend));