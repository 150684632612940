import React, { useState } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { useStyles } from './Styles';
import { urlJoin } from 'url-join-ts';
import settings from '../../config/settings';
   
interface Props {
    handleButtonClick: Function,
    headerText: any,
    bodyText: any | string,
    buttonText: any,
    clientAppText?: any,
    detailedErrorDescription?: any,
    expandDetailedErrorDescription? : boolean,
    dip_app_type? : string
}
  
const Information: React.FC<Props> = ({handleButtonClick, headerText, bodyText, buttonText, clientAppText, detailedErrorDescription, expandDetailedErrorDescription, dip_app_type}) => {

    const [showDetailedError, setShowDetailedError] = useState(expandDetailedErrorDescription && detailedErrorDescription);
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Container>
            <Grid container className={classes.contentContainer}>
                <Grid item xs={12}>
                    <Typography className={`${classes.mainTitle}`}>
                        {headerText}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.textContainer}>
                    {bodyText}
                </Grid>
                {detailedErrorDescription && detailedErrorDescription.length > 0 &&  
                    <Grid item xs={12} className={classes.detailedErrorContainer}>
                        <React.Fragment>
                            <div className={classes.showHide} onClick={() => { setShowDetailedError(!showDetailedError) }}>
                                {showDetailedError ?
                                    <img src={urlJoin(settings.staticFiles.endpoint,'/images/system/ChevronDownRed.svg')} className={classes.iconStyleRotate} alt="" />
                                :
                                    <img src={urlJoin(settings.staticFiles.endpoint,'/images/system/ChevronDownRed.svg')} className={classes.iconStyle} alt="" />
                                }
                                <span>{t(`custom_error_page.${!showDetailedError ? 'show_detailed_info' : 'hide_detailed_info'}`)}</span> 
                            </div>
                        </React.Fragment>
                            {showDetailedError ?
                        <div className={classes.wordBreak}>
                            {detailedErrorDescription}
                        </div>
                        :null}
                    </Grid>
                }
                {dip_app_type !== 'client_app' ?
                    <Grid container className={classes.buttonContainer}>
                        <Grid item xs={12}>
                            <Button 
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={handleButtonClick()}
                                id="dip_information_submit"
                            >
                                {buttonText} 
                            </Button>
                        </Grid>
                    </Grid>
                    :
                    <Grid container className={classes.textContainer}>
                        <Grid item xs={12}>
                            {clientAppText}
                        </Grid>
                    </Grid>
                }
            </Grid> 
        </Container>
    );
}

export default Information;