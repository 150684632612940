import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { startSubmit, stopSubmit, setSubmitFailed, setSubmitSucceeded } from 'redux-form'

import { resendVerificationEmail } from "../../api/email";
import { ErrorInfo, getErrorText } from '../../utils/errorHandling';
import { emailResendActionTypes } from './types';
import fetchApiWrapper from '../../api/fetchApiWrapper';
import settings from '../../config/settings';

export function* resendVerificationEmailRequest(action: any) {
    const { email, password, clientId, language } = action.payload;
    const FormId = 'emailResendForm';
    
    try {
      yield put(startSubmit(FormId));
    
      const response: Response = yield call(resendVerificationEmail, email, password, clientId, language);
      
      if (response.ok) {
        yield put(stopSubmit(FormId));
        yield put(setSubmitSucceeded(FormId));
      } else {
        yield put(stopSubmit(FormId, {email: getErrorText(response), password: [""]}));
        yield put(setSubmitFailed(FormId));
      }
    } catch (error) {
      yield put(stopSubmit(FormId, {email: getErrorText(error as ErrorInfo), password: [""]}));
      yield put(setSubmitFailed((FormId)))
    }
  }

  export function* resendPasswordEmailRequest(action: any) {
    const { email, clientId } = action.payload;
    const FormId = 'emailResendForm';
    
    try {
      yield put(startSubmit(FormId));
    
      const requestHeaders = new Headers();
      requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');

      const body = {
          "client_id": clientId,
          "connection": "danfoss-external",
          "email": email
      }

      let response: Response = yield fetchApiWrapper(`https://${settings.auth0.customDomain}/dbconnections/change_password`, {
          body: JSON.stringify(body),
          headers: requestHeaders,
          method: 'POST',
      });
      
      if (response.ok) {
        yield put(stopSubmit(FormId));
        yield put(setSubmitSucceeded(FormId));
      } else {
        yield put(stopSubmit(FormId, {email: getErrorText(response), password: [""]}));
        yield put(setSubmitFailed(FormId));
      }

      
    } catch (error) {
      yield put(stopSubmit(FormId, {email: getErrorText(error as ErrorInfo), password: [""]}));
      yield put(setSubmitFailed((FormId)))
    }
  }

  export function* watchResendVerificationEmail() {
    yield takeEvery(emailResendActionTypes.RESEND_VERIFICATION_EMAIL, resendVerificationEmailRequest)
  }

  export function* watchResendPasswordEmail() {
    yield takeEvery(emailResendActionTypes.RESEND_RESET_PASSWORD, resendPasswordEmailRequest)
  }
  
  export function* emailResendSagas() {
    yield all([
      fork(watchResendVerificationEmail),
      fork(watchResendPasswordEmail)
    ])
  }
  
  export default emailResendSagas;