import { fork, all, put } from 'redux-saga/effects';
import profileSagas from './profile/sagas';
import culturesSagas from './cultures/sagas';
import dashboardItemsSagas from './dashboadItems/sagas'
import legalInfoSagas from './legalInfo/sagas';
import fieldsMetadataSagas from './fieldsMetadata/sagas';
import emailResendSagas from './emailResend/sagas';
import { getCultures } from './cultures/actions';
import { getLegalInfo } from './legalInfo/actions';

export default function* rootSaga() {
  yield all([
    fork(profileSagas),
    fork(emailResendSagas),
    fork(culturesSagas),
    fork(dashboardItemsSagas),
    fork(legalInfoSagas),
    fork(fieldsMetadataSagas),
    put(getCultures()),
    put(getLegalInfo())
  ]);
}

