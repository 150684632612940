import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthentication } from '../../Providers/AuthenticationProvider';
import { Spinner } from '@danfoss/webex-ui';

interface LoginProps {
	redirectUrl?: string;
}

const Login: React.FC<LoginProps> = (props) => {

	const {loading, error} = useAuthentication();
	const {redirectUrl} = props;

	const error_code = error && error.error;
	const error_description = error && error.error_description;
	if (error && error_code === 'unauthorized' && error_description === 'user is blocked') {
		return <Navigate to={`/error?error_type=blocked_user&error_code=${error_code}&error_description=${error_description}` || "/"} replace />;
	} else if (error && error_code === 'unauthorized') {
		return <Navigate to={`/error?error_type=unauthorized&error_code=${error_code}&error_description=${error_description}` || "/"} replace />;
	} else if (error && error_code === 'access_denied') {
		return <Navigate to={`/error?error_type=login_error&error_code=${error_code}&error_description=${error_description}` || "/"} replace />;
	} else if (!loading) {
		return <Navigate to={redirectUrl || "/"} replace />;
	}
	
	return <Spinner visible={true}/>;
}


export default Login;
