import fetchApiWrapper from './fetchApiWrapper';
import settings from '../config/settings';

export interface LocationApiCountryValue {
    alpha2: string,
    name: string
}

export interface LocationApiCountries {
    value: LocationApiCountryValue[]
}

export interface Country {
    id: string
    text: string
}

export interface LocationApiCountryStates {
    value: LocationApiCountryStateValue[]
}

export interface LocationApiCountryStateValue {
    name: string,
    divisions: LocationApiCountryState[]
}

export interface LocationApiCountryState {
    id: string,
    name: string
}

export interface LocationApiCallingCodes {
    value: LocationApiCallingCodeValue[]
}

export interface LocationApiTimezones {
    value: LocationApiTimezone[]
}

export interface LocationApiTimezone {
    id: string,
    name: string,    
    base_utc_offset: string
}

export interface Timezone {
    id: string,
    text: string    
}

export interface LocationApiCallingCodeValue {
    name: string,
    alpha2: string,
    calling_codes: string[]
}

export interface CallingCode {
    id: string
    text: string
}

export interface State {
    id: string
    text: string
}

export const getStatesFormatted = (states: LocationApiCountryStates) => {
    return 	(states && states.value && states.value.length > 0 &&
            states.value[0].divisions.map(state => {
               return { id: state.id, text: state.name };
            }, [])) || [];
}

const compareTimeZones = (t1: LocationApiTimezone, t2: LocationApiTimezone) => {
    
    const t1Offset = parseFloat(t1.base_utc_offset.replace(/:/g, ".")) || 0;
    const t2Offset = parseFloat(t2.base_utc_offset.replace(/:/g, ".")) || 0;

    if (t1Offset === t2Offset) {
        return t1.name.localeCompare(t2.name);
    } 

    return t2Offset < t1Offset ? 1 : -1;
}

export const getTimezonesFormatted = (timezones: LocationApiTimezones) => {

    if (!timezones || !timezones.value) return [];
    
    timezones.value.sort((a, b) => compareTimeZones(a, b));

    return timezones.value.map(timezone => { 
        return {id: timezone.id, text: timezone.name};
    }, []) || [];
    
}

export const getCountries = async (accessToken: string, pageLanguage: string): Promise<LocationApiCountries> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper(`${settings.backend.endpoint}/location/countries?%24select=alpha2%2C%20name&%24orderby=name&language=${pageLanguage}`, {
        headers: requestHeaders,
        method: 'GET',
      });
}

export const getStates = async (accessToken: string, countryCode: string, pageLanguage: string): Promise<LocationApiCountryStates> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper(`${settings.backend.endpoint}/location/countries?%24select=name&%24filter=alpha2%20eq%20'${countryCode}'&%24expand=divisions&language=${pageLanguage}`, {
        headers: requestHeaders,
        method: 'GET',
      });
}

export const getCallingCodes = async (accessToken: string, pageLanguage: string): Promise<LocationApiCallingCodes> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper(`${settings.backend.endpoint}/location/countries?%24select=name%2C%20alpha2%2C%20calling_codes&%24orderby=name&language=${pageLanguage}`, {
        headers: requestHeaders,
        method: 'GET',
      });
}

export const getTimezones = async (accessToken: string): Promise<LocationApiTimezones> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper(`${settings.backend.endpoint}/location/timezones?%24select=id%2C%20name%2C%20base_utc_offset`, {
        headers: requestHeaders,
        method: 'GET',
      });
}