import { action } from 'typesafe-actions';
import { FieldsMetadataActionType, FieldsMetaData } from './types';

export interface SetupFieldsMetadata {
	type: FieldsMetadataActionType.SET_FIELDSMETADATA;
	payload: FieldsMetaData;
}

export interface GetFieldsMetadata {
	type: FieldsMetadataActionType.GET_FIELDSMETADATA;
}

export const setFieldsMetadata = (fieldsConfig: FieldsMetaData): SetupFieldsMetadata => action(FieldsMetadataActionType.SET_FIELDSMETADATA, fieldsConfig);

export const getFieldsMetadata = () : GetFieldsMetadata => action(FieldsMetadataActionType.GET_FIELDSMETADATA);	

export const setFieldDataSuccess = () => action(FieldsMetadataActionType.SET_FIELDSMETADATA_SUCCESS);

export type FieldsMetadataActionTypes = SetupFieldsMetadata;
