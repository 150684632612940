import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from '@danfoss/webex-ui';
import { useAuthentication } from '../../Providers/AuthenticationProvider';
import { AppState } from "../../store";
import { Cultures } from '../../store/cultures/types';

interface InitiateLoginProps {
  cultures: Cultures
}

const InitiateLogin: React.FC<InitiateLoginProps> = (props) => {

  const {loading,  loginWithRedirect } = useAuthentication();
  const { cultures } = props;
  if (!loading) {
    // redirectUri must be set to '/' in order not to redirect back to initiate-login after login. Else we get a loop of logins
    loginWithRedirect({appState: {redirectUri: '/'}, ui_locales: (cultures && cultures.currentCulture) || 'en' });
  }
  return (
    <Spinner visible={true} />
  );
};

const mapStateToProps = (state: AppState) => ({
  cultures: state.cultures
});

export default connect(mapStateToProps)(InitiateLogin);
