import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { ClickOutsideWrapperType } from './types'
import equal from 'deep-equal';
import FormLeaveConfirm from '../FormLeaveConfirm';
import { isIE } from 'mobile-device-detect';


const ClickOutsideWrapper: React.FC<ClickOutsideWrapperType> = ({ children, classNamePopupContainer, classNamePopup }) => {
    const [showLeaveConfirm, setShowLeaveConfirm] = useState(false);
	const [eventSaved, setEventSaved] = useState(false);
    const [clickEvent, setClickEvent] = useState({} as MouseEvent);
    const [isFormDirty, setIsFormDirty] = useState(true);
    const [isobjectsSet, setIsObjectSet] = useState(false);
    const [validationErrors, setValidationErrors] = useState("");
    const [oldObject] = useState({});
    const [newObject] = useState({});
    const [resetForm] = useState({} as Function);
    const [showLeave] = useState({} as Function);
    const [submitButton] = useState({} as HTMLButtonElement);
    
    const isFormDirtyRef = React.useRef(isFormDirty);
    const oldObjectRef = React.useRef(oldObject);
    const newObjectRef = React.useRef(newObject);
	const eventRef = React.useRef(clickEvent);
    const resetFormRef = React.useRef(() => resetForm());
    const setShowLeaveFunctionRef = React.useRef((show: boolean) => showLeave(show));
    const submitButtonRef = React.useRef(submitButton);
    
    const setIsFormDirtyStataAndRef = (isDirty: boolean) => {
		isFormDirtyRef.current = isDirty;
		setIsFormDirty(isDirty);
	};

    const setResetFormAndRef = (resetFormFunction: Function) => {
		resetFormRef.current = () => resetFormFunction();
	};

    const setShowLeaveRef = (setShowLeaveFunction: Function) => {
        setShowLeaveFunctionRef.current = (show: boolean) => setShowLeaveFunction(show);
	};

    const setSubmitButtonAndRef = (submitBtn: HTMLButtonElement) => {
		submitButtonRef.current = submitBtn;
	};

    const setClickEventStateAndRef = (event: MouseEvent) => {
		eventRef.current = event;
		setClickEvent(event);
	};

    const setObjectStateAndRef = (oldObj: any, newObj: any) => {
        oldObjectRef.current = oldObj;
        newObjectRef.current = newObj;
	};

    useEffect(() => {

        if (isobjectsSet) {
            document.addEventListener('click', (event: any) => handleClickOutside(event, isFormDirtyRef, oldObjectRef, newObjectRef, eventSaved), true);

            return () => {
                // When unmounting make sure to remove the eventListener again
                document.removeEventListener('click', (event: any) => handleClickOutside(event, isFormDirtyRef, oldObjectRef, newObjectRef, eventSaved), true);
            }
        }
	}, [isobjectsSet]);

    const setLeaveConfirmValues = (oldObj: any, newObj: any, formDirty: boolean, resetFormFunction: Function, submitBtn: HTMLButtonElement, setShowLeaveFunction: Function) => {
        setObjectStateAndRef(oldObj, newObj);
        setIsFormDirtyStataAndRef(formDirty);
        setResetFormAndRef(() => resetFormFunction());
        if (setShowLeaveFunction) {
            setShowLeaveRef((show: boolean) => setShowLeaveFunction(show));
        } else {
            setShowLeaveRef(() => {});
        }
        setSubmitButtonAndRef(submitBtn);
        setIsObjectSet(true);
    }    

    const shouldClickTriggerWarning = (event: any) => {
        return ((event.target.parentNode && event.target.parentNode.className && event.target.parentNode.className.indexOf("triggerWarning") > -1) || (event.target && event.target.className && event.target.className.indexOf("triggerWarning") > -1))
    }

    const handleClickOutside = (event: any, formDirty: React.MutableRefObject<boolean>, oldObj: React.MutableRefObject<any>, newObj: any, wasEventSaved: boolean) => {
        
        const domNode = document.getElementById('ClickoutsideContainer');

        if (formDirty.current && (!domNode || !domNode.contains(event.target)) && shouldClickTriggerWarning(event)) {
            
            if (wasDataUpdated(oldObj, newObj) && !wasEventSaved) {
                event.preventDefault();
                event.stopPropagation();
                setShowLeaveConfirm(true);
                setShowLeaveFunctionRef.current && setShowLeaveFunctionRef.current(true)
                setClickEventStateAndRef(event);
                setEventSaved(true);
            } else {
                setShowLeaveConfirm(false);
                setEventSaved(false);
            }
        }
    }

    const wasDataUpdated = (oldObjRef: React.MutableRefObject<any>, newObjRef: React.MutableRefObject<any>) => {
        if (!equal(oldObjRef.current, newObjRef.current)) {
            return true;
        }
    
        return false;
    }

    const hideLeaveConfirm = () => {
        if (isIE) {
            setIsFormDirtyStataAndRef(false);
        }
        setEventSaved(false);
        setShowLeaveConfirm(false);
        //setClickEventStateAndRef({} as MouseEvent);
        submitButtonRef && submitButtonRef?.current?.click();
    }

    const fireEvent = (event: any) => {

        if (eventRef.current && Object.keys(eventRef.current).length > 0) {
  
            const clickEventToFire = eventRef.current;
    
            event.preventDefault();
            event.stopPropagation();
            resetFormRef.current();
            setIsFormDirtyStataAndRef(false);
            setEventSaved(false);
            setShowLeaveConfirm(false);
            setClickEventStateAndRef({} as MouseEvent);
    
            const new_e = new MouseEvent(clickEventToFire.type, clickEventToFire);
            
            clickEvent.target && clickEvent.target.dispatchEvent(new_e)
    
        }
    }

    return (
        <Grid container>
            {showLeaveConfirm && (
            <FormLeaveConfirm
				setShowLeave={() => setShowLeaveConfirm(false)}
                onSave={() => hideLeaveConfirm()}
				onLeave={(event: any) => fireEvent(event)}
                validationErrors={validationErrors}
                classNamePopupContainer={classNamePopupContainer}
                classNamePopup={classNamePopup}
			/>)}
            {React.cloneElement(children, { fireEvent: (event: any) => fireEvent(event), setValidationErrors: (errors: string) => setValidationErrors(errors), setLeaveConfirmValues: (oldObj: any, newObj: any, isDirty: boolean, resetFormFunk: Function, submitBtn: HTMLButtonElement, setShowLeaveFunction: Function) => setLeaveConfirmValues(oldObj, newObj, isDirty, resetFormFunk, submitBtn, setShowLeaveFunction) })}
        </Grid>
    );
}

export default ClickOutsideWrapper;