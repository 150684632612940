import { all, fork, put, call, takeEvery, select } from 'redux-saga/effects';
import { startSubmit, stopSubmit, setSubmitFailed, setSubmitSucceeded } from 'redux-form';
import { Profile, ProfileActionTypes, UserDataResponse } from './types';
import { clearProfile, login, getUserError, updateUser as actionUpdateUser, enhanceUserWithData as actionEnhanceUserWithData } from './actions';
import { AppState } from "../index";
import { cultureChange } from "../cultures/actions";
import { changePassword, updateUser, getAuth0User } from "../../api/user";
import { getErrorText } from '../../utils/errorHandling'
import { getUserLanguage } from '../../utils/userLanguage';
import { authenticationClient } from '../../Providers/AuthenticationProvider';
import { getUsedApplications, getRecommendedApplications, getRelevantWebsites, setLoadedFalse } from '../../store/dashboadItems/actions'

export function* checkUserAction(action: any) {
  try {
    const { payload } = action;

    const isAuthenticated: boolean = yield authenticationClient.isAuthenticated();

    if (!isAuthenticated) {
      return;
    }

    const accessToken: string = payload || (yield authenticationClient.getTokenSilently());
    const responseUser: Profile = yield authenticationClient.getUser();

    yield put(login({...responseUser, userdata_read: false }));
    yield call(enhanceUserWithData, responseUser.sub || '', accessToken, '');

  } catch (error: any) {
    yield put(getUserError(error));
  }
}

export function* updateUserAction(action: any) {
  const FormId = 'dipProfile';

  const { accessToken, userId, language_id, nativeMode, successCallback, ...userData } = action.payload;
  const pageLanguage = sessionStorage.getItem('selectedCulture') || language_id;

  let data = {
    ...userData, language_id
  };

  if (userData.first_name) {
    data = {
      ...data, name: `${userData.first_name} ${userData.last_name}`
    };
  }

  try {
    yield put(startSubmit(FormId));
    yield call(updateUser, userId, pageLanguage, accessToken, data);
    // update user profile and submit succeeded if called from normal browser session
    if (!nativeMode) {
      
      yield put(actionUpdateUser({...data, phone_number_cleaned: data.phone_number_cleaned}));
      // Re-read user data to update to new language
      yield call(enhanceUserWithData, userId, accessToken, FormId);
      
    }
    // Stay on edit popup for native app and send close event to native app
    else {
      yield call(successCallback);
    }
  } catch (error) {
    yield put(stopSubmit(FormId));
    yield put(setSubmitFailed(FormId));
  }
}

export function* changePasswordRequest(action: any) {
  const { userId, accessToken, currentPassword,  newPassword, nativeMode, successCallback} = action.payload;
  const FormId = 'change-password-form';
  
  const getState = (state: AppState) => state;
  const { cultures } = yield select(getState);
  
  try {
    yield put(startSubmit(FormId));
    const response: Response = yield call(changePassword, userId, cultures.currentCulture, accessToken, currentPassword, newPassword);

    if (response.ok) {
      if (!nativeMode) {
          yield put(stopSubmit(FormId));
          yield put(setSubmitSucceeded(FormId));
      }
      // When called from native app do not update scrren and send close event to native app
      else {
          yield call(successCallback);
      }
    } else {
        yield put(stopSubmit(FormId, {currentPassword: getErrorText(response)}));
        yield put(setSubmitFailed(FormId));
    }
  } catch (error: any) {
      yield put(stopSubmit(FormId, {currentPassword: getErrorText(error)}));
      yield put(setSubmitFailed((FormId)))
  }
}

export function* enhanceUserWithData(userId: string, accessToken: string, formId: string) {
  try {
    const pageLanguage = sessionStorage.getItem('selectedCulture');
    const getState = (state: AppState) => state;
    const { cultures } = yield select(getState);
    const count = cultures.cultures.length;
    if (count === 0)
      return;

    const response: UserDataResponse = yield getAuth0User(userId, (pageLanguage || cultures.currentCulture), accessToken);
    
    const data = {...response, name: `${response.first_name} ${response.last_name}`, userdata_read: true}

    const newCulture = getUserLanguage(data, cultures.cultures)

    // When userData is loaded, we should change the language, but only if it has been set by the user and only if the user hasn't
    // selected a language in the language dropdown.
    if (newCulture && newCulture !== cultures.currentCulture) {
      yield put(cultureChange(newCulture));
    }

    yield put(actionEnhanceUserWithData(data));
    yield put(setLoadedFalse());
    yield put(getUsedApplications());
	  yield put(getRecommendedApplications());
	  yield put(getRelevantWebsites());
    
    // Set submit success if called from form and new culture has been updated.
    // Set submit success is done here in order to avoid showing the confirmation popup to show in both old and new culture 
    if (newCulture && newCulture === cultures.currentCulture && formId) {
      yield put(setSubmitSucceeded(formId));
    } 
  } catch (err) {
  }
}

export function* watchCheckUser() {
  yield takeEvery(ProfileActionTypes.CHECK_USER, checkUserAction)
}

export function* watchChangePassword() {
  yield takeEvery(ProfileActionTypes.CHANGE_PASSWORD_REQUEST, changePasswordRequest)
}

export function* watchUpdateUser() {
  yield takeEvery(ProfileActionTypes.UPDATE_USER_ACTION, updateUserAction)
}

function* profileSagas() {
  yield all([
    fork(watchCheckUser),
    fork(watchChangePassword),
    fork(watchUpdateUser)
  ])
}

export default profileSagas;

