/* eslint-disable no-debugger */
import { LoadingActionTypes, LoadingType } from "./types";
import { LoadingActionType } from './actions';
import { createReducer } from 'typesafe-actions';

export const initialState: LoadingType = {
	loading: false,
};

const reducer = createReducer<LoadingType, LoadingActionType>(initialState, {
	[LoadingActionTypes.LOADED]: () => {
		return { loading: false };
	},
	[LoadingActionTypes.LOADING]: () => {
		return { loading: true };
	}
});

export { reducer as loadingReducer };
