import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import settings from '../../config/settings';
import Footer from '../Footer/Footer';
import { Application, getApplication} from '../../api/application';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { Spinner } from '@danfoss/webex-ui';
import { getUserLanguage } from '../../utils/userLanguage';

import { useSharedStyles } from '../../sharedStyles/Styles';
import { pushEvent } from '../../Services/AnalyticsService';
import LogoAndBackground from '../Header/LogoAndBackground';
import ConfirmationContent from '../ConfirmationContent';
import DOMPurify from 'dompurify';
import { AppState } from '../../store';

const BlockedAccountConfirmation: React.FC<Props> = ({ profile, cultures }) => {
    const { t } = useTranslation();
    const classes = useSharedStyles();
    
    const [application, setApplication] = useState({} as Application);
    const [loading, setIsLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    
    useEffect(() => {
        setIsLoading(true);

        //Get querystring parameters
        const parsed = queryString.parse(window.location.search);

        const getApp = async () => {

            // take clientId if part of querystring otherwise default to My Profile clientId
            // @ts-ignore
            const cId = DOMPurify.sanitize(parsed.clientId) || settings.auth0.clientId;

            const lan = getUserLanguage(profile, cultures.cultures);
            
            // Get application based on clientId in URL
            let app = await getApplication(cId as string, lan);

            // If the application hasn't got a url, then default back to the default 
            // application "Danfoss Profile"
            if (!app.url) {
                app = await getApplication(settings.auth0.clientId, cultures.currentCulture);
            }
        
            setApplication(app);   
            setMessage(DOMPurify.sanitize((parsed.message || '') as string).toLowerCase());
            setSuccess(DOMPurify.sanitize((parsed.success || '')as string).toLowerCase() === 'true');
            setIsLoading(false);
        }

        getApp();

        // Push an event that a user entered the blockedaccount page
        pushEvent(
            {
                'event': 'virtualPageview',
                'page': `${(window as any).location.hostname}/blockedaccount/${success}/${message}`
            }
		);
        
    }, []);
    
    return loading ? (<Spinner visible={loading}/>) : (
        <React.Fragment>
            <LogoAndBackground classes={classes} />
            <ConfirmationContent
                classes={classes}
                success={success}
                message={message}
                application={application}
                usedOnceHeader={t('blocked_account.this_url_can_be_used_only_once.header')}
                usedOnceBody={parse(t('blocked_account.this_url_can_be_used_only_once.body'))}
                usedOnceButton={parse(t('blocked_account.this_url_can_be_used_only_once.button', { application: (application.translations?.name || application.name || '') }))}
                blockedHeader={t('blocked_account.congratulations.header')}
                blockedBody={parse(t('blocked_account.congratulations.body'))}
                blockedButton={parse(t('blocked_account.congratulations.button', { application: (application.translations?.name || application.name || '') }))}
            />
            <Footer contentClass={classes.footerContent} />
        </React.Fragment>
    );
}

const mapStateToProps = (state: AppState) => ({
    cultures: state.cultures,
    profile: state.profile
});

type Props = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(BlockedAccountConfirmation);
