import createHmac from 'create-hmac';
import settings from '../config/settings';

export const pushEvent = (eventObject: object, email?:string) => {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push(email ? {...eventObject, 'userId': getEmailHash(email)} : eventObject);
}

const getEmailHash = (email: string): string => {

    const hmac = createHmac('sha256', settings.analyticsEmailHashKey.key);
    hmac.update(email);

    return hmac.digest('hex')
}