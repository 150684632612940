import React from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import RedirectWithQueryString from './components/Routes/RedirectWithQueryString'
import RedirectToMobileApp from './components/Routes/RedirectToMobileApp'
import Home from './components/Home/Home';
import Login from './components/Login/Login';
import Logout from './components/Logout/Logout';
import InitiateLogin from './components/Login/InitiateLogin';
import Verification from './components/Verification/Verification';
import YourProfile from './components/YourProfile';
import EditProfile from './components/EditProfile/EditProfile';
import PasswordAndPrivacyWrapper from './components/PasswordAndPrivacy/';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import ErrorPage from './components/Error/ErrorPage';
import PasswordResetConfirmation from './components/PasswordReset/PasswordResetConfirmation'
import BlockedAccountConfirmation from './components/BlockedAccount/BlockedAccountConfirmation'

interface RoutesProps {
	redirectUrl?: string
}
const Routes: React.FC<RoutesProps> = (props: any) => {
	const {redirectUrl} = props;
	return (	
		<ReactRoutes>
			<Route path="/" element={<ProtectedRoute optionalAuthentication={true} ><Home/></ProtectedRoute> } />
			<Route path="/login" element={<Login redirectUrl={redirectUrl}/>} />
			<Route path="/initiate-login" element={<InitiateLogin/>} />
			<Route path="/logout" element={<Logout/>} />
			<Route path="/verification" element={<Verification/>} />
			<Route path="/passwordreset" element={<PasswordResetConfirmation/>} />
			<Route path="/blockedaccount" element={<BlockedAccountConfirmation/>} />
			<Route path="/error" element={<ErrorPage/>} />
			<Route path="/customerror" element={<RedirectWithQueryString to="/error/?client_id=#client_id&connection=#connection&lang=#lang&error_type=custom_error&error_code=custom_error&error=#error&error_description=#error_description&tracking=#tracking" />} />
			<Route path="/samlerror/:client_id" element={<RedirectWithQueryString to="/error/?client_id=:client_id&error_type=saml_error&error_code=saml_error&error_description=#error" />} />
			<Route path="/ruleerror" element={<RedirectWithQueryString to="/error/?client_id=#client_id&error_type=rule_error&error_code=#error_code&error_description=#error_description" />} />
			<Route path="/launchapp" element={<RedirectToMobileApp/>} />
			<Route path="/your-profile" element={<ProtectedRoute><YourProfile/></ProtectedRoute>} />
			<Route path="/edit-profile/:refreshKey" element={<ProtectedRoute><EditProfile/></ProtectedRoute>} />
			<Route path="/edit-profile" element={<ProtectedRoute><EditProfile/></ProtectedRoute>} />
			<Route path="/change-password" element={<ProtectedRoute><PasswordAndPrivacyWrapper/></ProtectedRoute>} />
			<Route path="/delete-account" element={<ProtectedRoute><PasswordAndPrivacyWrapper/></ProtectedRoute>} />
			<Route path="/multifactor-settings" element={<ProtectedRoute><PasswordAndPrivacyWrapper/></ProtectedRoute>} />
			<Route path="/content-only/edit-profile" element={<ProtectedRoute><EditProfile/></ProtectedRoute>} />
			<Route path="/content-only/change-password" element={<ProtectedRoute><PasswordAndPrivacyWrapper/></ProtectedRoute>} />
			<Route path="/content-only/delete-account" element={<ProtectedRoute><PasswordAndPrivacyWrapper/></ProtectedRoute>} />
			<Route path="/content-only/multifactor-settings" element={<ProtectedRoute><YourProfile/></ProtectedRoute>} />
		</ReactRoutes>
)};

export default Routes;
