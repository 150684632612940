import fetchApiWrapper from './fetchApiWrapper';
import settings from '../config/settings';
import { getEncryptedData } from '../utils'

export interface MultifactorSettings {
  mfa_settings: MultifactorSetting[];
}

export interface MultifactorSetting {
  id: string,
  type: string,
  confirmed: boolean,
  name: string,
  created_at: string,
  enrolled_at: string,
  last_auth_at?: string,
  link_id?: string
}

export const getMultifactorInfo = (userId: string | undefined, accessToken: string): Promise<MultifactorSettings> => {

  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
  requestHeaders.append('Authorization', `BEARER ${accessToken}`)

  const multisettingsPromise = fetchApiWrapper(`${settings.backend.endpoint}/users/${userId || ''}/mfa-settings`, {
    headers: requestHeaders,
    method: 'GET',
  }).catch(() => {
    // Added to prevent functionalty not to work if the scope "user-mfa.read" is missing in a native app
    return Promise.resolve({ mfa_settings: [] }) as Promise<MultifactorSettings>;
  });

  return multisettingsPromise as Promise<MultifactorSettings>
}

export const resetMultifactorInfo = (userId: string, accessToken: string): Promise<MultifactorSettings> => {

  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
  requestHeaders.append('Authorization', `BEARER ${accessToken}`)

  return fetchApiWrapper(`${settings.backend.endpoint}/users/${userId}/mfa-settings`, {
    headers: requestHeaders,
    method: 'DELETE',
  });
}

export const sendNewRecoveryCode = (email: string, pageLanguage: string, password: string, accessToken: string) => {

  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
  requestHeaders.append('Authorization', `BEARER ${accessToken}`)

  if (pageLanguage) {
    requestHeaders.append('Accept-Language', pageLanguage)
  }

  return getEncryptedData(email, accessToken).then((encryptedData: string) => {
    return fetch(`${settings.backend.endpoint}/users/${encryptedData}/mfa-recovery-code-emails`, {
      body: `{"password":"${password}"}`,
      headers: requestHeaders,
      method: 'POST',
    })
  });  
}