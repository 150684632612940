import { takeLatest, all, put, fork, select } from 'redux-saga/effects';
import { setLegalInfo } from './actions';
import { LegalInfoActionTypes, LegalInfo } from './types';
import { AppState } from "../";
import { DIPLegalInfo, getDipLegalInfo } from '../../api/legal';

export function* loadLegalInfo() {
    const getState = (state: AppState) => state;
    const { cultures } = yield select(getState);
        
    try {
        const legalInfo: DIPLegalInfo = yield getDipLegalInfo(cultures.currentCulture)
        yield put(setLegalInfo({currentCulture: cultures.currentCulture,...legalInfo}));
    } catch (ex) {
        yield put(setLegalInfo({} as LegalInfo));
    }
}    

export function* watchGetLegalInfo() {
    yield takeLatest(LegalInfoActionTypes.GET_LEGALINFO, loadLegalInfo);
}

export function* legalInfoSagas() {
    yield all([
        fork(watchGetLegalInfo)
    ]);
}

export default legalInfoSagas;