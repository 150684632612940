import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  popupContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    [theme.breakpoints.down('sm')]: {
      top: '-50px',
    }
  },
  popup: {
    top: '233px',
    maxWidth: '650px',
    display: 'table',
    marginTop: 'auto !important',
    padding: '30px',
    [theme.breakpoints.up('sm')]: {
      margin: '50px'
    },
    [theme.breakpoints.only('xs')]: {
      padding: '20px',
      margin: '15px'
    }
  },
  overlay: {
    [theme.breakpoints.only('xs')]: {
      marginTop: '10px',
      height: '100%'
    }
  },
  close: {
    cursor: 'pointer',
    width: '16px',
    [theme.breakpoints.only('xs')]: {
      margin: '10px -16px',
    },
    [theme.breakpoints.up('sm')]: {
      right: '16px'
    },
    position: 'absolute'
  },
  maintenanceHeader: {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '6px 0 16px',
    [theme.breakpoints.down('sm')]: {
      margin: '-8px 0 16px'
    }
  },
  imageContainer: {
    display: 'table-cell',
    width: '130px',
    height: '112px',
    paddingRight: '30px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '15px',
      height: '0px'
    }
  },
  textContainer: {
    display: 'table-cell',
    verticalAlign: 'top'
  },
  image: {
    width: "126px",
    height:"115px",
    [theme.breakpoints.down('sm')]: {
      width: "50px",
      height:"45px"
    },
  }
}));