import { Application } from '../../api/application';
import settings from '../../config/settings';

export const getMobileAppInfo = (os: string, app: Application) => {
    return app.mobile_app_info && app.mobile_app_info.find(i => i.os.toLowerCase() === os.toLowerCase());
}

export const addTrailingSlash = (url: string) => {
    const newUrl = url.includes('?') ? url : url + '/';
    // Add trailing slash to url if url does not contain query parms
    return url.endsWith('/') ? url : newUrl;
};

export const mobileAppExistsForOs = (os: string, app: Application) => {
    const mobileAppInfo = getMobileAppInfo(os, app);
    return !!mobileAppInfo;
}

export const getMobileStoreLink = (os: string, app: Application) => {
    const mobileAppInfo = getMobileAppInfo(os, app);
    if (mobileAppInfo) {
        if (mobileAppInfo.id) {
            if (os.toLowerCase() === "android") {
                return `${addTrailingSlash(settings.mobilestores.googlePlayAppUrl)}${mobileAppInfo.id}`;
            }
            if (os.toLowerCase() === "ios") {
                return `${addTrailingSlash(settings.mobilestores.appStoreUrl)}${mobileAppInfo.id}`;
            }
        }
    }
}