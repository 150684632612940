import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Main from '../Main/Main';
import EditProfileForm from './FormEditProfile';
import { AppState } from "../../store";
import { UserData } from '../../store/profile/types';
import { updateUserAction} from '../../store/profile/actions';
import { getFieldsMetadata as getFieldsMetadataAction } from '../../store/fieldsMetadata/actions';
import { useAuthentication } from '../../Providers/AuthenticationProvider';
import { subActivityHostService } from '../../Services/SubActivityHostService';
import { EditProfileProps, EditProfileState } from './Types';
import { pushEvent } from '../../Services/AnalyticsService';
import ClickOutsideWrapper from '../ClickOutsideWrapper';
import { useStyles } from './Styles';

const EditProfile: React.FC<EditProfileProps & EditProfileState> = (props: any) => {
  const { updateUser, getFieldsMetadata, profile, cultures } = props;
  const [accessToken, setAccessToken] = useState("");
  const [loading, setIsLoading] = useState(true);
  const { getTokenSilently, webkitActive  } = useAuthentication();
  const classes = useStyles();

  const onSubmit = async (data: any) => {
      profile && accessToken && await updateUser({...data, accessToken: accessToken, userId: profile.sub, nativeMode: webkitActive, successCallback: emitEventToNativeHost});
  }

  const emitEventToNativeHost = () => {
    subActivityHostService.close();
  }

  useEffect(() => {

    // Push an event that a user entered the editprofile page
    pushEvent(
      {
          'event': 'virtualPageview',
          'page': `${(window as any).location.hostname}/edit-profile`
      }
    );

    const getData = async () => {
      const token = await getTokenSilently();
      setAccessToken(token);
      await getFieldsMetadata();
      setIsLoading(false);
    }

    getData();

  }, []);

  return loading ? null : (
    <Main>
      <ClickOutsideWrapper classNamePopup={classes.leaveConfirmPopup} classNamePopupContainer={classes.leaveConfirmContainer}>
        <EditProfileForm onSubmit={onSubmit} accessToken={accessToken} nativeMode={webkitActive} currentCulture={cultures.currentCulture} />
      </ClickOutsideWrapper>
    </Main>
  );
}

const mapStateToProps = (state: AppState)  => ({
  profile: state.profile,
  cultures: state.cultures
});

const mapDispatchToProps = (dispatch: any) => () => ({
    updateUser: (data: UserData) => dispatch(updateUserAction(data)),
    getFieldsMetadata: () => dispatch(getFieldsMetadataAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
