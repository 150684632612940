import React from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './Styles';
import { DashBoardItemProps, DashBoardItem as DashBoardItemType, DashBoardItems as DashBoardItemsType } from './Types';
import { urlJoin } from 'url-join-ts';
import settings from '../../config/settings';
import Banner from '../Banner';
import { useTranslation } from 'react-i18next';
import { isMobileDevice } from '../../utils';

const hasVisibleDashboardItem = (dashItems: DashBoardItemsType) => {
	let hasVisibleItem = false;

	dashItems.items.filter((dashboardItem: DashBoardItemType) => dashboardItem.url).forEach((dashboardItem: DashBoardItemType) => {
		if (!(dashboardItem.hidden || false)) {
			hasVisibleItem = true;
		}
	});

	return hasVisibleItem;
}

const getItemsSorted = (dashBoardItems: DashBoardItemsType, onlyVisible: boolean) => {
	let dashboardItemsSorted = [];
	
	dashboardItemsSorted = dashBoardItems.items.filter(item => item.url && item.dip_app_type !== (isMobileDevice() ? 'client_app' : 'mobile_app')).sort((item1, item2) => (item1.display_order || 0) - (item2.display_order || 0)); 

	dashBoardItems.items.filter(item => item.url).forEach((item: DashBoardItemType) => {
		if (item.dip_app_type === (isMobileDevice() ? 'client_app' : 'mobile_app')) {
			dashboardItemsSorted.push(item);
		}
	});
	
	if (onlyVisible) {
		dashboardItemsSorted = dashboardItemsSorted.reduce((items: DashBoardItemType[], item: DashBoardItemType) => {
			if (!item.hidden) {
				items.push(item);
			}

			return items;
		}, [])
	}

	return dashboardItemsSorted;
} 

const getItemsToMap = (editMode: boolean, dashBoardItems: DashBoardItemsType) => {
	return editMode ? getItemsSorted(dashBoardItems, false) : getItemsSorted(dashBoardItems, true);
}

const showContent = (editMode: boolean, dashBoardItems: DashBoardItemsType) => {
	return (dashBoardItems.items.length > 0 && (hasVisibleDashboardItem(dashBoardItems) || editMode));
}

const getBanners = (ItemsToMap: DashBoardItemType[], functions: any, classes: any, itemType: string, editMode: boolean, userEmail: string) => {
	return ItemsToMap.map((dashboardItem: DashBoardItemType, index) => {
		const itemIcon = functions.getDashBoardItemIcon({ ...dashboardItem, dip_app_type: dashboardItem.dip_app_type });
		const showAsActive = functions.shouldItemBeShownAsActive(dashboardItem);

		let bannerTextClass = classes.bannerText

		if ((dashboardItem.name || '').length > 50) {
			bannerTextClass = classes.bannerTextSmallFont;
		}

		return (
			<Banner
				key={dashboardItem.name}
				bannerClick={() => functions.handleBannerClick(dashboardItem, showAsActive, functions.toggleInactiveAppPopup, functions.toggleClientAppPopup, functions.toggleMobileAppPopup)}
				bannerClass={showAsActive ? classes.banner : classes.bannerInactive}
				bannerIconBorderClass={classes.bannerIconBorder}
				bannerTextClass={bannerTextClass}
				bannerIcon={urlJoin(settings.staticFiles.endpoint, itemIcon)}
				bannerIconHover={urlJoin(settings.staticFiles.endpoint, itemIcon.replace('.svg', showAsActive ? '_hover.svg' : '_Inactive_hover.svg'))}
				bannerText={dashboardItem.translations?.name || dashboardItem.name || ''}
				bannerInformationIconUrl={(!!dashboardItem.translations?.description || !!dashboardItem.description)  ? urlJoin(settings.staticFiles.endpoint, '/images/system/Information_gray.svg') : ''}
				bannerInformationClick={() => functions.handleInformationIconClick(dashboardItem)}
				handleItemDisplay={(hidden: boolean) => functions.handleDashboardItemDisplay({...dashboardItem, hidden: !hidden}, itemType)}
				moveDashboardItem={(display_order: number, item: any) => functions.handleMoveDashboardItem({...item, display_order}, itemType)}
				index={index}
				itemType={itemType}
				client_id={dashboardItem.client_id || dashboardItem.id}
				showAsActive={showAsActive}
				editMode={editMode}
				hidden={dashboardItem.hidden || false}
				userEmail={userEmail}
			/>
		);
	})
}

const DashBoardItems: React.FC<DashBoardItemProps> = ({ areaSubject, dashBoardItems, getDashBoardItemIcon, shouldItemBeShownAsActive, functions, handleBannerClick, itemType, editMode, userEmail }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const ItemsToMap = getItemsToMap(editMode, dashBoardItems);

	return showContent(editMode, dashBoardItems) ? (
		<Grid item xs={12}>
			<Grid item xs={12} className={classes.applicationTypeHeader}>
				{t(areaSubject)}
			</Grid>
			<Grid
				container
				className={`${classes.applicationIconContainer}`}
				spacing={3}
			>
				{getBanners(ItemsToMap, {...functions, getDashBoardItemIcon, shouldItemBeShownAsActive, handleBannerClick}, classes, itemType, editMode, userEmail)}
			</Grid>
		</Grid>
	) : null;
};

export default DashBoardItems;
