import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ModalPopup from '../Modal/ModalPopup';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import { FormLeaveConfirmProps } from './types';
import { useStyles } from './styles';
import { isIE } from 'mobile-device-detect';
import { useTranslation } from 'react-i18next';
import settings from '../../config/settings';
import { urlJoin } from 'url-join-ts';
import parse from 'html-react-parser'; 

const FormLeaveConfirm: React.FC<FormLeaveConfirmProps> = ({ setShowLeave, onSave, onLeave, validationErrors, classNamePopupContainer, classNamePopup }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const containerClass = classNamePopupContainer ? classNamePopupContainer : classes.popupContainer;
    const popupClass = classNamePopup ? classNamePopup : classes.popup;

    return (
        <Grid container className={containerClass}>
            <ModalPopup paperClassName={popupClass}>
                <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                    <div onClick={() => setShowLeave()}>
                        <img src={urlJoin(settings.staticFiles.endpoint,'/images/system/Close.svg')} className={classes.close} alt="" />
                    </div>
                </Grid>
                <Grid container className={classes.root}>
                    <Typography className={classes.header}>
                        {t('unsaved_changes.confirm.header')}
                    </Typography>
                    <Typography>
                        {t('unsaved_changes.confirm.description')}
                    </Typography>
                    <Grid container alignItems="flex-start" justifyContent="flex-start" direction="row" className={classes.buttonContainer}>
                        <SpinnerButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => onSave()}
                            pathToImagesFolder={'/images'}
                            spinnerVisible={false}
                            disabled={!!validationErrors}
                        >
                            {t('unsaved_changes.confirm.submit')}
                        </SpinnerButton>
                        {!isIE && (
                            <Button
                                onClick={(e) => onLeave(e)}
                                type="button"
                                variant="outlined"
                                color="default"
                                className={classes.button}
                            >
                                {t('unsaved_changes.confirm.cancel')}
                            </Button>
                        )}
                        {validationErrors && (
							<Grid container direction="row" className={classes.validationErrorContainerLeaveConfirm}>
								<span className={classes.validationErrorIcon}>
									<img src={urlJoin(settings.staticFiles.endpoint,'/images/system/ValidationWarning.svg')} className={classes.icon} alt="" />
								</span>
								<span className={classes.validationErrorText}>
									{parse(validationErrors)}
								</span>
							</Grid>
						)}
                    </Grid>
                </Grid>
            </ModalPopup>
        </Grid>
    );
};

export default FormLeaveConfirm;
