import React, { Dispatch, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useSpinner } from "../../Providers/SpinnerProvider";

import { userRequest, UserActionType } from '../../store/profile/actions';
import { Profile } from '../../store/profile/types';
import { AppState } from '../../store';

import { useAuthentication } from '../../Providers/AuthenticationProvider';
import { Spinner } from '@danfoss/webex-ui';

const mapStateToProps = (state: AppState) => ({
  profile: state.profile,
  cultures: state.cultures,
  authentication: state.authentication,
});

const mapDispatchToProps = (dispatch: Dispatch<UserActionType>) => ({
  userRequest: (optionalAuthentication: boolean) => dispatch(userRequest(optionalAuthentication)),
});

type ProtectedRouteProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    optionalAuthentication?: boolean,
    children: React.ReactElement
  }

const setLoaderVisibility = (initialized: boolean, isAuthenticated: boolean, userdata_read: boolean | undefined, optionalAuthentication: boolean | undefined, setSpinnerVisibility: Function) => {
  if (initialized && isAuthenticated && !(userdata_read)  ) {
    setSpinnerVisibility(true);
  }
  else if (initialized && (isAuthenticated || optionalAuthentication)) {
    setSpinnerVisibility(false);
  }
  else {
    setSpinnerVisibility(true);
  }
}

const getContent = (initialized: boolean, isAuthenticated: boolean, profile: Profile | undefined, userdata_read: boolean | undefined, optionalAuthentication: boolean | undefined, children: React.ReactElement) => {
  
  if (initialized && isAuthenticated && !(profile && userdata_read)  ) {
    return null;
  }

  if (initialized && (isAuthenticated || optionalAuthentication)) {
    return children;
  }

  return <Spinner visible={true} />;
}

const hasAuthenticationError = (authenticationError: any, optionalAuthentication: boolean | undefined, loginWithRedirect: Function) => {
    // If silent login results in interaction required (e.g. terms approval needed) or login required on page where login is required ask the user to login 
    if ((authenticationError.error === 'login_required' && !optionalAuthentication) || authenticationError.error === 'interaction_required') {
      return loginWithRedirect({ is_content_only: window.location.pathname.includes("content-only/")});
    }
    // If login results in authorized (e.g. user blocked) redirect the user to the home page 
    else if (authenticationError.error === 'unauthorized') {
      window.location.href = "/";
    }
  
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props: any) => {
  const {
    profile, cultures, optionalAuthentication, authentication, children, ...rest
  } = props;
  const { userdata_read } = profile || {userdata_read: false};

  const { isAuthenticated, loginWithRedirect, automaticSso, error: authenticationError } = useAuthentication();
  // @ts-ignore
  const { setSpinnerVisibility } = useSpinner();
  const [initialized, setInitialized] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const automaticLogin = async () => {
      if (!isAuthenticated && !authenticationError) {
          // Try if sso is possible
          try {
            return automaticSso({ui_locales: (cultures?.currentCulture) || 'en', is_content_only: window.location.pathname.includes("content-only/"), appState: {redirectUri: `${location.pathname}${location.search}`} });
          } catch (error: any) {
            if ((error.error === 'login_required' && !optionalAuthentication) || error.error === 'interaction_required') {
              return loginWithRedirect({ is_content_only: window.location.pathname.includes("content-only/")});
            }
          } 
      }

      if (authenticationError) {
        hasAuthenticationError(authenticationError, optionalAuthentication, loginWithRedirect);
      }

      setInitialized(true); 
    };
    automaticLogin();
  },[]);

  // Control spinner visibility
  useEffect(() => {
    // If initialized and authenticated with for the profile to be read
    setLoaderVisibility(initialized, isAuthenticated, userdata_read, optionalAuthentication, setSpinnerVisibility);
    
  },[initialized, isAuthenticated, optionalAuthentication, userdata_read])

  return getContent(initialized, isAuthenticated, profile, userdata_read, optionalAuthentication, children)
  
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
