import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import parse from 'html-react-parser';
import { useStyles } from './Styles';
import { BenefitAreaProps } from './Types';

const BenefitItem: React.FC<BenefitAreaProps> = ({icon, header, description}) => {
	const classes = useStyles();

    return (
        <Grid item xs={12} sm={4} className={classes.benefitItemContainer}>
            <Grid item xs={12} className={classes.benefitItemIconContainer}>
                <img src={icon} className={classes.benefitItemIcon} alt={header}/>
            </Grid>
            <Grid item xs={12} className={classes.benefitDescriptionContainer}>
                <Typography className={classes.benefitItemHeader}>
                    {parse(header)}
                </Typography>
				<Typography className={classes.benefitItemDescription}>
                    {description}
                </Typography>
            </Grid>
        </Grid>
    );
}


  
export default BenefitItem;