import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import '@danfoss/webex-ui/dist/main.js';
import '@danfoss/webex-ui/dist/main.css';

import * as React from 'react';
import { createRoot } from 'react-dom/client';
import configureStore from './configureStore';
import RouteWrapper from './components/RouteWrapper';

const store = configureStore();

const root = createRoot(document.querySelector(
	'.dip-profile-container'
) as HTMLElement);
root.render(<RouteWrapper store={store} />);


