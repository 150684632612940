import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { AppState } from "../../store";
import { Cultures } from '../../store/cultures/types';
import { Grid, Container } from '@material-ui/core';
import Footer from '../Footer/Footer';
import settings from '../../config/settings';
import queryString from 'query-string';
import { Application, getApplication, getApplicationByName} from '../../api/application';
import { Spinner } from '@danfoss/webex-ui';
import { osName } from 'mobile-device-detect';
import { useStyles } from './Styles';
import { useSharedStyles } from '../../sharedStyles/Styles';
import LogoAndBackground from '../Header/LogoAndBackground';
import { urlJoin } from 'url-join-ts';
import DownloadIcon from './DownloadIcon';
import { mobileAppExistsForOs } from './Helpers';
import { isMobileDevice } from '../../utils';
import DOMPurify from 'dompurify';

const getMobileAppInfo = (os: string, app: Application) => {
    return app.mobile_app_info && app.mobile_app_info.find(i => i.os.toLowerCase() === os.toLowerCase());
}

const getMobileAppDeepLink = (os: string, app: Application) => {
    const mobileAppInfo = getMobileAppInfo(os, app);
    if (mobileAppInfo) {
        return `${mobileAppInfo.deep_link}`;
    }

    return "";
}

const addTrailingSlash = (url: string) => {
    // Add trailing slash to url if url does not contain query parms
    const modifiedUrl = ( url.includes('?') ? url : url + '/' )
    return url.endsWith('/') ? url : modifiedUrl;
};

const getBrowserStoreLink = (os: string, app: Application) => {
    const mobileAppInfo = getMobileAppInfo(os, app);
    if (mobileAppInfo) {
        if (mobileAppInfo.id) {
            if (os.toLowerCase() === "android") {
                return `${addTrailingSlash(settings.mobilestores.googlePlayWebUrl)}${mobileAppInfo.id}`;
            }
            if (os.toLowerCase() === "ios") {
                return `${addTrailingSlash(settings.mobilestores.appStoreUrl)}${mobileAppInfo.id}`;
            }
        }
    }
}

interface RedirectToNativeAppProps {
    appInfo?: Application,
    inline?: boolean,
    close?: Function,
    showImagePopup?: Function,
    cultures: Cultures;
}

const generateImageLink = (type: string, application: Application, image: string, classMobileStoreLink: any, classIcon: any) => {
    return (!isMobileDevice() && mobileAppExistsForOs(type, application)) 
            ?
            (<a href={getBrowserStoreLink(type, application)} target="_blank" rel="noopener noreferrer" className={classMobileStoreLink}>
                <img src={image} className={classIcon} alt=""/>
            </a>)
            : null
}

const getApplicationData = async (appInfo: Application | undefined, pageLanguage: string) => {

    let app = undefined;

    //Get querystring parameters
    const parsed = queryString.parse(window.location.search);

    if (appInfo) {
        app = appInfo;
    }
    else {
        // Use clientId if part of querystring, else use client_name if part of querystring
        // @ts-ignore
        const cId = DOMPurify.sanitize(parsed.client_id) || parsed.clientId;
        // @ts-ignore
        const cName = DOMPurify.sanitize(parsed.client_name) || parsed.clientName;
        if (cId) {
            app = await getApplication(cId as string, pageLanguage); 
        }
        else if (cName) {
            app = await getApplicationByName(cName as string, pageLanguage);                    
        }
    }

    return app;
}

const openDeepLink = (deep_link: string) => {
    if (deep_link) {
        if (deep_link.startsWith("https")) {
            // @ts-ignore
            window.open(deep_link);
        } else {
            // @ts-ignore
            window.location = deep_link;
        }
    }
}

const closePopUp = (close: Function | undefined) => {
    if (close) {
        close();
    }
    else {
        window.close();
    }
}

const addEventListener = (close: Function | undefined) => {
    window.addEventListener('blur', function(e:any){

        e = {...e, origin: (e?.target?.origin || '')}

        if (e.origin !== settings.application.url) {
            return 
        }

        closePopUp(close);
        
    });
}

const RedirectToNativeApp: React.FC<RedirectToNativeAppProps> = ({ appInfo, close, inline, cultures, showImagePopup }) => {

    const classes = useStyles();
    const sharedClasses = useSharedStyles();
    const [isReady, setIsReady] = useState(false);
    const [application, setApplication] = useState({} as Application);
    const [loading, setIsLoading] = useState(true);
    const [appStoreImage, setAppStoreImage] = useState('');
    const [googlePlayImage, setGoogloPlayImage] = useState('');

    useEffect(() => {
        setIsLoading(true);

        const getApp = async () => {

            let app = await getApplicationData(appInfo, cultures.currentCulture);
            
            if (app) {
                setApplication(app);
                setIsLoading(false);
            }
            
            if (isMobileDevice() && app && app.mobile_app_info) {
                addEventListener(close);

                const deep_link = getMobileAppDeepLink(osName, app);
                openDeepLink(deep_link)
            }
        }

        getApp();

        setAppStoreImage(urlJoin(settings.staticFiles.endpoint, `/images/mobilestore/appstore_${cultures.currentCulture}.png`));
        setGoogloPlayImage(urlJoin(settings.staticFiles.endpoint, `/images/mobilestore/playstore_${cultures.currentCulture}.png`));
        setIsLoading(false);
        
        setTimeout(() => {
            setIsReady(true);
            showImagePopup && showImagePopup();
        },1000);
        
    }, []);

    if (inline) {
        return isReady ? (
            <Grid item xs={12}>
                <DownloadIcon
                    classes={ classes }
                    application={ application }
                    appStoreImage={appStoreImage}
                    googlePlayImage={googlePlayImage}
                />
            </Grid>
        ) : null;
    }
    else {
        return loading ? (<Spinner visible={loading}/>) : (
            <React.Fragment>
                <LogoAndBackground classes={sharedClasses} />
                <Container className={sharedClasses.containerRoot}>
                    <Container maxWidth="md" className={`${sharedClasses.outerContainer} ${sharedClasses.relativeContainer}`}>
                        <Grid container className={sharedClasses.contentContainerRoot}>
                            <Grid item xs={12}>
                                <Container>
                                <Grid container>
                                    <Grid item xs={12} className={sharedClasses.formContainer}>
                                        <DownloadIcon
                                            classes={ classes }
                                            application={ application }
                                            appStoreImage={appStoreImage}
                                            googlePlayImage={googlePlayImage}
                                        />
                                        {generateImageLink("android", application, googlePlayImage, classes.mobileStoreLink ,classes.googlePlayIcon)}
                                        {generateImageLink("ios", application, appStoreImage, classes.mobileStoreLink ,classes.appStoreIcon)}
                                    </Grid>
                                </Grid>
                                </Container>
                            </Grid>
                        </Grid>
                    </Container>
                </Container>        
                <Footer contentClass={sharedClasses.footerContent} />
            </React.Fragment>

        );
    }
}

const mapStateToProps = (state: AppState) => ({
    cultures: state.cultures
});

export default connect(mapStateToProps)(RedirectToNativeApp);