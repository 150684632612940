import { Defer } from "./Defer";

declare global {
    interface Window {
        NativeToJsMessageHandler: {
            resolve: (scope: string, id: number, value: any) => void,
            reject: (scope: string, id: number, reeason: any) => void,
            cancel: (scope: string, id: number) => void,
            dispatch: (event: string, detail: any) => void
        }
    }
}

export interface JsMessageQueue {
    [scope: string]: JsMessageScopedQueue;
}

export interface JsMessageScopedQueue {
    [queueId: number]: Defer<any>;
    $scope: string
    $seed: number
}

export class HostDeferer {
    private static queue: JsMessageQueue;
    static isInitialized: boolean = false;
    static initialize() {
        if (this.isInitialized) return;

        this.isInitialized = true;
        this.queue = {};
        const handler = window.NativeToJsMessageHandler = {
            resolve: (scope, id, response) => this.resolve(scope, id, response),
            reject: (scope, id, error) => this.reject(scope, id, error),
            cancel: (scope, id) => this.cancel(scope, id),
            dispatch: (event, detail) => this.dispatch(event, detail),
        };

        // Callback function for incomming messages
        const messageHandler = (event: MessageEvent) => {
            // Return if no event or no event.data or if event.data is not a (JSON) string
            if (!event || !event.data || typeof event.data !== 'string') return;

            let eventData = {};
            try {
                // Convert data (string containing JSON) into a JSON object
                eventData = JSON.parse(event.data);
            }
            catch (e) {
                console.error("Unable to deserialize incoming message", e);
            }

            // Return if the event.data could not be parced to a JSON object or if event.data is empty
            if (!eventData) return;

            // get the action and args values of the parsed eventData
            const { action, args } = eventData as any;
            if (!action || !args) console.error("Invalid message.");

            // Action will be a string of "resolve", "reject", "cancel" or "despatch" so what you are doing basically 
            // if setting delegate = the action of handler with the same name as the value of action. 
            //
            // So if action = "resolve" then delegate is set to the handler.resolve action 
            const delegate = (handler as any)[action]
            if (!delegate || !delegate.apply) console.error(`Action '${action}' was not found in the handler.`);

            // Apply the arguments to the prototype function 
            // args is an array of values like ["app", 2, null]
            // so in the case of resolve "scope" = "app", id = "2" and "response" is null
            delegate.apply(window, args)

            // The outcome of this messageHandler call is triggering the static methods resolve, reject and cancel, which all will call 
            // the dissolve function
        }

        // Add a eventlistener that will listen for incomming messages and trigger the callback function: messageHandler
        window.addEventListener("message", messageHandler, false);
    }
    static defer<T>(scope: string, handler: string, method: string, data?: any): Defer<T> {

        // Make sure to run initialize to setup the eventlistener and queue object
        this.initialize();

        // If no current queue added with the scope like "app" add one and set the seed to 1.
        if (!this.queue[scope]) {
            this.queue[scope] = {
                $scope: scope,
                $seed: 1
            };
        }

        // Get the current seed of the queue with the given scope, then afterwards increase that number with 1
        const $id = this.queue[scope].$seed++;

        // To the queue with the given scope add an instance of the generic Defer class with the current values.
        this.queue[scope][$id] = new Defer<T>(scope, handler, method, $id, data);

        // Return the new Defer class from scope
        return this.queue[scope][$id]
    }
    static resolve(scope: string, id: number, response: any) {
        this.dissolve(scope, id, d => d.resolve(response))
    }
    static reject(scope: string, id: number, error: any) {
        this.dissolve(scope, id, d => d.reject(error))
    }
    static cancel(scope: string, id: number) {
        this.dissolve(scope, id, d => d.cancel())
    }
    private static dissolve(scope: string, id: number, action: (deferred: Defer<any>) => void) {
        // If data is empty, return 
        if (!id || !this.queue[scope] || !this.queue[scope][id]) {
            return;
        }

        try {
            // The value of action is in resolve situations 
            //   ƒ (d) {
            //     return d.resolve(response);
            //   }
            // Meaning, that if you call action with the input value of the queue item
            // (which is an instance of the class Defer), it will trigger
            // the Defer class function. In this case the resolve function. 
            action(this.queue[scope][id]);
        }
        catch (e) { console.error(e); }

        // Clear the queue item after it was handled
        delete this.queue[scope][id];
    }

    static dispatch(event: string, detail: any) {
        const prefix = "native.";
        
        // If the string event does not start with "native." then return
        if (event.indexOf(prefix) !== 0) return;
        
        // If the string event starts with "native." remove that and fire the custom event
        window.dispatchEvent(new CustomEvent(event.substr(prefix.length), { detail }));
    }
}
