import React, { useState } from 'react';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import TextField from '@material-ui/core/TextField';
import { useStyles } from './Styles';
import { useSharedStyles } from '../../sharedStyles/Styles';
import { PasswordPopupProps, OnPasswordSubmitResponse, PasswordFieldParameters } from './Types';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import settings from '../../config/settings';
import Grid from '@material-ui/core/Grid';
import { Button, InputAdornment } from '@material-ui/core';
import { urlJoin } from 'url-join-ts';


const getPasswordField = (passwordFieldParameters: PasswordFieldParameters) => {
    
    const { t } = passwordFieldParameters.useTranslation();
    const classes = passwordFieldParameters.useStyles();
    const sharedClasses = passwordFieldParameters.useSharedStyles();

    return (
        <TextField
            className={`${classes.element} ${passwordFieldParameters.verifyPasswordError || passwordFieldParameters.passwordEmpty ? classes.ErrorShadow : ''}`}
            type={(passwordFieldParameters.passwordEmpty || passwordFieldParameters.showCurrentPassword) ? "text" : "password"}
            placeholder={t('privacy_and_security.delete_account_overlay.placeholder')}
            onChange={(e) => { passwordFieldParameters.setUserPassword(e.target.value); passwordFieldParameters.setPasswordEmpty(false); }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" >
                    <i
                        className={passwordFieldParameters.showCurrentPasswordClass}
                        onClick={() => {passwordFieldParameters.setShowCurrentPasswordClass(!passwordFieldParameters.showCurrentPassword ? sharedClasses.eyeActive : sharedClasses.eyeInactive); passwordFieldParameters.setShowCurrentPassword(!passwordFieldParameters.showCurrentPassword)}}
                        onMouseOver= {() => passwordFieldParameters.setShowCurrentPasswordClass(sharedClasses.eyeHover)}
                        onMouseOut= {() => passwordFieldParameters.setShowCurrentPasswordClass(passwordFieldParameters.showCurrentPassword ? sharedClasses.eyeActive : sharedClasses.eyeInactive)}
                    />
                    </InputAdornment>
                ),
                classes: {
                    input: classes.blackText
                }
                }}
        />
    )
}

const PasswordPopup: React.FC<PasswordPopupProps> = ({ headerText, bodyText, buttonText, onSubmit, setShowPasswordConfirm }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const sharedClasses = useSharedStyles();

    const [passwordEmpty, setPasswordEmpty] = useState(false)
    const [verifyPasswordError, setVerifyPasswordError] = useState(false)
    const [isValidating, setIsValidating] = useState(false)
    const [userPassword, setUserPassword] = useState('')
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showCurrentPasswordClass, setShowCurrentPasswordClass] = useState(sharedClasses.eyeInactive);

    const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
       
        if (userPassword === '') {
            setPasswordEmpty(true);
            setVerifyPasswordError(false);
        } else {
        
            setPasswordEmpty(false)
            setIsValidating(true);

            const submitPassword = async () => {
                const passwordSubmitResponse: OnPasswordSubmitResponse = await onSubmit(userPassword);
                
                if (passwordSubmitResponse && !passwordSubmitResponse.ok) {
                    setVerifyPasswordError(true);
                    setIsValidating(false);
                }
            }

            submitPassword();
        }
    }

    return (
        <>
            <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                <div onClick={() => setShowPasswordConfirm(false)}>
                    <img src={urlJoin(settings.staticFiles.endpoint,'/images/system/Close.svg')} className={classes.close} alt="" />
                </div>
            </Grid>
            <Grid item xs={12} className={classes.passwordPopupHeader} >
                {headerText}
            </Grid>
            <Grid item xs={12} className={classes.popupContent}>
                {bodyText.map((content) => {
                    return (
                        <Grid key={content} item xs={12} className={classes.popupContent}>
                            {parse(content)}
                        </Grid>
                    )
                })}
            </Grid>
            <form onSubmit={handleOnSubmit} name="delete-profile-form">
                <Grid item xs={12} className={!verifyPasswordError && !passwordEmpty ? classes.textContainer : ''}>
                    {verifyPasswordError && (
                        <div className={classes.labelError}>
                            {parse(t('privacy_and_security.error_messages.password_invalid'))}
                        </div>
                    )}
                    {passwordEmpty && (
                        <div className={classes.labelError}>
                            {parse(t('privacy_and_security.error_messages.password_empty'))}
                        </div>
                    )}
                    {
                        getPasswordField({useTranslation, useStyles, useSharedStyles, verifyPasswordError, passwordEmpty, showCurrentPassword, showCurrentPasswordClass, setUserPassword, setPasswordEmpty, setShowCurrentPasswordClass, setShowCurrentPassword} as PasswordFieldParameters)
                    }            
                </Grid>
                <Grid container alignItems="flex-start" justifyContent="flex-start" direction="row">
                    <SpinnerButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.passwordPopupButton}
                        pathToImagesFolder={urlJoin(settings.staticFiles.endpoint, '/images')}
                        spinnerVisible={isValidating}
                        disabled={passwordEmpty || userPassword === ''}
                    >
                        {buttonText}
                    </SpinnerButton>
                    <Button
                        onClick={() => setShowPasswordConfirm(false)}
                        type="button"
                        variant="outlined"
                        color="default"
                        disabled={isValidating}
                        className={classes.passwordPopupButton}
                    >
                        {t('button.cancel')}
                    </Button>
                </Grid>
            </form>
        </>
    )
}

export default React.memo(PasswordPopup);