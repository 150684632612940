import { all, put, fork, select, takeLatest } from 'redux-saga/effects';
import { AppState } from '../index';
import { setFieldsMetadata, setFieldDataSuccess } from './actions';
import { getFieldsMetaData } from '../../api/fields';
import { FieldsMetadataActionType, FieldsMetaData } from './types';

export function* loadFieldMetadata() {
    
    const currentCulture: string = yield select((state: AppState) => state.cultures.currentCulture);
    const fieldsMetadata: FieldsMetaData = yield getFieldsMetaData(currentCulture);

    yield put(setFieldsMetadata(fieldsMetadata));
    yield put(setFieldDataSuccess());
}

export function* watchLoadFieldMetadata() {
    yield takeLatest(FieldsMetadataActionType.GET_FIELDSMETADATA, loadFieldMetadata);
}

export default function* fieldsMetadataSagas() {
    yield all([
        fork(watchLoadFieldMetadata)
    ]);
}