import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { PasswordAndPrivacyTab, PasswordAndPrivacyTabsProps } from './Types';
import { useStyles } from './Styles';

const PasswordAndPrivacyTabs: React.FC<PasswordAndPrivacyTabsProps> = ({activeTab, tabs, setActiveTab}) => {

    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} className={classes.paneContainer}>
                    <Grid container spacing={2}>
                        <Grid item  className={classes.pane}>
                            {tabs.map((tab: PasswordAndPrivacyTab) => {
                                return activeTab === tab.id ? 
                                        <span className={classes.paneactive} key={tab.id}>{t(`privacy_and_security.header_${tab.id}`)}</span>
                                    :
                                        <RouterLink to={tab.route} onClick={() => setActiveTab(tab.id)} key={tab.id} className={`${classes.panelink} triggerWarning`}>{t(`privacy_and_security.header_${tab.id}`)}</RouterLink>
                                    }
                                )
                            }
                        </Grid>
                    </Grid>   
                </Grid>
            </Grid>
            <div className={classes.paneunderline}>&nbsp;</div>
        </>
    )
}

export default PasswordAndPrivacyTabs;