import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import settings from '../../config/settings';
import { getDipLegalInfo, DIPLegalInfo } from '../../api/legal';
import { Cultures } from '../../store/cultures/types';
import { useStyles } from './Styles';
import { LegalInfo } from '../../store/legalInfo/types';
import { setLegalInfo } from '../../store/legalInfo/actions';

interface Props {
    contentClass?: string,
    cultures: Cultures,
    legalInfo?: LegalInfo,
    updateLegalInfo: Function
}

const Footer: React.FC<Props> = ({contentClass, cultures, legalInfo, updateLegalInfo}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [dipLegalInfo, setDipLegalInfo] = useState({dip_terms_and_conditions: {}, privacy_policy: {}} as DIPLegalInfo);
   
    const showCookieConsent = () => {
        return () => {
            if ((window as any).CookieConsent) (window as any).CookieConsent.show();
        }
    }

    useEffect(() => {

        const getLegalInfo = async () => {
            const li = await getDipLegalInfo(cultures.currentCulture);
            setDipLegalInfo(li)
            await updateLegalInfo({currentCulture: cultures.currentCulture, ...li})
        }

        if (legalInfo && (cultures.currentCulture !== legalInfo.currentCulture)) {
            getLegalInfo();
        } else {
            legalInfo && setDipLegalInfo({dip_terms_and_conditions: legalInfo.dip_terms_and_conditions, privacy_policy: legalInfo.privacy_policy});
        }
        
	}, [cultures.currentCulture, legalInfo]);

    return (
        <Grid container className={classes.black}>
            <Grid container className={classes.root}>
                <Grid container className={(contentClass || classes.content) && `${classes.padding}`}>
                    <div className={classes.item}>
                        <a href={dipLegalInfo.privacy_policy.url} target="_blank" rel="noopener noreferrer" className={classes.link} data-testid="privacy_policy">
                            {t('footer.privacy_policy')}
                        </a>
                    </div>
                    <div className={classes.item}>
                        <a href={dipLegalInfo.dip_terms_and_conditions.url} target="_blank" rel="noopener noreferrer" className={classes.link} data-testid="terms_of_use">
                            {t('footer.terms_of_use')}
                        </a>
                    </div>
                    <div className={classes.item}>
                        <a href={settings.generalInformation.endpoint} target="_blank" rel="noopener noreferrer" className={classes.link}>
                            {t('footer.general_information')}
                        </a>
                    </div>
                    <div className={classes.item } onClick={showCookieConsent()}>
                        {t('footer.cookies')}
                    </div>
                    <div className={classes.item}>
                        <a href={`${settings.faq.endpoint}?app_id=${settings.application.appId}`} target="_blank" rel="noopener noreferrer" className={classes.link} data-testid="faq">
                            {t('footer.faq')}
                        </a>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state: AppState)  => ({
    cultures: state.cultures,
    legalInfo: state.legalInfo 
});

const mapDispatchToProps = (dispatch: any) => () => ({
    updateLegalInfo: (data: LegalInfo) => dispatch(setLegalInfo(data))
});
      
export default connect(mapStateToProps, mapDispatchToProps)(Footer);

