import settings from '../config/settings';
import fetchApiWrapper from './fetchApiWrapper';

export interface SystemMaintenanceSettings {
    pages: SystemMaintenanceSetting[]
}

export interface SystemMaintenanceSetting {
    path: string,
	authenticated?: boolean,
    show_warning: boolean
}

export interface EncryptedDataResponse {
	encrypted_data: EencryptedData[]
}

interface EencryptedData {
	original_value: string,
	encrypted_value: string	
}

export const getSystemMaintenanceSettings = (): Promise<SystemMaintenanceSettings> => {
	
	return fetchApiWrapper<any>(
		`${settings.staticFiles.endpoint}/maintenance/myDanfossProfile.json`,
		{
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			method: 'GET',
		}
	);
};

export const encryptData = (dataToEncrypt: string, accessToken: string): Promise<EncryptedDataResponse> => {

	const requestHeaders = new Headers();
	requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
	requestHeaders.append('Authorization', `BEARER ${accessToken}`)
	
	return fetchApiWrapper(`${settings.backend.endpoint}/encrypt-data`, {
		body: `{"data":["${dataToEncrypt}"]}`,
		headers: requestHeaders,
		method: 'POST',
	  })
  }