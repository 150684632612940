import { action } from 'typesafe-actions';
import { RecommendedApplicationPreference, 
		 RecommendedApplicationWithPreference, 
		 RelevantWebsitePreference, 
		 RelevantWebsiteWithPreference, 
		 UsedApplicationPreference, 
		 UsedApplicationWithPreference,
		 UserPreferences		 
		} from '../../api/user';
import { DashboardItemsActionType, DashboardItems, LoadingInformation } from './types';

export interface GetUsedApplications {
	type: DashboardItemsActionType.GET_USED_APPLICATIONS;
}

export interface GetRecommendedApplications {
	type: DashboardItemsActionType.GET_RECOMMENDED_APPLICATIONS;
}

export interface GetRelevantWebsites {
	type: DashboardItemsActionType.GET_RELEVANT_WEBSITES;
}

export interface SetDashboardItems {
	type: DashboardItemsActionType.SET_DASHBOARD_ITEMS;
    payload: DashboardItems;
}

export interface SetUsedApplications {
	type: DashboardItemsActionType.SET_USED_APPLICATIONS;
    payload: UsedApplicationWithPreference[];
}

export interface SetRecommendedApplications {
	type: DashboardItemsActionType.SET_RECOMMENDED_APPLICATIONS;
    payload: RecommendedApplicationWithPreference[];
}

export interface SetRelevantWebsites {
	type: DashboardItemsActionType.SET_RELEVANT_WEBSITES;
    payload: RelevantWebsiteWithPreference[];
}

export interface SetRelevantWebsitesPreferences {
	type: DashboardItemsActionType.SET_RELEVANT_WEBSITES_PREFERENCES;
    payload: RelevantWebsitePreference[];
}

export interface SetRecommendedApplicationsPreferences {
	type: DashboardItemsActionType.SET_RECOMMENDED_APPLICATION_PREFERENCES;
    payload: RecommendedApplicationPreference[];
}

export interface SetUsedApplicationsPreferences {
	type: DashboardItemsActionType.SET_USED_APPLICATION_PREFERENCES;
    payload: UsedApplicationPreference[];
}

export interface UpdateUserPreferences {
	type: DashboardItemsActionType.UPDATE_USER_PREFERENCES;
    payload: UserPreferences;
}

export interface SetLoadedFalse {
	type: DashboardItemsActionType.SET_LOADED_FALSE;
}

export interface SetRelevantWebsitesLoaded {
	type: DashboardItemsActionType.SET_RELEVANT_WEBSITES_LOADED;
	payload: LoadingInformation;
}

export interface SetRecommendedApplicationsLoaded {
	type: DashboardItemsActionType.SET_RECOMMENDED_APPLICATIONS_LOADED;
	payload: LoadingInformation;
}

export interface SetUsedApplicationsLoaded {
	type: DashboardItemsActionType.SET_USED_APPLICATIONS_LOADED;
	payload: LoadingInformation;
}

export const setRelevantWebsitesPreferences = (preferences: RelevantWebsitePreference[]): SetRelevantWebsitesPreferences =>
	action(DashboardItemsActionType.SET_RELEVANT_WEBSITES_PREFERENCES, preferences)

export const setRecommendedApplicationsPreferences = (preferences: RecommendedApplicationPreference[]): SetRecommendedApplicationsPreferences =>
	action(DashboardItemsActionType.SET_RECOMMENDED_APPLICATION_PREFERENCES, preferences)

export const setUsedApplicationsPreferences = (preferences: UsedApplicationPreference[]): SetUsedApplicationsPreferences =>
	action(DashboardItemsActionType.SET_USED_APPLICATION_PREFERENCES, preferences)

export const setUsedApplications = (usedApplicationsWithPreferences: UsedApplicationWithPreference[]): SetUsedApplications =>
	action(DashboardItemsActionType.SET_USED_APPLICATIONS, usedApplicationsWithPreferences)

export const setRecommendedApplications = (recommendedApplicationsWithPreferences: RecommendedApplicationWithPreference[]): SetRecommendedApplications =>
	action(DashboardItemsActionType.SET_RECOMMENDED_APPLICATIONS, recommendedApplicationsWithPreferences)

export const setRelevantWebsites = (relevantWebsitesWithPreferences: RelevantWebsiteWithPreference[]): SetRelevantWebsites =>
	action(DashboardItemsActionType.SET_RELEVANT_WEBSITES, relevantWebsitesWithPreferences)

export const getUsedApplications = (): GetUsedApplications =>
	action(DashboardItemsActionType.GET_USED_APPLICATIONS);

export const getRecommendedApplications = (): GetRecommendedApplications =>
	action(DashboardItemsActionType.GET_RECOMMENDED_APPLICATIONS);

export const getRelevantWebsites = (): GetRelevantWebsites =>
	action(DashboardItemsActionType.GET_RELEVANT_WEBSITES);

export const updateUserPreferences = (userPreferences: UserPreferences): UpdateUserPreferences => 
	action(DashboardItemsActionType.UPDATE_USER_PREFERENCES, userPreferences);

export const setLoadedFalse = (): SetLoadedFalse => 
	action(DashboardItemsActionType.SET_LOADED_FALSE);

export const setRelevantWebsitesLoaded = (loadingInformation: LoadingInformation): SetRelevantWebsitesLoaded => 
	action(DashboardItemsActionType.SET_RELEVANT_WEBSITES_LOADED, loadingInformation);

export const setRecommendedApplicationsLoaded = (loadingInformation: LoadingInformation): SetRecommendedApplicationsLoaded => 
	action(DashboardItemsActionType.SET_RECOMMENDED_APPLICATIONS_LOADED, loadingInformation);

export const setUsedApplicationsLoaded = (loadingInformation: LoadingInformation): SetUsedApplicationsLoaded => 
	action(DashboardItemsActionType.SET_USED_APPLICATIONS_LOADED, loadingInformation);

export type DashboardItemActionType = GetUsedApplications | 
            GetRecommendedApplications | 
			GetRelevantWebsites | 
			SetDashboardItems | 
			SetRecommendedApplications | 
			SetRelevantWebsites | 
			SetUsedApplications | 
			SetRelevantWebsitesPreferences | 
			SetRecommendedApplicationsPreferences | 
			SetUsedApplicationsPreferences | 
			SetRelevantWebsitesLoaded | 
			SetRecommendedApplicationsLoaded | 
			SetUsedApplicationsLoaded |
			SetLoadedFalse;
