import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      margin: '0 80px 0 0',
      paddingLeft: '10px'
    },
    position: 'relative'
  },
  header: {
    fontSize: '36px',
    fontWeight: 'bold',
    letterSpacing: '0.14px',
    lineHeight: '48px',
    marginBottom: '11px'
  },
  description: {
    marginBottom: '49px',
    color: '#575756',
    maxWidth: '954px'
  },
  subHeader: {
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '25px',
    margin: '0px 0px 10px'
  },
  descriptionText: {
    color: colors.webexuiGrayText,
    fontSize: '16px',
    lineHeight: '24px'
  },
  DescriptionTextBold: {
    fontWeight: 'bold',
    color: colors.webexuiBlack
  },
  spacerTop: {
    marginTop: '20px'
  },
  spacerLeft: {
    borderRight: `1px solid ${colors.webexuiGrayBg}`
  },
  buttonContainer: {
    [theme.breakpoints.only('xs')]: {
      margin: '15px 0px'
    },
    [theme.breakpoints.up('sm')]: {
      margin: '35px 0px 40px'
    }
  },
  button: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      margin: '0px 0px 10px !important'
    }
  },
  hidden: {
    display: 'none'
  },
  complexityInfoIcon: {
    height: '21px',
    minWidth: '21px',
    marginRight: '8px'
  },
  complexityTextIcon: {
    height: '32px',
    width: '32px',
    marginRight: '8px'
  },
  complexityInfoText: {
    lineHeight: '20px'
  },
  complexityLength: {
    display: 'flex',
    marginBottom: '8px'
  },
  complexityRuleDescription: {
    display: 'flex',
    marginBottom: '8px'
  },
  complexityRule: {
    marginLeft: '23px',
    display: 'flex',
    marginBottom: '8px'
  },
  linkText: {
    fontSize: '18px',
    fontWeight: 600,
    letterSpacing: '0.18px',
    lineHeight: '28px',
    textDecoration: 'none',
    color: colors.webexuiDarkGrayish,
    cursor: 'pointer'
  },
  linkArrow: {
    marginRight: '7px'
  },
  changePasswordArea: {
    [theme.breakpoints.only('xs')]: {
      paddingRight: '20px'
    }
  },
  fullWidth: {
    maxWidth: '550px',
    [theme.breakpoints.only('xs')]: {
      maxWidth: '100%',
      flexBasis: '100%'
    }
  },
  passwordPopupContainer: {
    width: '100%',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      top: 0,
      paddingRight: '26px'
    },
    [theme.breakpoints.only('xs')]: {
      top: '100px',
      paddingRight: '20px'
    }
  },
  changePasswordPopupContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
    top: '195px',
    [theme.breakpoints.only('xs')]: {
      top: '240px'
    }
    
  },
  passwordPopup: {
    maxWidth: '546px',
    [theme.breakpoints.up('sm')]: {
      margin: '0px 15px 0'
    },
    textAlign: 'left',
    paddingBottom: '23px'
  },
  passwordPopupHeader: {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '20px 0 16px'
  },
  popupContent: {
    fontSize: '16px',
    color: '#686868',
    marginBottom: '20px'
  },
  popupButtonContainer: {
    marginTop: '38px'
  },
  passwordPopupButton: {
    marginRight: '20px',
    marginBottom: '10px',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      margin: '0px 0px 10px !important'
    }
  },
  popupContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
    top: '-50px',
  },
  popup: {
    top: '-134px',
    maxWidth: '650px',
    display: 'table',
    position: 'absolute',
    [theme.breakpoints.up('sm')]: {
      top: '-95px',
      marginLeft: '-10px'
    }
  },
  overlay: {
    [theme.breakpoints.only('xs')]: {
      // marginTop: '10px',
      height: '100%'
    }
  },
  changePasswordOverlay: {
      position:'fixed', 
      backgroundColor: colors.webexuiWhite, 
      top:'0', 
      left:'0', 
      bottom:'0', 
      right:'0', 
      overflow:'auto', 
      zIndex:1200,
      opacity: '0.85',
  },
  zIndexOverlay: {
    zIndex: 1301
  },
  zIndexPopup: {
    zIndex: 1302
  },
  popupHeader: {
    color: colors.webexuiDarkGrayish,
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '25px',
    textAlign: 'left',
    margin: '26px 26px 26px 0'
  },
  ErrorShadow: {
    boxShadow: `0 0 5px 0 ${colors.webexuiRed}`
  },
  element: {
    width: '100%',
    margin: '0 0 32px 0'
  },
  labelError: {
    fontFamily: 'myriad-pro, Myriad Pro',
    color: 'red',
    fontSize: '16px',
    lineHeight: '24px',
    [theme.breakpoints.up('md')]: {
      float: 'right'
    },
    textAlign: 'right'
  },
  close: {
    cursor: 'pointer',
    width: '16px',
    [theme.breakpoints.only('xs')]: {
      margin: '10px -16px',
    },
    [theme.breakpoints.up('sm')]: {
      right: '16px'
    },
    position: 'absolute'
  },
  textContainer: {
    marginTop: '20px'
  },
  deleteLinkContainer: {
    padding: '13px 0 40px'
  },
  MFAOuterContainer: {
    margin: '0 0 30px'
  },
  MFALinkContainer: {
    padding: '15px 0 15px'
  },
  MFALinkText: {
    padding: '8px 0 0'
  },
  changePasswordForm: {
    marginTop: '-6px',
    maxWidth: '360px'
  },
  whiteBackground: {
    backgroundColor: colors.webexuiWhite,
    padding: '12px 0 10px 20px'
  },
  blackText: {
    color: colors.webexuiBlack,
  },
  redText: {
    color: colors.webexuiRed,
  },
  paneContainer: {
    color: colors.webexuiGrayText,
    paddingBottom: '5px',
    marginBottom: '0px',
    fontSize: '16px',
    zIndex: 10
  },
  pane: {
    minWidth: '80px'
  },
  paneactive: {
    borderBottom: `3px solid ${colors.webexuiRed}`,
    padding: '0 8px 8px',
    zIndex: 1000,
    whiteSpace: 'nowrap',
    marginRight: '20px',
    color: colors.webexuiBlack
  },
  panelink: {
    padding: '0 8px 8px',
    textDecoration: 'none',
    color: colors.webexuiGrayText,
    whiteSpace: 'nowrap',
    marginRight: '20px'
  },
  paneunderline: {
    width: '100%',
    borderBottom: `1px solid ${colors.webexuiGray}`,
    marginTop: theme.spacing(-1) - 3,
    marginBottom: '30px'
  },
  paneMobile: {
    borderBottom: `1px solid ${colors.webexuiGray}`
  },
  iconStyle: {
    position: 'absolute',
    top: '0',
    right: '0',
    display: 'inline-block',
    color: '#c0c0c0',
    width: '13px',
    height: '13px',
    margin: '12px 10px',
    pointerEvents: 'none'
  },
  expanded: {
    color: colors.webexuiRed,
    fontWeight: 'bold',
    fontSize: '20px'
  },
  collapsed: {
    fontWeight: 'bold',
    fontSize: '20px'
  },
  maintenanceAlertPopupContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    position: 'absolute',
    height: 'calc(100% - 50px)'
  },
  maintenanceAlertPopup: {
    top: '50px',
    maxWidth: '650px',
    display: 'table',
    marginTop: 'auto !important',
    padding: '30px',
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      marginLeft: '0px'
    },
    [theme.breakpoints.down('sm')]: {
      top: '50px'
    },
    [theme.breakpoints.only('xs')]: {
      position: 'absolute'
    }
  },
  maintenanceAlertOverlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      marginTop: '10px',
      height: '100%',
      bottom: '50px'
    }
  },
  MuiAccordionroot: {
    zIndex: 100,
    width: '100%'
  },
  accordionDetails: {
    [theme.breakpoints.only('xs')]: {
      minHeight: '500px'
    }
  },
  leaveConfirmContainer: {
    top: '-50px',
    width: '100%',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'center'
  },
  leaveConfirmPopup: {
    padding: '0px !important',
    zIndex: 10000,
    position: 'absolute',
    maxWidth: '562px',
    margin: 'auto'
  }
}));