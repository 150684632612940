import React from 'react';
import { Application } from '../../api/application';
import { isIOS, isAndroid} from 'mobile-device-detect';
import { mobileAppExistsForOs, getMobileStoreLink } from './Helpers';

interface DownloadIconProps {
    classes: any;
    application: Application,
    appStoreImage: string;
    googlePlayImage: string;
}

const DownloadIcon: React.FC<DownloadIconProps> = ({ classes, application, appStoreImage, googlePlayImage }) => {
    return (
        <>
            {isIOS && mobileAppExistsForOs("ios", application) ?
                <a href={getMobileStoreLink("ios", application)} target="_blank" rel="noopener noreferrer" className={classes.mobileStoreLink}>
                    <img src={appStoreImage} className={classes.appStoreIcon} alt=""/>
                </a>
            : null}
            {isAndroid  && mobileAppExistsForOs("android", application) ?
                <a href={getMobileStoreLink("android", application)} target="_blank" rel="noopener noreferrer" className={classes.mobileStoreLink}>
                <img src={googlePlayImage} className={classes.googlePlayIcon} alt=""/>
                </a>
            : null}
        </>
    )
}

export default DownloadIcon