import { action } from 'typesafe-actions'
import { Profile, UserData, ProfileActionTypes } from './types';

export interface Login {
	type: ProfileActionTypes.LOGIN;
	payload: Profile;
}

export interface Logout {
	type: ProfileActionTypes.LOGOUT;
}

export interface ClearProfile {
	type: ProfileActionTypes.CLEAR_PROFILE;
}

export interface GetUserError {
	type: ProfileActionTypes.GET_USER_ERROR,
	payload: Error
}

export interface UpdateUser {
	type: ProfileActionTypes.UPDATE_USER;
	payload: Profile;
}

export interface UserRequest {
	type: ProfileActionTypes.USER_REQUEST;
	payload: boolean;
}

export interface FetchLogin {
	type: ProfileActionTypes.FETCH_LOGIN;
}

export interface CheckUser {
	type: ProfileActionTypes.CHECK_USER;
	payload?: string;
}

export interface AuthUser {
	type: ProfileActionTypes.AUTHENTICATION_USER;
}
export interface ResetPassword {
	type: ProfileActionTypes.RESET_PASSWORD;
	payload: string;
}
export interface ChangePasswordRequest {
  type: ProfileActionTypes.CHANGE_PASSWORD_REQUEST;
  payload: ChangePasswordFormData
}

export interface UpdateUserAction {
	type: ProfileActionTypes.UPDATE_USER_ACTION;
	payload: UserData
}

export interface EnhanceUserWithData {
	type: ProfileActionTypes.ENHANCE_USER;
	payload: UserData;
}

interface ChangePasswordFormData {
  currentpassword: string,
  newpassword: string
}

export const authUser = (): AuthUser => action(ProfileActionTypes.AUTHENTICATION_USER);

export const userRequest = (payload: boolean): UserRequest => action(ProfileActionTypes.USER_REQUEST, payload);

export const fetchLogin = (): FetchLogin => action(ProfileActionTypes.FETCH_LOGIN);

export const checkUser = (payload?: string): CheckUser => action(ProfileActionTypes.CHECK_USER, payload);

export const changePasswordRequest = (payload: ChangePasswordFormData ) : ChangePasswordRequest =>
	action(ProfileActionTypes.CHANGE_PASSWORD_REQUEST, payload);
	
export const updateUserAction = (payload: UserData ) : UpdateUserAction =>
    action(ProfileActionTypes.UPDATE_USER_ACTION, payload);

export const login = (user: Profile): Login => action(ProfileActionTypes.LOGIN, user);

export const logout = (): Logout => action(ProfileActionTypes.LOGOUT);

export const getUserError = (payload: Error): GetUserError => action(ProfileActionTypes.GET_USER_ERROR, payload);

export const updateUser = (payload: Profile): UpdateUser => action(ProfileActionTypes.UPDATE_USER, payload);

export const ResetPasswordAction = (payload: string): ResetPassword => action(ProfileActionTypes.RESET_PASSWORD, payload);

export const enhanceUserWithData = (payload: UserData): EnhanceUserWithData => action(ProfileActionTypes.ENHANCE_USER, payload)

export const clearProfile = (): ClearProfile => action(ProfileActionTypes.CLEAR_PROFILE);

export type UserActionType = Login | Logout | ClearProfile | GetUserError | UpdateUser | UserRequest | ChangePasswordRequest | UpdateUserAction | ResetPassword | EnhanceUserWithData | CheckUser;
