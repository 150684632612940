import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	contentContainer: {
        [theme.breakpoints.up('sm')]: {
            padding: '8px 10px 35px',
		},
		[theme.breakpoints.only('xs')]: {
            padding: '0px 8px',
		},
        marginTop: '8px',
        marginBottom: '8px',
        minHeight: '200px'
    },
    buttonContainer: {
        margin: '30px 0 0'
    },
    button:{
        [theme.breakpoints.only('xs')]: {
            width: '100%',
        }
    },
    mainTitle: {
        paddingLeft: '0px !important',
        textAlign: 'left',
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '24px'
      },
    textContainer: {
        color: '#686868',
        textAlign: 'left'
    },
    detailedErrorContainer: {
        backgroundColor: colors.webexuiGrayBg,
        color: colors.webexuiBlack,
        textAlign: 'left',
        marginBottom: '12px',
        borderRadius: '7px',
        padding: '10px'
    },
    showHide: {
        color: '#686868',
        textAlign: 'left',
        marginBottom: '12px',
        cursor:'pointer'
    },
    wordBreak: {
        wordWrap: 'break-word'
    },
    iconStyle: {
        display: 'inline-block',
        width: '13px',
        height: '13px',
        marginRight: '10px',
        pointerEvents: 'none'
    },
    iconStyleRotate: {
        display: 'inline-block',
        width: '13px',
        height: '13px',
        marginRight: '10px',
        pointerEvents: 'none',
        transform: 'rotate(180deg)'
    }
}));