import React, { useEffect, useState } from 'react';
import { isContentOnly } from '../../utils/index';
import { useStyles } from './Styles';
import PasswordAndPrivacy from './PasswordAndPrivacy';
import Main from '../Main/Main';
import { pushEvent } from '../../Services/AnalyticsService';
import { useLocation } from 'react-router-dom';

const PasswordAndSecurityWrapper: React.FC = () => {
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState('change_password');
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
      setActiveTab(location.pathname.substring(location.pathname.lastIndexOf("/")+1).replace('-','_'));
      setIsLoading(false);
    }, []);

    useEffect(() => {

      // Push an event that a user entered the passwordAndPrivacy page
      pushEvent(
        {
          'event': 'virtualPageview',
          'page': `${(window as any).location.hostname}${location.pathname}`
        }
      );

    }, [location.pathname]);

    const getContent = () => {
      return isContentOnly() ? (
        <span className={classes.whiteBackground}>
          <PasswordAndPrivacy activeTabFromProps={activeTab} />
        </span>
      ) : (
        <Main allowMaintenanceAlert={false}>
              <PasswordAndPrivacy activeTabFromProps={activeTab} />
        </Main>
      );
    }

    return isLoading ? null : getContent();
}

export default PasswordAndSecurityWrapper;