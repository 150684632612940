import { LegalInfoActionTypes, LegalInfo } from "./types";
import { createReducer } from 'typesafe-actions';
import { LegalInfoActionType } from './actions';

export const initialState = null;

const reducer = createReducer<LegalInfo, LegalInfoActionType>(initialState as unknown as LegalInfo, {
  [LegalInfoActionTypes.SET_LEGALINFO]: (state, action) => {
    return { ...action.payload };
  }
});

export { reducer as legalInfoReducer };

