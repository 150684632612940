import React, { useEffect, useState } from 'react';
import './styles.scss';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';
import { Spinner } from '@danfoss/webex-ui';
import { isContentOnly } from '../../utils/index';

import { useAuthentication } from '../../Providers/AuthenticationProvider';
import { useStyles } from './Styles';
import BreadCrumbs from './../BreadCrumbs';
import { getSystemMaintenanceSettings, SystemMaintenanceSettings, SystemMaintenanceSetting } from '../../api/system';
import { useLocation } from 'react-router-dom';
import MaintenanceAlert from '../MaintenanceAlert';

interface MainProps {
	children: {},
	itemClass?: string,
	allowMaintenanceAlert?: boolean
}

const isPageDownForMaintenance = (systemMaintenanceSettings: SystemMaintenanceSettings, isAuthenticated: boolean, pathname: string) => {

	const pageSetting:SystemMaintenanceSetting[] = systemMaintenanceSettings?.pages?.reduce((pages:SystemMaintenanceSetting[], setting: SystemMaintenanceSetting) => {
		
		if (setting.path === pathname && setting.show_warning) {
			if (setting.authenticated && isAuthenticated) {
				pages.push(setting);
			}

			if (setting.authenticated === false && !isAuthenticated) {
				pages.push(setting);
			}				

			if (setting.authenticated === undefined) {
				pages.push(setting);
			}
		}

		return pages;

	}, []);

	if (pageSetting?.length > 0) {
		return (<MaintenanceAlert/>)
	}

	return null;
}

const Main: React.FC<MainProps> = ({ children, itemClass, allowMaintenanceAlert }) => {
	const classes = useStyles();
	const { loading, isAuthenticated } = useAuthentication();
	const [systemMaintenanceSettings, setSystemMaintenanceSettings] = useState({} as SystemMaintenanceSettings);
	const location = useLocation();

	useEffect(() => {
		const getMaintenanceSettings = async () => {
			await getSystemMaintenanceSettings().then((response:any) => { 
				setSystemMaintenanceSettings(response);
			})
		}

		getMaintenanceSettings();

	}, []);

	if (loading) {
		return <Spinner  visible={true} />
	}

	return (
		<React.Fragment>
			{!isContentOnly() && (<Header/>)}
			<Container className={`${itemClass ? 'myAccountFullWidth' : 'myAccount'} flexgrow`}>
				<Grid container className={itemClass ? classes.containerFullWidth : classes.container}>
					<Grid item xs={12} className={`${itemClass || 'myAccountContent'}`}>
						<>
							{!isContentOnly() && (
								<BreadCrumbs/>
							)}
							{(allowMaintenanceAlert !== false) && isPageDownForMaintenance(systemMaintenanceSettings, isAuthenticated, location.pathname)}
							{children}
						</>
					</Grid>
				</Grid>
			</Container>
			{!isContentOnly() && (<Footer />)}
		</React.Fragment>
	);
};

export default Main;
