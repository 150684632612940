import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import FormChangePassword from './ChangePassword';
import DeleteUser from './DeleteUser';
import PasswordPopup from './PasswordPopup'
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './Styles';
import { Spinner } from '@danfoss/webex-ui';
import ModalPopup from '../Modal/ModalPopup';
import { useAuthentication } from '../../Providers/AuthenticationProvider';
import { deleteUser } from '../../api/user';
import { subActivityHostService } from '../../Services/SubActivityHostService';
import { clearProfile as actionClearProfile, changePasswordRequest } from "../../store/profile/actions";
import { getUserIdentity } from '../../store/profile/helpers';
import { AppState } from "../../store";
import { getPasswordComplexityData } from '../../api/connection';
import { PasswordAndSecurityProps, ChangePasswordFormData, PasswordAndPrivacyTab } from './Types';
import MultiFactor from './MultiFactor';
import { getMultifactorInfo, sendNewRecoveryCode, resetMultifactorInfo, MultifactorSettings } from '../../api/multifactor';
import { Profile } from '../../store/profile/types';
import PasswordAndPrivacyContent from './PasswordAndPrivacyContent';
import { getSystemMaintenanceSettings, SystemMaintenanceSettings, SystemMaintenanceSetting } from '../../api/system';
import { isContentOnly } from '../../utils';
import i18n from "i18next";
import MaintenanceAlert from '../MaintenanceAlert';
import Button from '@material-ui/core/Button';
import parse from 'html-react-parser';

const onDeleteUser = async (password: string, accessToken: string, logoutWithRedirect: Function, clearProfile: Function, webkitActive: boolean, profile: Profile | undefined) => {
 
    // A user deletion is triggered
    const isUserDeleted = await deleteUser((profile?.sub) || '', accessToken, password);

    // If the user is deleted then continue, 
    // otherwise return log out the user
    if (isUserDeleted && isUserDeleted.ok) {
        handlePasswordResetLogout(logoutWithRedirect, clearProfile, webkitActive);
    } else {
        return isUserDeleted;
    }
}

const handlePasswordResetLogout = (logoutWithRedirect: Function, clearProfile: Function, webkitActive: boolean) => {
 
    if (!webkitActive) {
        sessionStorage.setItem("redirectAfterLogout", `/initiate-login`);
        logoutWithRedirect();
    } else {
        subActivityHostService.close();
    }

    clearProfile();
}

const onSendRecoveryCode = async (password: string, profile: Profile | undefined, accessToken: string, setRecoveryCodeSentRef: Function, setShowRecoveryCodePasswordConfim: Function) => {
    // Send a new recovery code to the user on mail
    const result = await sendNewRecoveryCode(profile?.email || '', i18n.language, password, accessToken);
        
    // If the recovery code was sent successfully
    if (result && result.ok) {
        setRecoveryCodeSentRef(true);
        setShowRecoveryCodePasswordConfim(false);
    } else {
        return result;
    }        
}

const onResetMFASettings = async (profile: Profile | undefined, accessToken: string, setResetMFASuccessRef: Function) => {
    // Send a new recovery code to the user on mail
    await resetMultifactorInfo(profile?.sub || '', accessToken).then((result:any) => {
        // If the recovery code was sent successfully
        if (result && result.ok) {
            setResetMFASuccessRef(true);
        }
    });
}

const getPWComplexity = async (profile: Profile | undefined, accessToken: string) => {
    if (!profile || !accessToken) return null;

    const userIdentity = getUserIdentity(profile);

    return getPasswordComplexityData(userIdentity ? userIdentity.connection : '', accessToken);
}

const onSubmit = async (data: any, profile: Profile | undefined, accessToken: string , changePassword: Function, logoutWithRedirect: Function, webkitActive: boolean) => {
    if (!profile) return;
    await changePassword({ ...data, accessToken: accessToken, userId: profile.sub, logout: logoutWithRedirect, nativeMode: webkitActive, successCallback: subActivityHostService.close() });
}

const handleAccordianClick = (tab: PasswordAndPrivacyTab, activeTab: string, setActiveTabRef: Function) => {
    if (activeTab !== tab.id || activeTab === '') {
        setActiveTabRef(tab.id)
    } else {
        setActiveTabRef("")
    }
}

const getVisibleTabs = (hideMultifactor: boolean, tabs: PasswordAndPrivacyTab[]) => {
    if (hideMultifactor) {
        return tabs.reduce((reducedTabs: PasswordAndPrivacyTab[], tab) => {

            if (tab.id !== "multifactor_settings") {
                reducedTabs.push(tab);
            }

            return reducedTabs;
        }, [])
    }

    return tabs;
}

const isMultifactorToBeHidden = (mfaSettings: MultifactorSettings) => {
    return mfaSettings && (mfaSettings.mfa_settings && mfaSettings.mfa_settings.length === 0 || mfaSettings.mfa_settings && mfaSettings.mfa_settings.filter(mfa => mfa.confirmed === true).length === 0);
}

const getRoute = (contentOnly: boolean, contentOnlyRoute: string, route: string) => {
    return contentOnly ? contentOnlyRoute : route;
}

const changeActiveTab = (passwordAndPrivacyTabs: PasswordAndPrivacyTab[], activeTab: string, setActiveComponent: Function, setActiveTabRef: Function) => {
    const ac = passwordAndPrivacyTabs.find((tab: PasswordAndPrivacyTab) => tab.id === activeTab) || {} as PasswordAndPrivacyTab
            
    // after resetMFASettings we need to send the user to change password if the browser is refreshed
    if (ac.component) {
        setActiveComponent(ac)
    } else {
        setActiveTabRef(passwordAndPrivacyTabs[0].id);
        setActiveComponent(passwordAndPrivacyTabs.find((tab: PasswordAndPrivacyTab) => tab.id === passwordAndPrivacyTabs[0].id) || {} as PasswordAndPrivacyTab)
    }
}

const getPageSetting = (systemMaintenanceSettings: SystemMaintenanceSettings, activeTabRef: React.MutableRefObject<string>) => {
    return systemMaintenanceSettings?.pages?.filter((setting: SystemMaintenanceSetting) => setting.path === `/${activeTabRef.current.replace('_', '-')}` && setting.show_warning) || []; 
}

const PasswordAndPrivacy: React.FC<PasswordAndSecurityProps> = ({ profile, clearProfile, changePassword, cultures, activeTabFromProps }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [showUserDeletionConfirm, setShowUserDeletionConfirm] = useState(false);
    const [showRecoveryCodePasswordConfim, setShowRecoveryCodePasswordConfim] = useState(false);
    const [showChangePasswordPopup, setShowChangePasswordPopup] = useState(false);
    const [recoveryCodeSent, setRecoveryCodeSent] = useState(false);
    const [resetMFASuccess, setResetMFASuccess] = useState(false);
    const [activeComponent, setActiveComponent] = useState({} as PasswordAndPrivacyTab);
    const [passwordAndPrivacyTabs, setPasswordAndPrivacyTabs] = useState([] as PasswordAndPrivacyTab[]);
    const [accessToken, setAccessToken] = useState('')
    const [activeTab, setActiveTab] = useState("");
    const [isConnectionFederation, setIsConnectionFederation] = useState(false);
    const [isPasswordBeingSaved, setIsPasswordBeingSaved] = useState(false);
    const { getTokenSilently, logoutWithRedirect, webkitActive } = useAuthentication();
    
    const recoveryCodeSentRef = React.useRef(recoveryCodeSent);

    const setRecoveryCodeSentRef = (RCSent: any) => {
		recoveryCodeSentRef.current = RCSent;
		setRecoveryCodeSent(RCSent);
    };

    const resetMFASuccessRef = React.useRef(resetMFASuccess);

    const setResetMFASuccessRef = (success: any) => {
		resetMFASuccessRef.current = success;
		setResetMFASuccess(success);
    };
    
    const activeTabRef = React.useRef(activeTab);

    const setActiveTabRef = (at: string) => {
		activeTabRef.current = at;
		setActiveTab(at);
    };

    const scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    useEffect(() => {

        setIsLoading(true);
        
        const pwPasswordAndPrivacyData = async () => {
            
            const systemMaintenanceSettings = await getSystemMaintenanceSettings()

            const token = await getTokenSilently();
            setAccessToken(token);

            let [mfaSettings, pwComplexity] = await Promise.all([
                getMultifactorInfo(profile?.sub, token),
                getPWComplexity(profile, token)
            ])            

            const contentOnly = isContentOnly();
            const hideMultifactor = isMultifactorToBeHidden(mfaSettings);

            const userIdentity = getUserIdentity(profile);
            const isConFederation = userIdentity?.metadata?.IsFederation ?? false;
            const connectionName = userIdentity?.connection ?? '';
            setIsConnectionFederation(isConFederation);
            
            let tabs: PasswordAndPrivacyTab[] = [
                {
                    id: "change_password",
                    header: 'privacy_and_security.header_change_password',
                    route: getRoute(contentOnly, "/content-only/change-password", "/change-password"),
                    component: FormChangePassword,
                    parameters: {
                        connectionName: connectionName,
                        passwordComplexity: pwComplexity,
                        onSubmit: (data: any) => {scrollToTop(); onSubmit(data, profile, token, changePassword, logoutWithRedirect, webkitActive);},
                        isMultifactorEnrolled: !hideMultifactor,
                        isFederation: isConFederation,
                        setActiveTab: setActiveTabRef,
                        systemMaintenanceSettings:systemMaintenanceSettings,
                        isPageDownForMaintenance: isPageDownForMaintenance,
                        setShowChangePasswordPopup: setShowChangePasswordPopup,
                        setIsPasswordBeingSaved: setIsPasswordBeingSaved
                    }
                },
                {
                    id: "multifactor_settings",
                    header: 'privacy_and_security.header_multifactor_settings',
                    route: getRoute(contentOnly, "/content-only/multifactor-settings", "/multifactor-settings"),
                    component: MultiFactor,
                    parameters: {
                        profile: profile,
                        mfaSettings: mfaSettings && mfaSettings.mfa_settings,
                        cultures: cultures,
                        setShowRecoveryCodePasswordConfim: setShowRecoveryCodePasswordConfim,
                        resetMFASettings: () => onResetMFASettings(profile, token, setResetMFASuccessRef),
                        recoveryCodeSent: recoveryCodeSentRef,
                        resetMFASuccess: resetMFASuccessRef,
                        systemMaintenanceSettings:systemMaintenanceSettings,
                        isPageDownForMaintenance: isPageDownForMaintenance
                    }
                },
                {
                    id: "delete_account",
                    header: 'privacy_and_security.header_delete_account',
                    route: getRoute(contentOnly, "/content-only/delete-account", "/delete-account"),
                    component: DeleteUser,
                    parameters: {
                        setShowUserDeletionConfirm: setShowUserDeletionConfirm,
                        isMultifactorEnrolled: !hideMultifactor,
                        isFederation: isConFederation,
                        connectionName: connectionName,
                        setActiveTab: setActiveTabRef,
                        systemMaintenanceSettings:systemMaintenanceSettings,
                        isPageDownForMaintenance: isPageDownForMaintenance,
                        profile: profile
                    }
                }
            ];

            // remove multifactor tab if user isn't enrolled in MFA
            tabs = getVisibleTabs(hideMultifactor, tabs)
            
            setPasswordAndPrivacyTabs(tabs);

            if (activeTab === "") {
                setActiveTabRef(activeTabFromProps)
            }
        }

        pwPasswordAndPrivacyData();

    }, []);

    useEffect(() => {

        if (activeTab !== '') {
            
            changeActiveTab(passwordAndPrivacyTabs, activeTab, setActiveComponent, setActiveTabRef);
             
            setRecoveryCodeSentRef(false);
            setResetMFASuccessRef(false);
            setIsLoading(false);
        }
    }, [activeTab]);

    if (showUserDeletionConfirm || showRecoveryCodePasswordConfim || showChangePasswordPopup) {
        scrollToTop();
    }

    const isPageDownForMaintenance = (systemMaintenanceSettings: SystemMaintenanceSettings, maintenanceAlertPopup: any, maintenanceAlertPopupContainer: any, maintenanceAlertOverlay: any) => {

		const pageSetting = getPageSetting(systemMaintenanceSettings, activeTabRef);

        if (pageSetting.length > 0) {
			return (<MaintenanceAlert
                maintenanceAlertPopup={maintenanceAlertPopup}
                maintenanceAlertPopupContainer={maintenanceAlertPopupContainer}
                maintenanceAlertOverlay={maintenanceAlertOverlay}
            />)
		}

		return null;
	}

    

    return isLoading ? <Spinner visible={isLoading} /> : (
        <>
            <Grid container className={classes.root}>
                {isPasswordBeingSaved && (
                    <div className={`${classes.changePasswordOverlay} ${classes.overlay} ${classes.zIndexOverlay}`} >
                        <Spinner visible={true} />
                    </div>
                )}
                {showChangePasswordPopup && (
                    <Grid container className={classes.changePasswordPopupContainer}>
                        <ModalPopup paperClassName={classes.popup} overlayClassName={classes.overlay} zIndexOverlay={classes.zIndexOverlay} zIndexPopup={classes.zIndexPopup}>
                        <Grid item xs={12} className={classes.popupHeader} >
                            {t('privacy_and_security.header_change_password_popup')} 
                        </Grid>
                        <Grid item xs={12}  >
                            <span className={classes.popupContent}>
                            {parse(t('privacy_and_security.description_change_password_popup'))}
                            </span>
                        </Grid>
                        <Grid item xs={12} className={classes.popupButtonContainer} >
                            <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => handlePasswordResetLogout(logoutWithRedirect, clearProfile, webkitActive)}
                            >
                            {t('button.confirm')}
                            </Button>
                        </Grid>
                        </ModalPopup>
                    </Grid>
                    )
                }
                {showUserDeletionConfirm && (
                    <Grid container className={classes.passwordPopupContainer}>
                        <ModalPopup paperClassName={classes.passwordPopup}>
                            <PasswordPopup 
                                headerText={t('privacy_and_security.delete_account_overlay.header')}
                                bodyText={[t('privacy_and_security.delete_account_overlay.content1'), t('privacy_and_security.delete_account_overlay.content2'), t('privacy_and_security.delete_account_overlay.content3')]}
                                buttonText={t('button.delete_account_ok')}
                                onSubmit={(password: string) => onDeleteUser(password, accessToken, logoutWithRedirect, clearProfile, webkitActive, profile)} 
                                setShowPasswordConfirm={setShowUserDeletionConfirm} 
                            />
                        </ModalPopup>
                    </Grid>
                )
                }
                {showRecoveryCodePasswordConfim && (
                    <Grid container className={classes.passwordPopupContainer}>
                        <ModalPopup paperClassName={classes.passwordPopup}>
                            <PasswordPopup 
                                headerText={t('privacy_and_security.mfa_settings.send_recovery_code_overlay.header')}
                                bodyText={[t('privacy_and_security.mfa_settings.send_recovery_code_overlay.content')]}
                                buttonText={t('button.submit')}
                                onSubmit={(password: string) => onSendRecoveryCode(password, profile, accessToken, setRecoveryCodeSentRef, setShowRecoveryCodePasswordConfim)} 
                                setShowPasswordConfirm={setShowRecoveryCodePasswordConfim}
                            />
                        </ModalPopup>
                    </Grid>
                )
                }
                <Grid item xs={12}>
                    <Typography className={classes.header}>
                        {t('privacy_and_security.header')}
                    </Typography>
                    <Typography className={classes.description}>
                        {t('privacy_and_security.subHeader')}
                    </Typography>
                </Grid>
                <PasswordAndPrivacyContent 
                    passwordAndPrivacyTabs={passwordAndPrivacyTabs} 
                    activeTab={activeTab}
                    handleAccordianClick={handleAccordianClick}
                    setActiveTabRef={setActiveTabRef}
                    activeComponent={activeComponent}
                    isFederation={isConnectionFederation}
                />
                
                
                
            </Grid>

        </>
    )
}

const mapStateToProps = (state: AppState) => ({
    profile: state.profile,
    cultures: state.cultures
});

const mapDispatchToProps = (dispatch: any) => () => ({
    changePassword: (data: ChangePasswordFormData) => dispatch(changePasswordRequest(data)),
    clearProfile: () => dispatch(actionClearProfile())
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordAndPrivacy);