import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	buttonContainer: {
        marginTop: '34px',
        marginBottom: '5px'
    },
    button:{
        [theme.breakpoints.only('xs')]: {
            width: '100%',
		},
    },
    mainTitle: {
        paddingLeft: '0px !important',
        fontSize: '24px',
        fontWeight: 'bold',
        margin: '8px 0 24px'
    },
    textContainer: {
        marginBottom: '20px',
        color: '#686868'    
    },
    spinner:{
        position: 'relative',
        top: '191px',
        width: '450px',
        zIndex: 1
    },
    linkContainer: {
        color: '#000',
        textAlign: 'left',
        fontFamily: 'myriad-pro, Myriad Pro',
        fontSize: '18px',
        fontWeight: 600,
        cursor:'pointer',
        marginTop: '22px'
    },
    linkArrow: {
        width: '16px',
        height: '16px',
        marginRight: '6px',
        position: 'relative',
        top: '2px'
      },
    inputContainer: {
        marginTop: '9px'
    },
    blackText: {
      color: colors.webexuiBlack,
    }
}));