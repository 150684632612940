import { action } from 'typesafe-actions';
import { CulturesActionType, Culture } from './types';

export interface SetCultures {
	type: CulturesActionType.SET_CULTURES;
	payload: Culture[];
}

export interface GetCultures {
	type: CulturesActionType.GET_CULTURES;
}

export interface CultureChange {
	type: CulturesActionType.CULTURE_CHANGE,
	payload: string;
}

export const setCultures = (cultures: Culture[]): SetCultures =>
	action(CulturesActionType.SET_CULTURES, cultures)

export const getCultures = (): GetCultures =>
	action(CulturesActionType.GET_CULTURES)


export const cultureChange = (newCulture: string): CultureChange =>
	action(CulturesActionType.CULTURE_CHANGE, newCulture);

export type CultureActionType = SetCultures | CultureChange;