import React from 'react';
import { useStyles } from './Styles';
import settings from '../../config/settings';
import Grid from '@material-ui/core/Grid';
import { isMobileDevice } from '../../utils';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { urlJoin } from 'url-join-ts';
import PasswordAndPrivacyTabs from './PasswordAndPrivacyTabs';
import { PasswordAndPrivacyTab, PasswordAndPrivacyContentProps } from './Types';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import ClickOutsideWrapper from '../ClickOutsideWrapper';


const PasswordAndPrivacyContent: React.FC<PasswordAndPrivacyContentProps> = ({ passwordAndPrivacyTabs, activeTab, handleAccordianClick, setActiveTabRef, activeComponent, isFederation }) => {
    
    const classes = useStyles();
    const { t } = useTranslation();

    const getAccordianIcon = (tab: PasswordAndPrivacyTab) => {
        return (activeTab !== tab.id || activeTab === '') ?
            (<img src={urlJoin(settings.staticFiles.endpoint,'/images/system/ChevronDown.svg')} className={classes.iconStyle} alt="" />)
        :
            (<img src={urlJoin(settings.staticFiles.endpoint,'/images/system/ChevronDownRed.svg')} className={classes.iconStyle} alt="" />)
    }

    return isMobileDevice() ? 
            passwordAndPrivacyTabs.map((tab: PasswordAndPrivacyTab) => {
                return (<Accordion expanded={activeTab === tab.id ? true : false} elevation={0} key={tab.id} classes={{root: classes.MuiAccordionroot}}  TransitionProps={{ unmountOnExit: true }} >
                        <AccordionSummary
                            expandIcon={getAccordianIcon(tab)}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            onClick={() => handleAccordianClick(tab, activeTab, setActiveTabRef)}
                        >
                            <Typography><span  className={activeTab === tab.id ? classes.expanded : classes.collapsed}>{t(tab.header)}</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails className={isFederation ? classes.accordionDetails : ''}>
                            {React.createElement(tab.component, tab.parameters)}
                        </AccordionDetails>
                    </Accordion>
                    )
                }) as any
        
        :
        (
            <>
                <PasswordAndPrivacyTabs activeTab={activeTab} tabs={passwordAndPrivacyTabs} setActiveTab={setActiveTabRef} />
                <Grid item xs={12}>
                    <ClickOutsideWrapper classNamePopupContainer={classes.leaveConfirmContainer} classNamePopup={classes.leaveConfirmPopup}>
                        {
                            activeComponent.component && React.createElement(activeComponent.component, activeComponent.parameters)
                        }
                    </ClickOutsideWrapper>
                </Grid>
            </>
        )
}

export default PasswordAndPrivacyContent;