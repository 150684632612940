export interface TermsAndConditions {
    latest_version: string,
    url: string,
}

export interface PrivacyPolicy {
    latest_version: string,
    url: string,
}

export interface LegalInfo {
    currentCulture: string;
    privacy_policy: PrivacyPolicy,
    dip_terms_and_conditions: TermsAndConditions,
}

export enum LegalInfoActionTypes {
    SET_LEGALINFO = '@@translation/SET_LEGALINFO',
    GET_LEGALINFO = '@@translation/GET_LEGALINFO'
}