import { combineReducers } from 'redux';
import { loadingReducer } from './loading/reducers';
import { profileReducer } from './profile/reducers';
import { authenticationReducer } from "./authentication/reducers";
import { culturesReducer } from './cultures/reducers';
import { legalInfoReducer } from './legalInfo/reducers';
import { fieldsMetadataReducer } from './fieldsMetadata/reducers';
import { dashboardItemsReducer } from './dashboadItems/reducers';
import { reducer as formReducer, FormStateMap } from 'redux-form';
import { LoadingType } from './loading/types';
import { Profile } from './profile/types';
import { LegalInfo } from './legalInfo/types';
import { Authentication } from './authentication/types';
import { Cultures } from './cultures/types';
import { FieldsMetaData } from './fieldsMetadata/types';
import { DashboardItems } from './dashboadItems/types'

// Top-level state object
export interface AppState {
	form?: FormStateMap;
	loading: LoadingType;
	profile?: Profile;
	authentication?: Authentication;
	cultures: Cultures;
	legalInfo?: LegalInfo;
	fieldsMetadata?: FieldsMetaData;
	dashboardItems: DashboardItems
}

export const createRootReducer = () =>
	combineReducers({
		form: formReducer,
		profile: profileReducer,
		loading: loadingReducer,
		authentication: authenticationReducer,
		cultures: culturesReducer,
		legalInfo: legalInfoReducer,
		fieldsMetadata: fieldsMetadataReducer,
		dashboardItems: dashboardItemsReducer
	});
