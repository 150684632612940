import React from 'react';
import Grid from '@material-ui/core/Grid';
import ModalPopup from '../../components/Modal/ModalPopup';
import { FormEditConfirmProps } from './Types';
import { useStyles } from './Styles';
import { useTranslation } from 'react-i18next';
import { isIE } from 'mobile-device-detect';
import { urlJoin } from 'url-join-ts';
import settings from '../../config/settings';

const FormEditConfirm: React.FC<FormEditConfirmProps> = ({ showLeave, setShowConfirmation, onLeave, onGoToApplication, applicationName }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  
  return (
    <Grid container className={classes.confirmationPopupContainer}>
      <ModalPopup paperClassName={classes.confirmationPopup}>
        <Grid item xs={12} className={classes.confirmationHeader} >
          {t('user_profile.confirmation')}
        </Grid>
        <Grid item xs={12} className={classes.confirmationLink}>
          <span onClick={() => setShowConfirmation()} className={classes.linkText}>
            <>
              <img src={urlJoin(settings.staticFiles.endpoint,'/images/system/LinkArrow.svg')} className={classes.icon} alt="" /> {t('user_profile.go_back')}
            </>
          </span>
        </Grid>
        {applicationName && (
          <Grid item xs={12} className={classes.confirmationLink}>
            <span onClick={() => onGoToApplication()} className={classes.linkText}>
              <>
                <img src={urlJoin(settings.staticFiles.endpoint,'/images/system/LinkArrow.svg')} className={classes.icon} alt="" /> {t('email_verification.this_url_can_be_used_only_once.button', { application: applicationName })}
              </>
            </span>
          </Grid>
        )}
        {showLeave && !isIE && (
          <Grid item xs={12} className={classes.confirmationLink}>
            <span onClick={(e) => onLeave(e)} className={classes.linkText}>
              <>
                <img src={urlJoin(settings.staticFiles.endpoint,'/images/system/LinkArrow.svg')} className={classes.icon} alt="" /> {t('user_profile.leave_profile')}
              </>
            </span>
          </Grid>
        )}
      </ModalPopup>
    </Grid>
  );
};

export default FormEditConfirm;
