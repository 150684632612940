import { AuthenticationActions, Authentication } from "./types";
import { createReducer } from 'typesafe-actions';
import { AuthenticationActionType } from './actions';

export const initialState: Authentication = {
  authenticationCompleted: false,
  authenticationFailed: false
};

const reducer = createReducer<Authentication, AuthenticationActionType>(initialState, {
  [AuthenticationActions.AUTHENTICATION_COMPLETED]: (state, _) => {
    return { ...state, authenticationCompleted: true };
  },
  [AuthenticationActions.AUTHENTICATION_FAILED]: (state, _) => {
    return { ...state, authenticationFailed: true };
  }
});

export { reducer as authenticationReducer };
