import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';
import { urlJoin } from 'url-join-ts';
import settings from '../../config/settings';

export const useStyles = makeStyles(theme => ({
  gridMargin: {
    paddingTop: "5px",
    paddingBottom: "5px"
  },
  label: {
    fontWeight: 600,
    whiteSpace: 'nowrap'
  },
  text: {
    fontSize: '16px',
    lineHeight: '22px',
    color: colors.webexuiDarkGrayish,
    whiteSpace: "normal"
  },
  editText: {
    fontSize: '16px',
    lineHeight: '24px',
    color: colors.webexuiGrayText
  },
  element: {
    width: '100%',
    maxHeight: '48px',
    marginBottom: '5px',
    color: colors.webexuiBlack
  },
  select: {
    maxHeight: '36px',
    backgroundColor: '#f4f4f4',
    border: 0,
  },
  labelError: {
    fontFamily: 'myriad-pro, Myriad Pro',
    color: 'red',
    fontSize: '16px',
    lineHeight: '24px',
    [theme.breakpoints.up('md')]: {
      float: 'right'
    }
  },
  ErrorShadow: {
    boxShadow: `0 0 5px 0 ${colors.webexuiRed}`
  },
  iconStyle: {
    position: 'absolute',
    top: '0',
    right: '0',
    display: 'inline-block',
    color: '#c0c0c0',
    width: '13px',
    height: '13px',
    margin: '12px 10px',
    pointerEvents: 'none'
  },
  lock: {
    backgroundImage: `url(${urlJoin(settings.staticFiles.endpoint, '/images/system/lock.svg')})`,
    backgroundSize: "contain",
    backgroundRepeat: 'no-repeat',
    width: "11px",
    height: "14px",
    display: "table"
  },
  grayTextRoot: {
    '& $disabled': {
      color: '#686868'
    }
  },
  disabled: {},
  marginTop: {
    marginTop: '10px'
  }
}));