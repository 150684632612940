import { Country, State, LocationApiTimezone } from '../../api/locationApi';
import { AreaOfInterest, CompanyType } from '../../api/references';
import { LegalInfo } from './../legalInfo/types';

export interface Profile extends UserData {
    sub?: string;
    given_name?: string;
    family_name?: string;
    nickname?: string;
    name?: string;
    picture?: string;
    updated_at?: string;
    email_verified?: boolean;
    connections?: [];
    identities?: Identity[],
    identification?: Identification
    userdata_read?: boolean
}

export interface Identification {
    danfoss_identity_id: string,
    is_workforce: boolean,
    salesforce_identity_id: string
}

export interface Identity {
    connection: string;
    provider: string;
    metadata: MetaData;
    user_id: string;
}

interface MetaData {
    LockedProfileFields: string,
    CompanyName: string,
    IsFederation: boolean
}

export interface Language {
    id: string,
    text: string,
}

export interface Department {
    id: string,
    text: string,
}

export interface PhoneCallingCode {
    id: string,
    text: string,
}

export interface UserData {
    language?: Language,
    department?: Department,
    area_of_interest?: AreaOfInterest,
    company_type?: CompanyType,
    state?: State,
    country?: Country,
    timezone?: LocationApiTimezone,
    identities?: Identity[],
    legal_info?: LegalInfo,
    email?: string,
    first_name?: string,
    last_name?: string,
    city?: string,
    zip?: string,
    street?: string,
    phone_calling_code?: PhoneCallingCode,
    phone_number_cleaned?: string,
    company?: string,
    country_id?: string,
    state_id?: string,
    language_id?: string,
    department_id?: string,
    company_type_id?: string,
    areas_of_interest?: AreaOfInterest[]
}

export interface UserDataResponse extends UserData {
    phone_calling_code?: PhoneCallingCode;
}

export enum ProfileActionTypes {
    LOGIN = '@@profile/LOGIN',
    LOGOUT = '@@profile/LOGOUT',
    ENHANCE_USER = '@@profile/ENHANCE_USER',
    GET_USER_ERROR = '@@profile/GET_USER_ERROR',
    UPDATE_USER = '@@profile/UPDATE_USER',
    USER_REQUEST = '@@profile/USER_REQUEST',
    FETCH_LOGIN = '@@profile/FETCH_LOGIN',
    CHECK_USER = '@@profile/CHECK_USER',
    AUTHENTICATION_USER = '@@profile/AUTHENTICATION_USER',
    CHANGE_PASSWORD_REQUEST = '@@profile/CHANGE_PASSWORD_REQUEST',
    UPDATE_USER_ACTION =  '@@profile/UPDATE_USER_ACTION',
    RESET_PASSWORD =  '@@profile/RESET_PASSWORD',
    CLEAR_PROFILE =  '@@profile/CLEAR_PROFILE',
}
