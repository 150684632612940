import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import settings from '../../config/settings';
import Footer from '../Footer/Footer';
import { Application, getApplication} from '../../api/application';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import i18n from "i18next";
import { pushEvent } from '../../Services/AnalyticsService';
import { Container, Grid } from '@material-ui/core';
import { Spinner } from '@danfoss/webex-ui';
import Resend from './Resend';
import Information from '../Information/Information';
import { useSharedStyles } from '../../sharedStyles/Styles';
import LogoAndBackground from '../Header/LogoAndBackground';
import { useAuthentication } from '../../Providers/AuthenticationProvider';
import { getUserLanguage } from '../../utils/userLanguage';
import { cultureChange, CultureChange } from '../../store/cultures/actions';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import DOMPurify from 'dompurify';
import { isUserVerifed } from '../../api/user';
import { sendWelcomeEmail } from '../../api/email';

const getContent = (message:string, clientId: string, application: Application, goToApplication: Function, verifyApplicaiton: Function, t: Function) => {

    let content = null;

    switch(message) {
        case 'access expired.':
            content = <Resend
                clientId={clientId}
                headerText={parse(t('email_verification.access_expired.header'))}
                bodyText={parse(t('email_verification.access_expired.body'))}
                buttonText={parse(t('email_verification.access_expired.button', { application: (application.translations?.name || application.name || '') }))}
            />
            break;
        case 'resend-email':
            content = <Resend
                clientId={clientId}
                headerText={parse(t('email_verification.resend.header'))}
                bodyText={parse(t('email_verification.resend.body'))}
                buttonText={parse(t('email_verification.resend.button', { application: (application.translations?.name || application.name || '') }))}
            />
            break;
        case 'not-verified':
            content = <Resend
                clientId={clientId}
                headerText={parse(t('email_verification.email_not_verified.header'))}
                bodyText={parse(t('email_verification.email_not_verified.body'))}
                buttonText={parse(t('email_verification.email_not_verified.button'))}
                links={application.dip_app_type !== 'client_app' ? [
                    {
                        linkText:`${parse(t('email_verification.congratulations.button', { application: (application.translations?.name || application.name || '') }))}`,
                        onClick: goToApplication
                    }
                ] : []}
            />
            break;
        case 'user-action-required':
                content = <Information
                    handleButtonClick={() => verifyApplicaiton}
                    headerText={parse(t('email_verification.user_action.header'))}
                    bodyText={parse(t('email_verification.user_action.body'))}
                    buttonText={t('email_verification.user_action.button')}
                />
                break;
        case 'this url can be used only once':
            content = <Information
                handleButtonClick={() => goToApplication}
                headerText={parse(t('email_verification.this_url_can_be_used_only_once.header'))}
                bodyText={parse(t('email_verification.this_url_can_be_used_only_once.body'))}
                buttonText={parse(t('email_verification.this_url_can_be_used_only_once.button', { application: (application.translations?.name || application.name || '') }))}
                dip_app_type={application.dip_app_type}
            />
            break;
        case 'verification-email-sent':
            content = <Information
                handleButtonClick={() => goToApplication}
                headerText={parse(t('email_verification.verification_email_sent.header'))}
                bodyText={parse(t('email_verification.verification_email_sent.body'))}
                buttonText={parse(t('email_verification.verification_email_sent.button', { application: (application.translations?.name || application.name || '') }))}
                dip_app_type={application.dip_app_type}
            />
            break;
        default:
            content = <Information
                handleButtonClick={() => goToApplication}
                headerText={parse(t('email_verification.congratulations.header'))}
                bodyText={parse(t('email_verification.congratulations.body'))}
                buttonText={parse(t('email_verification.this_url_can_be_used_only_once.button', { application: (application.translations?.name || application.name || '') }))}
                clientAppText={parse(t('email_verification.congratulations.linktext_client_app', { application: (application.translations?.name || application.name || '') }))}
                dip_app_type={application.dip_app_type}
            />
            break;
    
    }

    return content;
}

const Verification: React.FC<Props> = ({profile, cultures, changeCulture}) => {
    const { t } = useTranslation();
    const { logoutWithRedirect } = useAuthentication();
    const sharedClasses = useSharedStyles();
    const [application, setApplication] = useState({} as Application);
    const [clientId, setClientId] = useState('');
    const [message, setMessage] = useState('');
    const [token, setToken] = useState('');
    const [loading, setIsLoading] = useState(true);
    const [verificationTicket, setVerificationTicet] = useState('');

    useEffect(() => {
        setIsLoading(true);
        //Get querystring parameters
        const parsed = queryString.parse(window.location.search);
        const returnFromLogout = parsed.returnFromLogout;
        const ticket = parsed.ticket as string;
        const email = parsed.email as string;
        const parsedToken = parsed.token as string;
        setToken(parsedToken);

        const lan = getUserLanguage(profile, cultures.cultures);

        // Do a logout of the user to invalidate unfinished redirect from rule. With seamless SSO eneabled the user will continue with any unfinished
        // when he goes to the application and will not be asked to loin again 
        if (!(returnFromLogout && returnFromLogout === 'true' )) {
            sessionStorage.setItem("redirectAfterLogout", `${window.location.pathname}${window.location.search}&returnFromLogout=true${window.location.hash}`);
            return logoutWithRedirect();
        }

        changeCulture(lan);

        const sanatizedCodeOrMessage = (DOMPurify.sanitize((parsed.code || parsed.message) as string)).toLowerCase()
            
        setMessage(sanatizedCodeOrMessage);

        // When the user action button is clicked, it is ok for us to send a 
        // welcome email to the user.
        if (sanatizedCodeOrMessage === 'success') {
            sendWelcomeMail();
        }

        const getData = async () => {

            // take clientId if part of querystring otherwise default to My Profile clientId
            // @ts-ignore
            const cId = DOMPurify.sanitize(parsed.clientId) || settings.auth0.clientId;
            setClientId(cId as string);

            const app: Application = await getApplication(cId as string, lan)
            setApplication(app); 
            
            // If we have a verification ticket as a part of the querystring, then we need to check if
            // the user is already verified, because then we need to display a warning to the user, that
            // an email account can only be verified once. 
            if (ticket) {
                
                setMessage("user-action-required");
                setVerificationTicet(ticket);

                const isUserAlreadyVerified = async () => {
            
                    // Make a call to the API to ensure, that the user is not already verified 
                    await isUserVerifed(email, parsedToken).then(response => {

                        // If already verified display proper message
                        if (response.is_email_verified) {
                            setMessage("this url can be used only once") 
                        }
                    }).catch(() => {
                        // If an error occurs, we don't want the system to display any errors, because that could 
                        // be used to see if an email is used in DIP
                    });

                    setIsLoading(false);
                }

                isUserAlreadyVerified();

            } else {
                setIsLoading(false);
            }
        }

        getData();

        // Push an event that a user entered the verification page
        pushEvent(
            {
                'event': 'virtualPageview',
                'page': `${(window as any).location.hostname}/verification/${message}`
            }
        );
        
    }, []);
    
    const goToApplication = () => {

        const currentUrl = new URL(document.location.href);
        const applicationUrl = new URL (!!application.url ? application.url : application.allowed_callback);
        // Added because of sonarCloud codesmell
        const andOrQuestionmark = applicationUrl.search ? '&' : '?';
        const uiLocales = currentUrl.hostname === applicationUrl.hostname ? `${andOrQuestionmark}ui_locales=${i18n.language}` : '';

        document.location.href = `${application.url || application.allowed_callback}${uiLocales}`;
    }

    const verifyEmail = () => {
        // We have to save the token in session storage because the token is not 
        // present when we need it on the success screen
        sessionStorage.setItem("welcomeToken", token);

        // Call the verify endpoint send to us in the the ticket
        document.location.href = `${verificationTicket}?ui_locales=${cultures.currentCulture}`;
    }

    const sendWelcomeMail = () => {
        const parsed = queryString.parse(window.location.search);
        const email = parsed.email as string;
        
        sendWelcomeEmail(email, getUserLanguage(profile, cultures.cultures), sessionStorage.getItem("welcomeToken") || '');

        // The token value is now used, so lets delete it again
        sessionStorage.removeItem("welcomeToken");
    }

    return loading ? (<Spinner visible={loading}/>) : (
        <React.Fragment>
                <LogoAndBackground classes={sharedClasses} />
                <Container className={sharedClasses.containerRoot}>
                    <Container maxWidth="md" className={`${sharedClasses.outerContainer} ${sharedClasses.relativeContainer}`}>
                        <Grid container className={sharedClasses.contentContainerRoot}>
                            <Grid item xs={12}>
                                { getContent(message, clientId, application, goToApplication, verifyEmail, t) }
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
                <Footer contentClass={sharedClasses.footerContent} />
        </React.Fragment>
    );
}

const mapStateToProps = (state: AppState)  => ({
    cultures: state.cultures,
    profile: state.profile
  });
  
const mapDispatchToProps = (dispatch: Dispatch<CultureChange>) => ({
    changeCulture: (newCulture: string) => dispatch(cultureChange(newCulture))
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Verification);