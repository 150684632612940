import { makeStyles } from '@material-ui/core';
import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';

export const useStyles = makeStyles(theme => ({
	languageContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
      },
      menuIcon: {
        marginRight: '10px',
        height: '22px',
        width: '22px'
      },
      subMenuIcon: {
        marginRight: '10px',
        height: '16px',
        width: '16px'
      },
      paper: {
        marginTop: '5px',
        borderRadius: '0px 0px 5px 5px',
        [theme.breakpoints.down('md')]: {
          marginTop: '2px',
        },
        [theme.breakpoints.down('sm')]: {
          marginTop: '2px',
          width: '100%',
          borderRadius: 0,
          left: '0 !important',
          maxWidth: '100%',
          padding: '19px 0'
        },
      },
      menuItem: {
        fontSize: '16px',
        lineHeight: '30px',
        minHeight: '32px',
        paddingTop: '0px',
        paddingBottom: '0px',
        display: 'block',
        '&:hover': {
          color: colors.webexuiRed
        }
      },
      menuItemLink: {
        textDecoration: 'none',
        fontWeight: 700,
        lineHeight: '24px',
        marginLeft: '20px',
        color: colors.webexuiBlack,
        '&:hover': {
          color: colors.webexuiRed
        },
        '&::before': {
          content: '"\\203A\\0000A0"',
          position: 'absolute',
          fontSize: '26px',
          top: '2px',
          left: '21px'
        }
      },
      menuItemLast: {
        marginTop: '15px',
      },
      menuItemName: {
        marginLeft: '21px',
        color: colors.webexuiBlack,
        lineHeight: '20px',
        fontWeight: "normal"
      },
      menuItemEmail: {
        margin: '0 0 10px 21px',
        color: colors.webexuiBlack,
        marginBottom: '16px',
        fontWeight: "normal"
      },
      modal: {
        marginTop: '100px',
        "& > div":{
          top: '74px !important',
          [theme.breakpoints.down('md')]: {
            top: '53px !important' 
          },
          [theme.breakpoints.only('xs')]: {
            top: '42px !important' 
          }
        }
      },
      cultureNameSmallScreen: {
        [theme.breakpoints.up('sm')]: {
          display: 'none'
        },
        marginRight: '10px'
      },
      cultureNameBigScreen: {
        [theme.breakpoints.down('xs')]: {
          display: 'none'
        }
      }
      
}));