import { takeLatest, all, put, fork, select } from 'redux-saga/effects';
import { cultureChange, setCultures } from './actions';
import { getLanguages, Languages } from '../../api/references';
import { CulturesActionType } from './types';
import { getUserLanguage } from '../../utils/userLanguage';
import { AppState } from "../";

export function* loadCultures() {
    // We need to load cultures/languages in state before moving on
    const languages:Languages = yield getLanguages();
    
    yield put(setCultures(languages.items));

    const getState = (state: AppState) => state;
    const { profile } = yield select(getState);

    const currentCulture = getUserLanguage(profile, languages.items);
    yield put(cultureChange(currentCulture));
}

export function* watchLoadCultures() {
    yield takeLatest(CulturesActionType.GET_CULTURES, loadCultures);
}

export default function* culturesSagas() {
    yield all([
        fork(watchLoadCultures)
    ]);
}