import React from 'react';
import { Field } from 'redux-form';
import { LabeledTextbox } from '../../components/RenderFields/RenderFields';
import { InputAdornment } from '@material-ui/core';

export const renderUsername = (classes: any) => {
    return (
        <Field
            name="username"
            component={LabeledTextbox}
            className={classes.hidden}
            autocomplete="username"
            type="hidden"
            />
    )
}

export const renderCurrentPassword = (value: string, showCurrentPassword: boolean, classesObject: any, handleFieldChange: Function, setShowCurrentPassword: Function, setShowCurrentPasswordClass: Function,  t: any) => {
  return (<Field
        name="currentPassword"
        label={t('privacy_and_security.currentPassword.placeholder')}
        placeholder={t('privacy_and_security.currentPassword.placeholder')}
        component={LabeledTextbox}
        autoComplete="currentPassword"
        type={showCurrentPassword ? "text" : "password"}
        onChange={({ target }: {target: HTMLInputElement}) => { handleFieldChange('currentPassword', target.value); }}
        props={{showsubmitfailed: true}}
        required={true}
        defaultValue={value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" >
              <i
                className={classesObject.showCurrentPasswordClass}
                onClick={() => {setShowCurrentPasswordClass(!showCurrentPassword ? classesObject.sharedClasses.eyeActive : classesObject.sharedClasses.eyeInactive); setShowCurrentPassword(!showCurrentPassword);}}
                onMouseOver= {() => setShowCurrentPasswordClass(classesObject.sharedClasses.eyeHover)}
                onMouseOut= {() => setShowCurrentPasswordClass(showCurrentPassword ? classesObject.sharedClasses.eyeActive : classesObject.sharedClasses.eyeInactive)}
              />
            </InputAdornment>
          ),
          classes: {
              root: classesObject.classes.cssOutlinedInput,
              focused: classesObject.classes.cssFocused,
              input: classesObject.classes.blackText
          }
        }}
      />);
}

export const renderNewPassword = (value: string, showPassword: boolean, classesObject: any, hidePopup: Function, handleFieldChange: Function, toggleShowPassword: Function, setShowPasswordClass: Function,  t: any) => {
    return (<Field
        name="newPassword"
        label={t('privacy_and_security.newPassword.placeholder')}
        placeholder={t('privacy_and_security.newPassword.placeholder')}
        component={LabeledTextbox}
        autoComplete="newPassword"
        type={showPassword ? "text" : "password"}
        onChange={({ target }: {target: HTMLInputElement}) => { handleFieldChange('newPassword', target.value); }}
        onBlur={() => hidePopup(false)}
        required={true}
        defaultValue={value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" >
              <i
                className={classesObject.showPasswordClass}
                onClick={() => {setShowPasswordClass(!showPassword ? classesObject.sharedClasses.eyeActive : classesObject.sharedClasses.eyeInactive); toggleShowPassword(!showPassword);}}
                onMouseOver= {() => setShowPasswordClass(classesObject.sharedClasses.eyeHover)}
                onMouseOut= {() => setShowPasswordClass(showPassword ? classesObject.sharedClasses.eyeActive : classesObject.sharedClasses.eyeInactive)}
              />
            </InputAdornment>
          ),
          classes: {
              root: classesObject.classes.cssOutlinedInput,
              focused: classesObject.classes.cssFocused,
              input: classesObject.classes.blackText
          }
        }}
      />);
}

export const renderRetypeNewPassword = (value: string, showPassword: boolean, classes: any, handleFieldChange: Function, t: any) => {
    return (<Field
        name="retypeNewPassword"
        label={t('privacy_and_security.retypeNewPassword.placeholder')}
        placeholder={t('privacy_and_security.retypeNewPassword.placeholder')}
        component={LabeledTextbox}
        autoComplete="newPassword"
        type={showPassword ? "text" : "password"}
        required={true}
        defaultValue={value}
        onChange={({ target }: {target: HTMLInputElement}) => { handleFieldChange('retypeNewPassword', target.value); }}
        InputProps={{
          classes: {
              input: classes.blackText
          }
        }}
      />);
}

