// Generic class accepting T as a Type
export class Defer<T> {
    scope: string;
    id: number;
    promise: Promise<T>;
    handler: string;
    method: string;
    data?: any;
    constructor(scope: string, handler: string, method: string, id: number, data?: any) {
        this.scope = scope;
        this.handler = handler;
        this.method = method;
        this.id = id;
        this.data = data;
        this.promise = new Promise<T>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
            this.cancel = () => reject("cancelled");
        });
    }
    resolve: (value: T | PromiseLike<T>) => void = () => { return null; };
    reject: (reason?: any) => void = () => { return null; };
    cancel: () => void = () => { return null; };
}
