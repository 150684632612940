export interface FieldsMetaData {
    fields: FieldMetaData[],
    fieldsObject: any
}

export interface FieldMetaData {
    name: string,
    validation?: {
        max_len?: number,
        regex?: string,
        standard?: string
    },
    idp_data_path: string,
    data_type: string,
    display_name: string,
    display_when_field_has_value?: string,
    is_data_required_if_dependend_field_has_value: boolean,
    required: boolean
}

export enum FieldsMetadataActionType {
    SET_FIELDSMETADATA = '@@fieldsMetadata/SET_FIELDSMETADATA',
    GET_FIELDSMETADATA = '@@fieldsMetadata/GET_FIELDSMETADATA',
    SET_FIELDSMETADATA_SUCCESS = '@@fieldsMetadata/SET_FIELDSMETADATA_SUCCESS'
}
