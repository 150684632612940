import React, { useState, useEffect, useContext } from "react";
import createAuthenticationClient from './Helpers/AuthenticationClient';
import { Auth0Provider, Auth0Context } from './Auth0Provider';
import { useSpinner } from "./SpinnerProvider";
const DEFAULT_REDIRECT_CALLBACK = (appstate) => { return; };
const DEFAULT_REDIRECT_PERSIST_STATE = () => { return; };
const DEFAULT_ONAUTHENTICATED = (accessToken, user) => { return; };
const DEFAULT_ONREFRESHERROR = (error) => { return; };

export const AuthenticationContext = React.createContext();
export const useAuthentication = () => useContext(AuthenticationContext);
export let authenticationClient;

const isOk = (isAuth, requireSignin, setLoading) => {
  if (isAuth || !requireSignin)
    setLoading(false);
}

export const AuthenticationProvider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  onRedirectPersistState = DEFAULT_REDIRECT_PERSIST_STATE,
  onAuthenticated = DEFAULT_ONAUTHENTICATED,
  onRefreshError = DEFAULT_ONREFRESHERROR,
  logout_uri,
  automaticSso,
  requireSignin,
  ...initOptions
}) => {
  const [isWebKitBridgeAvailable, setIsWebKitBridgeAvailable] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [initilazed, setInitialized] = useState(false);

  const { setSpinnerVisibility } = useSpinner();

  useEffect(() => {
    const initAuthentication = async () => {
      setSpinnerVisibility(true);
      authenticationClient = await createAuthenticationClient();

      const webkitAvail = await authenticationClient.isWebKitBridgeAvailable();
      setIsWebKitBridgeAvailable(webkitAvail);

      if (webkitAvail) {
        let isAuth = await authenticationClient.isAuthenticated();
        setIsAuthenticated(isAuth);

        if (isAuth) {
          const usr = await authenticationClient.getUser();
          const accessToken = await authenticationClient.getTokenSilently();
          setUser(usr);
          onAuthenticated(accessToken, usr);
        }

        isOk(isAuth, requireSignin, setLoading);
        
      }
      else 
        setLoading(false);

      setInitialized(true);  
      setSpinnerVisibility(false);
    };
    initAuthentication();
    // eslint-disable-next-line
  }, []);

  if (loading)
    return null;

  if (isWebKitBridgeAvailable) {
  return (
    <AuthenticationContext.Provider
      value={{
        initilazed,
        isAuthenticated,
        user,
        loading,
        error: false,
        webkitActive: isWebKitBridgeAvailable,
        getIdTokenClaims: (...p) => authenticationClient.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => { authenticationClient.loginWithRedirect({ appState: onRedirectPersistState(), ...p }) },
        getTokenSilently: (...p) => authenticationClient.getTokenSilently(...p),
        automaticSso: (...p) => authenticationClient.getTokenSilently(...p),
        automaticSsoSilent: (...p) => authenticationClient.getTokenSilently(...p),
        logout: (...p) => authenticationClient.logout(...p),
        logoutWithRedirect: (...p) => authenticationClient.logout({ returnTo: logout_uri, ...p })
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )}
  else {
    return (
			<Auth0Provider 
        automaticSso={automaticSso}
        requireSignin={requireSignin}
        onAuthenticated={onAuthenticated}
        onRedirectCallback={onRedirectCallback}
        onRedirectPersistState={onRedirectPersistState}
        onRefreshError={onRefreshError}
        logout_uri={logout_uri}
        {...initOptions}
      >
        <Auth0Context.Consumer>
          {({ initialized: auth0Initialized, 
              isAuthenticated: auth0IsAuthenticated, 
              user: auth0User, 
              loading: auth0Loading, 
              error: auth0Error, 
              handleRedirectCallback: auth0HandleRedirectCallback, 
              getIdTokenClaims: auth0GetIdTokenClaims, 
              loginWithRedirect: auth0LoginWithRedirect, 
              getTokenSilently: auth0GetTokenSilently, 
              automaticSso: auth0AutomaticSso, 
              automaticSsoSilent: auth0AutomaticSsoSilent, 
              logout: auth0Logout, 
              logoutWithRedirect: auth0LogoutWithRedirect }) => (
            <AuthenticationContext.Provider
              value={{
                initialized: auth0Initialized,
                isAuthenticated: auth0IsAuthenticated,
                user: auth0User,
                loading: auth0Loading,
                error: auth0Error,
                webkitActive: isWebKitBridgeAvailable,
                handleRedirectCallback: auth0HandleRedirectCallback,
                getIdTokenClaims: auth0GetIdTokenClaims,
                loginWithRedirect: auth0LoginWithRedirect,
                getTokenSilently: auth0GetTokenSilently,
                automaticSso: auth0AutomaticSso,
                automaticSsoSilent: auth0AutomaticSsoSilent,
                logout: auth0Logout,
                logoutWithRedirect: auth0LogoutWithRedirect
              }}
            >
              {children}
            </AuthenticationContext.Provider>
          )}
        </Auth0Context.Consumer>
      </Auth0Provider>  
    );
  }
};
