import React from 'react';
import { Field } from 'redux-form';
import { Profile } from '../../store/profile/types';
import i18n from "i18next";

import { LabeledTextbox, LabeledSelectbox, LabeledDisabledTextboxWithIcon } from '../../components/RenderFields/RenderFields';
import { State, CallingCode, Timezone } from '../../api/locationApi';
import { Department, Language, CompanyType, AreaOfInterest } from '../../api/references';
import { ToolTip } from '../../components/ToolTip';
import { FieldMetaData } from '../../store/fieldsMetadata/types';
import e from 'express';


export const renderEmailUsername = (profile: Profile | undefined, t: any) => {
    return (<Field
        id="username"
        name="username"
        label={t('user_profile.username.label')}
        value={profile ? profile.email : ''}
        defaultValue={profile ? profile.email : ''}
        component={LabeledDisabledTextboxWithIcon}
        disabled={true}
        inputProps={{labelGrid: 12}}
    />);
}

export const renderFirstName = (fieldMetadata: FieldMetaData | undefined, classes:any, firstName: string | undefined, handleChange: Function, t: any, editAllowed: boolean) => {
    return (<Field
        id="first_name"
        name="first_name"
        label={t('user_profile.first_name.label')}
        value={firstName ?? ''}
        defaultValue={firstName ?? ''}
        onChange={({ target }: { target: HTMLInputElement }) => {
            handleChange('first_name', target.value);
        }}
        required={(fieldMetadata && fieldMetadata.required) || false}
        placeholder={t('user_profile.first_name.placeholder')}
        component={editAllowed ? LabeledDisabledTextboxWithIcon : LabeledTextbox}
        disabled={editAllowed}
        inputProps={{maxLength: fieldMetadata?.validation?.max_len || 60}}
        InputProps={{
            classes: {
              input: classes.blackText
        }}}
    />);
}

export const renderLastName = (fieldMetadata: FieldMetaData | undefined, classes:any, lastName: string | undefined, handleChange: Function, t: any, editAllowed: boolean) => {
    return (<Field
        id="last_name"
        name="last_name"
        label={t('user_profile.last_name.label')}
        value={lastName ?? ''}
        defaultValue={lastName ?? ''}
        onChange={({ target }: { target: HTMLInputElement }) => {
            handleChange('last_name', target.value);
        }}
        required={(fieldMetadata && fieldMetadata.required) || false}
        placeholder={t('user_profile.last_name.placeholder')}
        component={editAllowed ? LabeledDisabledTextboxWithIcon : LabeledTextbox}
        disabled={editAllowed}
        inputProps={{maxLength: fieldMetadata?.validation?.max_len || 60}}
        InputProps={{
            classes: {
              input: classes.blackText
        }}}
    />);
}

export const renderCallingCode = (fieldMetadata: FieldMetaData | undefined, callingCodeId: string | undefined, profile: Profile | undefined, callingCodes: CallingCode[], handleChange: Function, t: any, editAllowed: boolean) => {
    return (<Field
        id="phone_calling_code"
        name="phone_calling_code"
        label={t('user_profile.calling_code.label')}
        value={callingCodeId}
        defaultValue={callingCodeId}
        onChange={(event: any) => {
            handleChange(event);
        }}
        parse={(value: any) => {
            return value.value;
        }}
        placeholder={t('user_profile.calling_code.placeholder')}
        component={LabeledSelectbox}
        options={callingCodes}
        disabled={editAllowed}
        validate={hasDependendAttribute}
        required={(profile?.phone_number_cleaned ?? '') !== '' || (fieldMetadata && fieldMetadata.required) || false}
        inputProps={{usetooltip:"true"}}
    />);
}

const hasDependendAttribute = (object: any, profile: Profile | undefined, props: any, name: string) => {
    
    let valueAsString = object;

    if (!profile) return null;

    if (object && typeof(object) !== 'string') {
        valueAsString = object.value;
    }
    
    // We need a small hack here, because of the introduction of phone_number_cleaned and phone_calling_code
    let fieldName = name;
    
    if (name === "phone_number_cleaned") {
        fieldName = "phone_number"; 
    }

    if (name === "phone_calling_code") {
        fieldName = "calling_code" 
    }


    const fieldMetadata:FieldMetaData = (props?.initialValues?.fieldsMetadata?.fields || []).find((field: FieldMetaData) => field.name === fieldName)
    const fieldsDependsOnName = fieldMetadata?.display_when_field_has_value;
    const isFieldValueRequired = fieldMetadata?.is_data_required_if_dependend_field_has_value;
    
    // Return if the field is not required to have a value
    if (!isFieldValueRequired) return null;

    // We need a small hack here, because of the introduction of phone_number_cleaned and phone_calling_code
    let profileFieldName = fieldsDependsOnName;
    
    if (fieldsDependsOnName === "phone_number") {
        profileFieldName = "phone_number_cleaned" 
    }

    if (fieldsDependsOnName === "calling_code") {
        profileFieldName = "phone_calling_code" 
    }

    // @ts-ignore
    let profileValue = (profileFieldName && profile[profileFieldName]) ?? '' ;
    
    // If dependend field has value and the field itself not, then display a warning
    if (!valueAsString && profileValue !== '') {
        return i18n.t(fieldMetadata.data_type === 'text' ? 'validation_messages.required' : 'validation_messages.required_select', { property_name: i18n.t(`user_profile.${fieldName}.label`)});
    }

    // Use the attributes regular expression, if any, to validate the value 
    if (fieldMetadata?.validation?.regex) {
        const reg = new RegExp(fieldMetadata.validation.regex);
        if (valueAsString && !reg.test(valueAsString)) {
            return [i18n.t('validation_messages.incorrect_character_used'), <ToolTip key="test" toolTip={i18n.t('tooltip_messages.phone_number_incorrect_character_used')}/>];
        }
    }

    return undefined;
}

export const renderPhoneNumber = (fieldMetadata: FieldMetaData | undefined, classes:any, phoneNumber: string |undefined, profile: Profile | undefined, handleChange: Function, t: any, editAllowed: boolean) => {
    return (<Field
        id="phone_number_cleaned"
        name="phone_number_cleaned"
        label={t('user_profile.phone_number.label')}
        value={phoneNumber ?? ''}
        defaultValue={phoneNumber ?? ''}
        onChange={({ target }: { target: HTMLInputElement }) => {
            handleChange('phone_number_cleaned', target.value);
        }}
        placeholder={t('user_profile.phone_number.placeholder')}
        component={editAllowed ? LabeledDisabledTextboxWithIcon : LabeledTextbox}
        disabled={editAllowed}
        inputProps={{maxLength: fieldMetadata?.validation?.max_len || 24,  usetooltip:"true", inputMode:"tel"}}
        InputProps={{
            classes: {
              input: classes.blackText
        }}}
        validate={hasDependendAttribute}
        required={(profile?.phone_calling_code?.id ?? '') !== '' || fieldMetadata?.required || false}
    />);
}

export const renderStreet = (fieldMetadata: FieldMetaData | undefined, classes:any, street: string | undefined, handleChange: Function, t: any, editAllowed: boolean) => {
    return (<Field
        id="street"
        name="street"
        label={t('user_profile.street.label')}
        value={street ?? ''}
        defaultValue={street ?? ''}
        onChange={({ target }: { target: HTMLInputElement }) => {
            handleChange('street', target.value);
        }}
        placeholder={t('user_profile.street.placeholder')}
        component={editAllowed ? LabeledDisabledTextboxWithIcon : LabeledTextbox}
        disabled={editAllowed}
        inputProps={{maxLength: fieldMetadata?.validation?.max_len || 100, usetooltip:"true"}}
        InputProps={{
            classes: {
              input: classes.blackText
        }}}
        required={(fieldMetadata && fieldMetadata.required) || false}
    />);
}

export const renderZip = (fieldMetadata: FieldMetaData | undefined, classes:any, zip: string | undefined, handleChange: Function, t: any, editAllowed: boolean) => {
    return (<Field
        id="zip"
        name="zip"
        label={t('user_profile.zip.label')}
        value={zip ?? ''}
        defaultValue={zip ?? ''}
        onChange={({ target }: { target: HTMLInputElement }) => {
            handleChange('zip', target.value);
        }}
        placeholder={t('user_profile.zip.placeholder')}
        component={editAllowed ? LabeledDisabledTextboxWithIcon : LabeledTextbox}
        disabled={editAllowed}
        inputProps={{maxLength: fieldMetadata?.validation?.max_len || 60, usetooltip:"true"}}
        InputProps={{
            classes: {
              input: classes.blackText
        }}}
        required={(fieldMetadata && fieldMetadata.required) || false}
    />);
}

export const renderCity = (fieldMetadata: FieldMetaData | undefined, classes:any, city: string | undefined, handleChange: Function, t: any, editAllowed: boolean) => {
    return (<Field
        id="city"
        name="city"
        label={t('user_profile.city.label')}
        value={city ?? ''}
        defaultValue={city ?? ''}
        onChange={({ target }: { target: HTMLInputElement }) => {
            handleChange('city', target.value);
        }}
        placeholder={t('user_profile.city.placeholder')}
        component={editAllowed ? LabeledDisabledTextboxWithIcon : LabeledTextbox}
        disabled={editAllowed}
        inputProps={{maxLength: fieldMetadata?.validation?.max_len || 60, usetooltip:"true"}}
        InputProps={{
            classes: {
              input: classes.blackText
        }}}
        required={(fieldMetadata && fieldMetadata.required) || false}
    />);
}

export const renderState = (fieldMetadata: FieldMetaData | undefined, states: State[] | undefined, stateId: string | undefined, handleChange: Function, t: any, editAllowed: boolean) => {
    
    const newStates = states?.map((state:State) => {
        return {id: state.id.toUpperCase(), text: state.text}
    })
    
    return (<Field
        id="state"
        name="state"
        label={t('user_profile.state.label')}
        value={stateId}
        defaultValue={stateId}
        onChange={(event: any) => {
            handleChange(event);
        }}
        parse={(value: any) => {
            return value.value;
        }}
        placeholder={t('user_profile.state.placeholder')}
        component={LabeledSelectbox}
        options={newStates}
        disabled={editAllowed}
        inputProps={{usetooltip:"true"}}
        required={(fieldMetadata && fieldMetadata.required) || false}
    />);
}

export const renderTimezone = (fieldMetadata: FieldMetaData | undefined, timezones: Timezone[] | undefined, timezoneId: string | undefined, handleChange: Function, t: any) => {
    return (<Field
        id="timezone"
        name="timezone"
        label={t('user_profile.timezone.label')}
        value={timezoneId ?? ''}
        defaultValue={timezoneId ?? ''}
        onChange={(event: any) => {
            handleChange(event);
        }}
        parse={(value: any) => {
            return value.value;
        }}
        placeholder={'test'}
        component={LabeledSelectbox}
        options={timezones}
        inputProps={{usetooltip:"true"}}
        required={(fieldMetadata && fieldMetadata.required) || false}
    />);
}

export const renderCompany = (fieldMetadata: FieldMetaData | undefined, classes:any, company: string | undefined, handleChange: Function, t: any, editAllowed: boolean) => {
    return (<Field
        id="company"
        name="company"
        label={t('user_profile.company.label')}
        value={company ?? ''}
        defaultValue={company ?? ''}
        onChange={({ target }: { target: HTMLInputElement }) => {
            handleChange('company', target.value);
        }}
        placeholder={t('user_profile.company.placeholder')}
        component={editAllowed ? LabeledDisabledTextboxWithIcon : LabeledTextbox}
        disabled={editAllowed}
        inputProps={{maxLength: fieldMetadata?.validation?.max_len || 60, usetooltip:"true"}}
        InputProps={{
            classes: {
              input: classes.blackText
        }}}
        required={(fieldMetadata && fieldMetadata.required) || false}
    />);
}

export const renderCompanyType = (fieldMetadata: FieldMetaData | undefined, companyTypeId: string | undefined, companyTypes: CompanyType[], handleChange: Function, t: any, editAllowed: boolean) => {
    return (<Field
        id="company_type"
        name="company_type"
        label={t('user_profile.company_type.label')}
        value={companyTypeId ?? ''}
        defaultValue={companyTypeId ?? ''}
        onChange={(event: any) => {
            handleChange(event);
        }}
        parse={(value: any) => {
            return value.value;
        }}
        placeholder={t('user_profile.company_type.placeholder')}
        component={LabeledSelectbox}
        options={companyTypes}
        disabled={editAllowed}
        inputProps={{usetooltip:"true"}}
        required={(fieldMetadata && fieldMetadata.required) || false}
    />);
}

export const renderAreaOfInterest = (fieldMetadata: FieldMetaData | undefined, areaOfInterestIds: string[] | undefined, areasOfInterest: AreaOfInterest[], handleChange: Function, t: any, editAllowed: boolean) => {
    return (<Field
        id="areas_of_interest"
        name="areas_of_interest"
        label={t('user_profile.areas_of_interest.label')}
        value={areaOfInterestIds ?? ''}
        defaultValue={areaOfInterestIds ?? []}
        onChange={(event: any) => {
            handleChange(event);
        }}
        parse={(value: any) => {
            return value.value;
        }}
        placeholder={t('user_profile.areas_of_interest.placeholder')}
        component={LabeledSelectbox}
        options={areasOfInterest}
        disabled={editAllowed}
        inputProps={{usetooltip:"true", isMultiSelect: true}}
        required={(fieldMetadata && fieldMetadata.required) || false}
    />);
}

export const renderDepartment = (fieldMetadata: FieldMetaData | undefined, departmentId: string | undefined, departments: Department[], handleChange: Function, t: any, editAllowed: boolean) => {
    return (<Field
        id="department"
        name="department"
        label={t('user_profile.department.label')}
        value={departmentId ?? ''}
        defaultValue={departmentId ?? ''}
        onChange={(event: any) => {
            handleChange(event);
        }}
        parse={(value: any) => {
            return value.value;
        }}
        placeholder={t('user_profile.department.placeholder')}
        component={LabeledSelectbox}
        options={departments}
        disabled={editAllowed}
        inputProps={{usetooltip:"true"}}
        required={(fieldMetadata && fieldMetadata.required) || false}
    />);
}

export const renderLanguage = (fieldMetadata: FieldMetaData | undefined, languageId: string | undefined, languages: Language[], handleChange: Function, t: any) => {
    return (<Field
        id="language"
        name="language"
        label={t('user_profile.language.label')}
        value={languageId}
        defaultValue={languageId}
        onChange={(event: any) => {
            handleChange(event);
        }}
        parse={(value: any) => {
            return value.value;
        }}
        placeholder={t('user_profile.language.placeholder')}
        component={LabeledSelectbox}
        options={languages}
        inputProps={{usetooltip:"true"}}
        required={(fieldMetadata && fieldMetadata.required) || false}
    />);
}



