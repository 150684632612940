import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	infoIconContainer: {
        verticalAlign: 'middle',
        lineHeight: '12px',
        display: 'inline-table',
        marginLeft: '3px'
    },
    infoIcon: {
        width: '12px',
        height: '15px'
    }
}));