/* eslint-disable react/jsx-no-bind,jsx-a11y/label-has-for,class-methods-use-this,prefer-rest-params */
import React, { useState, useEffect } from 'react';
import { Field } from 'redux-form';
import { LabeledSelectbox } from '../../components/RenderFields/RenderFields';
import Grid from '@material-ui/core/Grid';
import { Timezone } from '../../api/locationApi';
import { AreaOfInterest, Language } from '../../api/references';
import * as fieldRenderer from './FieldRenderer';
import { getUserIdentity } from '../../store/profile/helpers';
import { useStyles } from './Styles';
import { ProfileFieldsProps } from './Types';
import { useTranslation } from 'react-i18next';
import { urlJoin } from 'url-join-ts';
import settings from '../../config/settings';
import { FieldMetaData } from '../../store/fieldsMetadata/types';

const ProfileFields: React.FC<ProfileFieldsProps> = ({ handleFieldChange, handleSelectChange, handleMultiSelectChange, handleCountryChange, profile, attributeValues, fieldsMetadata }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [countries, setCountries] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [areasOfInterest, setAreasOfInterest] = useState([]);
    const [companyTypes, setCompanyTypes] = useState([]);
    const [companyName, setCompanyName] = useState('');
    const [isFederation, setIsFederation] = useState(false);
    const [languages, setLanguages] = useState([] as Language[]);
    const [callingCodes, setCallingCodes] = useState([]);
    const [timezones, setTimezones] = useState([] as Timezone[]);
    const [lockedFields, setLockedFields] = useState([] as string[]);
    
    useEffect(() => {

        setCountries(attributeValues.countries);
        setDepartments(attributeValues.departments);
        setCompanyTypes(attributeValues.companyTypes);
        setAreasOfInterest(attributeValues.areasOfInterest);
        setLanguages(attributeValues.languages);
        setCallingCodes(attributeValues.callingCodes);
        const userIdentity = getUserIdentity(profile);
        const connlockedFields = (userIdentity?.metadata?.LockedProfileFields || "").split(",");
        setCompanyName(userIdentity?.metadata?.CompanyName || "")
        setIsFederation(userIdentity?.metadata?.IsFederation || false)

        setLockedFields(connlockedFields);
        setTimezones(attributeValues.timezones);

    }, [profile, attributeValues])

    const handleChange = (field: string, value: any) => {
        
        if (!handleFieldChange) return;

        handleFieldChange(field, value);
    };

    const getLanguageValue = () => {

        const languageId = (profile && profile.language && profile.language.id) || "";

        if (!profile) return "";

        const language = languages.find(
            l => l.id === languageId
        );

        return (language && language.id) || '';
        
    };

    const isFieldEditable = (fieldName: string) => {
        return lockedFields.indexOf(fieldName) > -1;
    }

    const isCountryRequired = (fieldMetaData?: FieldMetaData) => {
        return (fieldMetaData && fieldMetaData.required) || false;
    }

    return (
        <>
            <Grid container direction="row">
                <Grid item xs={12} className={classes.margin}>
                    <Grid item xs={12}>
                        {fieldRenderer.renderEmailUsername(profile, t)}
                    </Grid>
                    {isFederation && (
                        <Grid item xs={12} className={classes.disabledFieldsDescriptionContainer}>
                            <span><img src={urlJoin(settings.staticFiles.endpoint, '/images/system/Information_black.svg')} className={classes.informationIcon} alt="" /></span><span className={classes.disabledFieldsDescriptionText}>
                                {t('user_profile.disabled_fields_description', { property_company_name: companyName })}
                            </span>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        {fieldRenderer.renderFirstName(fieldsMetadata.fields.find(field => field.name === 'first_name'), classes, profile?.first_name, handleChange, t, isFieldEditable('first_name'))}
                        {fieldRenderer.renderLastName(fieldsMetadata.fields.find(field => field.name === 'last_name'), classes, profile?.last_name, handleChange, t, isFieldEditable('last_name'))}
                        {fieldRenderer.renderLanguage(fieldsMetadata.fields.find(field => field.name === 'language'),getLanguageValue(), languages, handleSelectChange, t)}
                        {fieldRenderer.renderTimezone(fieldsMetadata.fields.find(field => field.name === 'timezone'), timezones, profile?.timezone?.id, handleSelectChange, t)}
                        {fieldRenderer.renderCompany(fieldsMetadata.fields.find(field => field.name === 'company'), classes, profile?.company, handleChange, t, isFieldEditable('company'))}
                    </Grid>
                    <Grid item xs={12}>
                        {fieldRenderer.renderCallingCode(fieldsMetadata.fields.find(field => field.name === 'calling_code'), profile?.phone_calling_code?.id || '', profile, callingCodes, handleSelectChange, t, isFieldEditable('phone_calling_code'))}
                        {fieldRenderer.renderPhoneNumber(fieldsMetadata.fields.find(field => field.name === 'phone_number'), classes, profile?.phone_number_cleaned, profile, handleChange, t, isFieldEditable('phone_number'))}
                    </Grid>
                    <Grid item xs={12}>
                        {fieldRenderer.renderStreet(fieldsMetadata.fields.find(field => field.name === 'street'), classes, profile?.street, handleChange, t, isFieldEditable('street'))}
                        {fieldRenderer.renderCity(fieldsMetadata.fields.find(field => field.name === 'city'), classes, profile?.city, handleChange, t, isFieldEditable('city'))}
                        {fieldRenderer.renderZip(fieldsMetadata.fields.find(field => field.name === 'zip'), classes, profile?.zip, handleChange, t, isFieldEditable('zip'))}
                        <Field
                            id="country"
                            name="country"
                            label={t('user_profile.country.label')}
                            value={profile?.country?.id || ''}
                            defaultValue={profile?.country?.id || ''}
                            onChange={(event: any) => {
                                handleCountryChange(event);
                            }}
                            parse={(value: any) => {
                                return value.value;
                            }}
                            placeholder={t('user_profile.country.placeholder')}
                            component={LabeledSelectbox}
                            options={countries}
                            disabled={isFieldEditable('country')}
                            inputProps={{ usetooltip: "true" }}
                            required={isCountryRequired(fieldsMetadata.fields.find(field => field.name === 'country'))}
                        />
                        {profile?.country?.id?.trim() !== '' &&
                            fieldRenderer.renderState(fieldsMetadata.fields.find(field => field.name === 'state'), attributeValues.states, profile?.state?.id.trim() || '', handleSelectChange, t, isFieldEditable('state'))}
                        {fieldRenderer.renderDepartment(fieldsMetadata.fields.find(field => field.name === 'department'), profile?.department?.id, departments, handleSelectChange, t, isFieldEditable('department'))}
                        {fieldRenderer.renderCompanyType(fieldsMetadata.fields.find(field => field.name === 'company_type'), profile?.company_type?.id, companyTypes, handleSelectChange, t, isFieldEditable('company_type'))}
                        {fieldRenderer.renderAreaOfInterest(fieldsMetadata.fields.find(field => field.name === 'area_of_interest'), profile?.areas_of_interest?.map((area: AreaOfInterest) => area.id), areasOfInterest, handleMultiSelectChange, t, isFieldEditable('interest_area'))}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ProfileFields;