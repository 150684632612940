import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './Styles';
import { BreadCrumb } from './Types'
import { Link as RouterLink, useLocation } from 'react-router-dom';

const BreadCrumbs: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const location = useLocation();

    const [breadCrumbs, setBreadCrumbs] = useState([{ text: 'header.home', link: "/" }] as BreadCrumb[]);

    useEffect(() => {

        const setBreadCrubs = async () => {
            let newBreadCrumbs = [] as BreadCrumb[]


            newBreadCrumbs.push({ text: 'header.home', link: "/" } as BreadCrumb)

            if (location.pathname.indexOf('edit-profile') > -1) {
                newBreadCrumbs = newBreadCrumbs.concat([{ text: 'header.your-profile', link: '/your-profile' }, { text: `header.edit-profile`, link: `/edit-profile/${Math.random()}` }]);
            } else if (location.pathname !== '/your-profile') {
                newBreadCrumbs = newBreadCrumbs.concat([{ text: 'header.your-profile', link: '/your-profile' }, { text: `header.${location.pathname.substring(1)}`, link: location.pathname }]);
            } else {
                newBreadCrumbs = newBreadCrumbs.concat([{ text: `header.${location.pathname.substring(1)}`, link: location.pathname }]);
            }

            setBreadCrumbs(newBreadCrumbs)
        }

        setBreadCrubs();


    }, [location.pathname]);


    return location.pathname !== '/' ? (
        <React.Fragment>
            <Grid container className={classes.breadCrumbContainer}>
                {breadCrumbs.map((breadCrumb, index) => {
                    return (
                        <span key={breadCrumb.text} className="triggerWarning">
                            <RouterLink to={breadCrumb.link} className={classes.breadCrumb}><>{t(breadCrumb.text)}</></RouterLink>
                            {index !== (breadCrumbs.length - 1) && <span className={classes.breadCrumbItemSpacer}>/</span>}
                        </span>
                    );
                })}
            </Grid>
        </React.Fragment>
    ) : null;
}
      
export default BreadCrumbs;

