import { getCookieCategoriesAcceptance } from './CookieInformation';

export default function Dynatrace() {
    initDynatraceTracking();
}

const initDynatraceTracking = () => {
    // When cookieinformation is loaded we need to trigger this function
    window.addEventListener("CookieInformationConsentGiven", function onCookieConsentChange() {
        // @ts-ignore
        const dtrum = window["dtrum"];
    
        // If no dtrum object available then return
        if (!dtrum || !dtrum.enable || !dtrum.disable) return;
        // Get user cookie acceptance from cookieinformation
        const acceptance = getCookieCategoriesAcceptance();
        
        // If the user has accepted functional cookies we can continue
        if (acceptance && acceptance.functional) { 
            // Enable Dynatrace cookies
            dtrum.enable();
        } else {
            // Disable Dynatrace cookies
            dtrum.disable();
        }
    }); 
} 
