import fetchApiWrapper from './fetchApiWrapper';
import settings from '../config/settings';

export interface Departments {
    items: Department[]
}

export interface AreasOfInterest {
    items: AreaOfInterest[]
}

export interface CompanyTypes {
    items: CompanyType[]
}

export interface CompanyType {
    id: string,
    text: string
}

export interface AreaOfInterest {
    id: string,
    text: string
}

export interface Department {
    id: string,
    text: string
}

export interface Languages {
    items: Language[]
}

export interface Language {
    id: string,
    text: string,
    has_been_translated: boolean
}

export const getDepartments = (pageLanguage: string): Promise<Departments> => {

    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    
    requestHeaders.append('Accept-Language', pageLanguage)
    
    return fetchApiWrapper(`${settings.backend.endpoint}/references/departments`, {
        headers: requestHeaders,
        method: 'GET',
      });
}

export const getCompanyTypes = (pageLanguage: string): Promise<CompanyTypes> => {

    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    
    requestHeaders.append('Accept-Language', pageLanguage)
    
    return fetchApiWrapper(`${settings.backend.endpoint}/references/company-types`, {
        headers: requestHeaders,
        method: 'GET',
      });
}

export const getAreasOfInterest = (pageLanguage: string): Promise<AreasOfInterest> => {

    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    
    requestHeaders.append('Accept-Language', pageLanguage)
    
    return fetchApiWrapper(`${settings.backend.endpoint}/references/interest-areas`, {
        headers: requestHeaders,
        method: 'GET',
      });
}

export const getLanguages = (): Promise<Languages> => {
    return fetchApiWrapper(`${settings.backend.endpoint}/references/languages`, {
        headers: {
          'Content-Type': 'application/json-patch+json; charset=utf-8',
        },
        method: 'GET',
      });
}
