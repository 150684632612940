import React from 'react';
import { useStyles } from './Styles';

interface Props {
  overlayClassName?: any,
  paperClassName?: any,
  zIndexOverlay?: any,
  zIndexPopup?: any,
  children: any
}
const ModalPopup: React.FC<Props> = ({overlayClassName, paperClassName, zIndexOverlay, zIndexPopup, children}) => {

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={`${classes.overlay} ${overlayClassName || ''} ${zIndexOverlay || ''}`} />
      <div className={`${classes.paper} ${paperClassName || ''} ${zIndexPopup || ''}`}>
        {children}
      </div>
    </React.Fragment>
  );
}

export default ModalPopup;