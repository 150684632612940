export const cookieRemove = (
	cookieName: string,
	cookieDomain?: string
) => {
    const domain = cookieDomain ? `Path=/; Domain=${cookieDomain}; ` : '';
    
    document.cookie = `${cookieName}=;${domain}Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
};

export const getCookieValueByName = (name: string) => {
    let cookieValue = null;
    
    document.cookie.split(";").forEach(item => {
        let [key, value] = item.split("="); 
        if(key.trim() === name)
            cookieValue = value;
    });
    
    return cookieValue;
}

export const getDomainName = (hostName: string) => {
    return (hostName && hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1)) || '';
}