import React from 'react';
import { Spinner } from '@danfoss/webex-ui';

type State = {setSpinnerVisibility: any}
type Props = {
    children: any
}
const SpinnerContext = React.createContext<State | undefined>(undefined);
export const useSpinner = () => React.useContext(SpinnerContext);

export const SpinnerProvider: React.FC<Props>  = ({children}) => {

    const [show, setSpinnerVisible] = React.useState(false);

    return (
        <SpinnerContext.Provider value={{setSpinnerVisibility: (visible: boolean) => setSpinnerVisible(visible)}}>
            <Spinner visible={show} />
            {children}
        </SpinnerContext.Provider>  
    )

}