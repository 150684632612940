import React, { Suspense, Dispatch, useState } from 'react';
import { Provider, connect } from 'react-redux';
import { Store } from 'redux';
import { AppState } from '../../store';
import Routes from '../../routes';
import { BrowserRouter } from 'react-router-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import theme from "./MuiTheme";
import { ThemeProvider } from '@material-ui/styles';
import { createBrowserHistory } from "history";
import { TelemetryService } from './AppInsights';
import LocalizationManager from './LocalizationManager';
import CookieInformation from './CookieInformation';
import GoogleAnalytics from './GoogleAnalytics';
import { AuthenticationProvider, authenticationClient } from '../../Providers/AuthenticationProvider';
import settings from '../../config/settings';
import { checkUser, UserActionType } from '../../store/profile/actions';
import Dynatrace from './Dynatrace';
import Title from './Title'
import { SpinnerProvider } from '../../Providers/SpinnerProvider';
import { Cultures } from '../../store/cultures/types';

type Props =  ReturnType<typeof mapDispatchToProps> & {
	store: Store<AppState>
	cultures: Cultures
  }

const history = createBrowserHistory();

// trigger dynatrace initialization
Dynatrace();

const RouteWrapper: React.FC<Props> = ( { store, checkUserProp, cultures } ) => {
	const [redirectUrl, setRedirectUrl] = useState('/');
	const culturesLoaded = cultures?.cultures?.length > 0;

	return (
		<Provider store={store}>
			<SpinnerProvider>
				{!(culturesLoaded) ? null : (<AuthenticationProvider 
					automaticSso={false}
					requireSignin={false}
					domain={settings.auth0.customDomain}
					client_id={settings.auth0.clientId}
					redirect_uri={`${window.location.origin}/login`}
					onAuthenticated={(accessToken: string , user: any) => { 
						checkUserProp(accessToken);
					}}
					onRedirectPersistState={() => {
						sessionStorage.setItem('redirectUri', window.location.pathname);
						return {redirectUri: window.location.pathname}}
					}
					onRedirectCallback={(appState: any = {}) => {
						if(appState && appState.redirectUri) {
							const { redirectUri = '' } = appState;
							setRedirectUrl(redirectUri);
						}
						else {
							const redirectUri = sessionStorage.getItem('redirectUri') || '';
							setRedirectUrl(redirectUri);
							sessionStorage.removeItem("redirectUri");
						}
					}}
					onRefreshError={(error: any) => {
						// If refresh of token results in login required or interaction required ask the user to login again 
						// (e.g. terms approval required, user logged out, refresh token expired or invalidated) 
						if (error.error === 'login_required' || error.error === 'interaction_required') {
							return authenticationClient.loginWithRedirect({ is_content_only: window.location.pathname.includes("content-only/")}); 
						}
						// If refresh of token results in invalid grant (e.g. if user is blocked) logout the user 
						else if (error.error === 'invalid_grant') {
							return authenticationClient.logout({ logout_uri: `${window.location.origin}`}); 
						}
						}
					}
					logout_uri={`${window.location.origin}/logout`}
					audience={settings.auth0.audience}
					scope='openid profile email read.users update.users delete.users read.fields-metadata read.connections read.user-applications read.location-data user-mfa.read user-mfa.write'
					leeway={60}
					useRefreshTokens={true}
					cacheLocation="memory"
					>

					<LocalizationManager>
						<Suspense fallback={null} >
							<Title/>
							{ /* @ts-ignore */ } 
							<MuiThemeProvider>
								<ThemeProvider theme={theme}>
									<BrowserRouter>
										<Routes redirectUrl={redirectUrl} />
									</BrowserRouter>
								</ThemeProvider>
							</MuiThemeProvider>
							<TelemetryService history={history} />
							<CookieInformation />
							<GoogleAnalytics />
						</Suspense>
					</LocalizationManager>
				</AuthenticationProvider>)}
			</SpinnerProvider>
		</Provider>
	);
};

const mapStateToProps = (state: AppState) => ({
	cultures: state.cultures
});

const mapDispatchToProps = (dispatch: Dispatch<UserActionType>) => ({
	checkUserProp: async (accessToken: string) => 
	   dispatch(checkUser(accessToken)),
  });


export default connect(mapStateToProps, mapDispatchToProps)(RouteWrapper);
