import { authHostService } from '../../Services/AuthHostService';
import jwtDecode from 'jwt-decode';
import {auth0Client} from '../Auth0Provider'
import { RedirectLoginOptions, 
         PopupLoginOptions, 
         PopupConfigOptions,
         GetUserOptions, 
         getIdTokenClaimsOptions, 
         IdToken,
         RedirectLoginResult,
         GetTokenSilentlyOptions, 
         LogoutOptions} from '@auth0/auth0-spa-js/dist/typings/global';
interface DecodedToken {
    sub: string,
    exp: number
}

export class AuthenticationClient {

    accessToken: string = '';
    accessTokenDecoded: DecodedToken | undefined;
    expirationTime : number = Date.now();
    webkitAuthenticationAvailable: boolean = false;

    async initialize(): Promise<boolean> {
        // Check for a messagehandler named "JsToNativeMessageHandler" is exists then isAvailable is true
        if (authHostService.isAvailable) {
            try {
                // Get the accesstoken from the authHostService
                const { token } = await authHostService.getAccessToken();
                this.accessToken = token;
                this.accessTokenDecoded = jwtDecode<DecodedToken>(this.accessToken);
                const expDate = new Date(0);
                this.expirationTime = expDate.setUTCSeconds(this.accessTokenDecoded.exp);

                // If the accesstoken is valid and not expired, then set webkitAuthenticationAvailable to true
                if (this.accessToken && this.accessTokenDecoded && this.expirationTime > Date.now()) {
                    this.webkitAuthenticationAvailable = true; 
                }
                }
                catch (e) {
                    // If an error happens set webkitAuthenticationAvailable = false 
                    this.webkitAuthenticationAvailable = false;  
                    console.error("Unable to obtain token from host", e);
                }
        }
        return true;
    }

    isWebKitBridgeAvailable(): Promise<boolean> {
        return Promise.resolve(authHostService.isAvailable && this.webkitAuthenticationAvailable);
    }

    buildAuthorizeUrl(options?: RedirectLoginOptions): Promise<string> {
        if (auth0Client)
            return auth0Client.buildAuthorizeUrl(options);
        else
            return Promise.reject();    
    }

    loginWithPopup(options?: PopupLoginOptions, config?: PopupConfigOptions): Promise<void> {
        if (auth0Client)
            return auth0Client.loginWithPopup(options, config);
        else
            return Promise.reject();    
    }

    getUser(options?: GetUserOptions): Promise<any> {

        if (this.webkitAuthenticationAvailable) {
            return this.isAuthenticated()
                .then((isAuthenticated:boolean) => {
                    if (isAuthenticated) 
                        return Promise.resolve({sub: this.accessTokenDecoded && this.accessTokenDecoded.sub}) 
                    else
                        return Promise.reject();
                })
        }
        else {
            if (auth0Client)
                return auth0Client.getUser(options);
        }

        return Promise.resolve();    
    }

    getIdTokenClaims(options?: getIdTokenClaimsOptions): Promise<IdToken> {

        if (auth0Client)
            return auth0Client.getIdTokenClaims(options);
        else
            return Promise.reject();    

    }

    loginWithRedirect(options?: RedirectLoginOptions): Promise<void> {

        if (auth0Client)
            return auth0Client.loginWithRedirect(options);
        else
            return Promise.reject();    

    }

    handleRedirectCallback(url?: string): Promise<RedirectLoginResult>  {

        if (auth0Client)
            return auth0Client.handleRedirectCallback(url);
        else
            return Promise.reject();    

    }

    async getTokenSilently(options?: GetTokenSilentlyOptions): Promise<any> {

        if (this.webkitAuthenticationAvailable) {
            if (this.accessToken && this.accessTokenDecoded && this.expirationTime > Date.now()) {
                this.webkitAuthenticationAvailable = true;
                return Promise.resolve(this.accessToken);
            }
            else {
                try {
                    const { token } = await authHostService.getAccessToken();
                    this.accessToken = token;
                    this.accessTokenDecoded = jwtDecode<DecodedToken>(this.accessToken);
                    const expDate = new Date(0);
                    this.expirationTime = expDate.setUTCSeconds(this.accessTokenDecoded.exp);
                    if (this.accessToken && this.accessTokenDecoded && this.expirationTime > Date.now()) {
                        this.webkitAuthenticationAvailable = true;
                        return this.accessToken;  
                    }
                  }
                  catch (e) {
                    this.webkitAuthenticationAvailable = false;  
                    console.error("Unable to obtain token from host", e);
                    return '';
                  }
            }    
        }
        else {
            if (auth0Client)
                return auth0Client.getTokenSilently(options);
        }

        return Promise.resolve('');    

    }

    isAuthenticated(): Promise<boolean> {
        if (this.webkitAuthenticationAvailable) {
            if (this.accessToken && this.accessTokenDecoded && this.expirationTime > Date.now()) {
                return Promise.resolve(true);
            }
            else {
                this.getTokenSilently();
                if (this.accessToken && this.accessTokenDecoded && this.expirationTime > Date.now()) {
                    return Promise.resolve(true);
                }
            }
        }
        else {
            if (auth0Client) {
                return auth0Client.isAuthenticated();
            }
        }

        return Promise.resolve(false);
    } 
    
    logout(options?: LogoutOptions): void {
        if (auth0Client)
            return auth0Client.logout(options);
        else
            return;    

    }
}

export default async function createAuthnticationClient(): Promise<AuthenticationClient> {

    const authenticationClient = new AuthenticationClient();
    await authenticationClient.initialize();

    return authenticationClient;

}