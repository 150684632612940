import settings from '../config/settings';
import fetchApiWrapper from './fetchApiWrapper';

export const getCookieInformationLangauges = () => {
	
	return fetchApiWrapper<any>(
		`${settings.staticFiles.endpoint}/localization/cookieInformation.json`,
		{
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			method: 'GET',
		}
	);
};
