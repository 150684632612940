
import React from 'react';
import { DanfossBackground, DanfossLogo } from '@danfoss/webex-ui/dist/mui';
import { urlJoin } from 'url-join-ts';
import settings from '../../config/settings';

interface LogoAndBackgroundProps {
	classes: any;
}

const LogoAndBackground: React.FC<LogoAndBackgroundProps> = ({classes}) => {
    return (
        <>
          <DanfossBackground />
          <DanfossLogo pathToImage={urlJoin(settings.staticFiles.endpoint, '/images/logo_payoff.svg')} linkUrlPath="" className={`${classes.wrapper} ${classes.big}`} />
          <DanfossLogo pathToImage={urlJoin(settings.staticFiles.endpoint, '/images/logo_mobile.svg')} linkUrlPath="" className={`${classes.wrapper} ${classes.small}`} />
        </>
    );
}

export default LogoAndBackground