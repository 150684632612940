import { emailResendActionTypes, EmailVerificationData, PasswordResetData } from './types';
import { action } from 'typesafe-actions';

export interface ResendEmailVerification {
	type: emailResendActionTypes.RESEND_VERIFICATION_EMAIL;
	payload: EmailVerificationData;
}

export interface ResendPasswordEmail {
	type: emailResendActionTypes.RESEND_RESET_PASSWORD;
	payload: PasswordResetData;
}

export const resendEmailVerification = (payload: EmailVerificationData) : ResendEmailVerification => {
	return action(emailResendActionTypes.RESEND_VERIFICATION_EMAIL, payload);
}

export const resendPasswordReset = (payload: PasswordResetData) : ResendPasswordEmail => {
	return action(emailResendActionTypes.RESEND_RESET_PASSWORD, payload);
}

export type EmailResendType = ResendEmailVerification | ResendPasswordEmail