import React from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './Styles';
import { DashboardItemPopupProps } from './Types';
import { urlJoin } from 'url-join-ts';
import settings from '../../config/settings';
import ModalPopup from '../../components/Modal/ModalPopup';
import parse from 'html-react-parser';
import { isMobileDevice } from '../../utils';

const DashboardItemPopup: React.FC<DashboardItemPopupProps> = ({ headerText, bodyText, togglePopup }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.popupContainer}>
            <ModalPopup paperClassName={classes.popup} overlayClassName={classes.overlay}>
                <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                    <div onClick={() => togglePopup()}>
                        <img src={urlJoin(settings.staticFiles.endpoint, '/images/system/Close.svg')} className={classes.close} alt="" />
                    </div>
                </Grid>
                {headerText && (
                    <Grid item xs={12} className={classes.popupHeader} >
                        {!isMobileDevice() ? headerText.replace('<br/>',' ').replace('<br>',' ') : parse(headerText.replace('>','/>'))}
                    </Grid>)
                }
                <Grid item xs={12} >
                    <span className={classes.popupContent}>
                        {bodyText}
                    </span>
                </Grid>
            </ModalPopup>
        </Grid>
    );
};

export default DashboardItemPopup;
