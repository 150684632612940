import { HostDeferer } from "./JsHostService/HostDeferer";

export interface JsMessageHandler {
    postMessage(message: string, targetOrigin: string): void
}

export interface IJsHostService {
    messageHandlerName: string;
    scopeNamespace?: string;

    readonly isAvailable: boolean;
    readonly messageHandler: JsMessageHandler;
    readonly metadata: { [key: string]: string; }

    call<T>(scope: string, handler: string, method: string, data?: any): Promise<T>
}
export class JsHostService implements IJsHostService {

    messageHandlerName: string;
    scopeNamespace?: string;

    readonly isAvailable: boolean;
    readonly messageHandler: JsMessageHandler;
    readonly metadata: { [key: string]: string; }

    constructor(messageHandlerName: string, scopeNamespace?: string) {
        this.messageHandlerName = messageHandlerName;
        this.scopeNamespace = scopeNamespace;

        // Get the messagehandler with the given name if it exists
        const handler = JsHostService.getMessageHandler(this.messageHandlerName);

        // Add true/false if the handler exists
        this.isAvailable = !!handler;

        // If the handler exists, set set the values of varibles, else set default values
        if (handler) {
            this.metadata = handler.metadata;
            this.messageHandler = handler.messageHandler;
        }
        else {
            this.metadata = {}
            this.messageHandler = { postMessage: () => { return null; } };
        }
    }


    // Generic function of type T that takes different values and returns a Promise of type T
    call = <T>(scope: string, handler: string, method: string, data?: any): Promise<T> => {
        const namespace = !!this.scopeNamespace ? `${this.scopeNamespace}.` : '';
        const namespacedScope = `${namespace}${scope}`;
        
        // Add a new Defer to the queue and return the inserted instance
        const defer = HostDeferer.defer<T>(namespacedScope, handler, method, data);

        // Post the message with the stringified defer instance
        this.messageHandler.postMessage(JSON.stringify(defer), '*');

        return defer.promise;
    }

    static getMessageHandler(handlerName: string) {
        let messageHandler: JsMessageHandler;
        let host: string;

        // If window has no parent then window === window.parent
        if (window === window.parent) {
            const _window = window as any;
            const webkit = _window.webkit;

            // Do we have webkit messagehandlers?
            const hasWKHandler = (webkit && webkit.messageHandlers);
            
            // if webkit messagehandlers exists, get the messagehandler with the given name from webkit otherwise from the window object
            messageHandler = (hasWKHandler ? webkit.messageHandlers[handlerName] : _window[handlerName]) as JsMessageHandler;
            
            // If we found a webkit handler the host is to be set to iOS otherwise Android
            host = hasWKHandler ? "ios" : "android";
        }
        else {
            // window has a parent, then we should call the parent windows postmessage function.
            messageHandler = {
                postMessage: (message: any, targetOrigin: string) => window.parent.postMessage(message, targetOrigin)
            }
            host = "web";
        }

        if (!messageHandler) return undefined;

        // Return the messagehandler and host as object
        return {
            messageHandler,
            metadata: {
                host
            }
        };

    }
}

/** An instance of JsHostService that uses the JS interface with the name 'JsToNativeMessageHandler' and the default scope namespace */
export const jsHostService = new JsHostService("JsToNativeMessageHandler") as IJsHostService;