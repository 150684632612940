import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	languageContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
      },
      languageIcon: {
        marginRight: '10px',
        height: '22px',
        width: '22px'
      },
      paper: {
        marginTop: '5px',
        width: '173px',
        maxHeight: '170px',
        borderRadius: '0px 0px 5px 5px',
        [theme.breakpoints.down('md')]: {
          marginTop: '2px', 
        },
        [theme.breakpoints.down('sm')]: {
          //marginTop: '26px', 
          width: '100%',
          borderRadius: 0,
          left: '0 !important',
          maxWidth: '100%',
        }
      },
      menuitem: {
        fontSize: '14px',
        lineHeight: '30px',
        minHeight: '32px',
        letterSpacong: '0.5px', 
        paddingTop: '0px',
        paddingBottom: '0px'  
      },
      modal: {
        marginTop: '100px',
        "& > div":{
          top: '74px !important',
          [theme.breakpoints.only('md')]: {
            top: '53px !important' 
          },
          [theme.breakpoints.only('sm')]: {
            top: '42px !important' 
          },
          [theme.breakpoints.only('xs')]: {
            top: '42px !important' 
          }
        }
      },
      cultureNameSmallScreen: {
        [theme.breakpoints.up('sm')]: {
          display: 'none'
        },
        marginRight: '10px'
      },
      cultureNameBigScreen: {
        [theme.breakpoints.down('xs')]: {
          display: 'none'
        }      
      },
      headerSpacer: {
        marginRight: '27px'
      },
}));