import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	root: {
		marginLeft: '0px',
		position: 'relative'
	},
	applicationTextContainer: {
		position: 'relative',
		minHeight: '562px',
		[theme.breakpoints.down('sm')]: {
			minHeight: '284px', 
			display: 'block'
        },
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundColor: 'white',
		backgroundSize: 'cover' 
	},
	innerContentMargin: {
		margin: '62px 80px 58px 80px',
		[theme.breakpoints.between('sm', 'md')]: {
            margin: '62px 80px 58px 40px'
        },
        [theme.breakpoints.only('xs')]: {
            margin: '37px 16px 33px 16px'
        },
	},
	pictureBox: {
		position: 'absolute',
    	maxWidth: '772px',
    	maxHeight: '284px',
    	background: 'rgba(255, 255, 255, 0.8)',
		margin: 'auto',
		justifyContent: 'center',
		textAlign: 'center',
		padding: '40px 55px 15px 55px',
		[theme.breakpoints.only('xs')]: {
			padding: '40px 16px 15px 16px',
			display: 'table',
			top: 0,
			position: 'relative',
        },
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
	},
	pictureBoxHeader: {
		fontSize: '36px',
		fontWeight: 700,
		lineHeight: '48px',
		[theme.breakpoints.only('xs')]: {
			textAlign: 'center',
			marginBottom: '15px'
        },
	},
	pictureBoxButtonContainer: {
		justifyContent: 'center',
		marginBottom: '3px',
		display: 'flex',
		[theme.breakpoints.only('xs')]: {
			marginTop: '30px',
			marginBottom: '30px',
			display: 'inline-block'
		}
	},
	pictureBoxSignUpLinkContainer: {
		marginTop: '3px'
	},
	pictureBoxSignUpLink: {
		textDecoration: 'none',
		fontSize: '16px',
		fontWeight: 700,
		lineHeight: '24px',
		color: colors.webexuiBlack,
		cursor: 'pointer',
		'&::before': {
			content: '"\\203A\\0000A0"',
			fontSize: '26px',
			top: '2px',
			position: 'relative'
		},
		"&:hover": {
			color: colors.webexuiRed
		}
	},
	button: {
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			margin: '0 0 10px !important'
		},
		padding: '5px 30px',
		marginRight: '30px'
    },
	innerContentWidth: {
		maxWidth: '1280px',
		margin: 'auto',
		marginTop: '36px',
		marginBottom: '36px',
		backgroundColor: colors.webexuiWhite
	},
	descriptionContainer: {
		backgroundColor: colors.webexuiWhite
	},
	benefitsContainer: {
		backgroundColor: colors.webexuiGraySoft
	},
	containerMaxWidth: {
		margin: 'auto',
		maxWidth: '900px'
	},
	backgroundImage: {
		width: '100%',
		height: '100%',
		maxHeight: '562px',
		verticalAlign: 'middle',
		borderStyle: 'none',
		[theme.breakpoints.only('xs')]: {
			position: 'absolute'
		}
	},
	buttonContainer: {
		[theme.breakpoints.only('xs')]: {
			margin: '0 52px 0 52px !important'
		}
	},
	welcomeText: {
		[theme.breakpoints.up('sm')]: {
			marginLeft: '10px'
		},
		fontSize: '16px',
		color: colors.webexuiGrayText,
		lineHeight: '24px',
		marginBottom: '10px'
	},
	header: {
		fontSize: '28px',
		fontWeight: 700,
		lineHeight: '48px',
		marginBottom: '16px'
	},
	description: {
		fontSize: '16px',
		lineHeight: '24px',
		marginBottom: '10px'
	},
	center: {
		textAlign: 'center'
	},
	subHeader: {
		fontSize: '24px',
		fontWeight: 'bold',
		letterSpacing: '0.12px',
		lineHeight: '26px',
		color: colors.webexuiDarkGrayish,
		margin: '0px 0px 20px 10px',
		[theme.breakpoints.only('xs')]: {
			margin: '0px 0px 20px 0px'
		}
	},
	areaIconContainer: {
		margin: '15px 0px 15px',
		flexGrow: 1		
	},
	fullWidth: {
		backgroundColor: colors.webexuiGraySoft
	},
	fullHeight: {
		height: '100%'
	},
	benefitContainer: {
		maxWidth: '900px',
		margin: '58px 0px 15px',
		flexGrow: 1	
	},
	benefitItemContainer: {
		[theme.breakpoints.up('sm')]: {
			padding: '0 22px'
		},
		textAlign: 'center',
	},
	benefitItemIconContainer: {
		
	},
	benefitItemIcon: {
		width: '72px',
		height: '72px'
	},
	benefitDescriptionContainer: {
		margin: '22px 0'
	},
	benefitItemHeader: {
		fontSize: '18px',
		fontWeight: 700,
		marginBottom: '10px'
	},
	benefitItemDescription: {
		fontSize: '16px',
		fontWeight: 400
	},
	leaveConfirmContainer: {
		top: '-80px',
		width: '100%',
		display: 'flex',
		position: 'absolute',
		justifyContent: 'center'
	  }
}));