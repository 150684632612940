import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useStyles } from './Styles';
import { urlJoin } from 'url-join-ts';
import settings from '../../config/settings';

type ToolTipProps = { 
    toolTip: string,
    type?: string
} 

const ToolTip: React.FC<ToolTipProps> = ({ toolTip, type }) => {
    const classes = useStyles();

    return (
        <span key="test" className={classes.infoIconContainer}>
            <Tooltip
                placement="top-start"
                enterTouchDelay={0}
                title={
                    <React.Fragment>
                        {toolTip}
                    </React.Fragment>
                }
                className="MuiTooltip"
            >
            <img src={type && type === 'information' ? urlJoin(settings.staticFiles.endpoint,'/images/system/Information_white.svg') : urlJoin(settings.staticFiles.endpoint,'/images/system/Information_red.svg')} className={classes.infoIcon} alt="" />
            </Tooltip>
        </span>
    );
};

export default ToolTip;

