import React from 'react';
import { Navigate, useLocation, useMatch  } from 'react-router-dom';
import queryString from 'query-string';
import DOMPurify from 'dompurify';

interface RedirectWithQueryStringProps {
    to: string
}

const RedirectWithQueryString: React.FC<RedirectWithQueryStringProps> = ({ to }) => {

    let location = useLocation();
    const match = useMatch(to);
    const {search} = location;

    let newUrl = to;
    for (let [key, value] of Object.entries(match?.params || {})) {
        newUrl = newUrl.replace(`:${key}`, value as string);
    }
    
    const queryStringElements = queryString.parse(search);
    const qyeryStringReplacements = newUrl.match(/(#.+?)\b/g);
    qyeryStringReplacements && qyeryStringReplacements.forEach( (key) => {
        if (queryStringElements[key.slice(1)] as string )
            newUrl = newUrl.replace(key, encodeURIComponent(DOMPurify.sanitize(queryStringElements[key.slice(1)] as string)));        
    })    
    
    newUrl = newUrl.replace(/([#:].+?)\b/g, '');

    return (
        <Navigate to={newUrl}/>
    );

}

export default RedirectWithQueryString