import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './Styles';
import { BannerProps } from './Types';
import parse from 'html-react-parser';
import { useDrag, useDrop } from 'react-dnd'
import { urlJoin } from 'url-join-ts';
import settings from '../../config/settings';
import { useTranslation } from 'react-i18next';
import { pushEvent } from '../../Services/AnalyticsService';

const Banner: React.FC<BannerProps> = ({ bannerClick, bannerClass, bannerIconBorderClass, bannerTextClass, bannerIcon, bannerIconHover, bannerText, bannerInformationIconUrl, bannerInformationClick, handleItemDisplay, id = "", moveDashboardItem, index, itemType, client_id, showAsActive, editMode, hidden, userEmail }) => {
    const classes = useStyles();
    const [iconSrc, setIconSrc] = React.useState(bannerIcon);
    const { t } = useTranslation();

    const dragRef = React.useRef(null)
    const dropRef = React.useRef(null)

    const [{ isDragging }, drag] = useDrag({
        type: (itemType || ''),
        item: { name: bannerText, client_id },
        collect: monitor => ({
            item: monitor.getItem(),
            isDragging: monitor.isDragging()
        }),
    })

    const [, drop] = useDrop({
        accept: (itemType || ''),
        drop: (_item) => {
            return moveDashboardItem && moveDashboardItem(index, _item)
        }
      });

    const opacity = isDragging ? 0 : 1

    // We do not allow users to sort inactive items
    if (showAsActive && editMode) {
        drop(dropRef)
    }

    // We do not allow drag'n'drop sorting on relevant websites.
    if (itemType !== 'recommended' && itemType !== 'yourProfile' && showAsActive && editMode) {
        drag(dragRef)
    }

    const handleBannerClick = () => {

        pushEvent(
            {
                'event': 'AppDashboard',
                'step': 'banner click',
                'banner': bannerText
            },
            userEmail
        );

        !editMode && bannerClick();
    }

    return (
        <div style={{ opacity }} ref={dropRef} className={classes.bannerContainer}>
            <Grid ref={dragRef}>

                <Grid className={classes.iconContainer}>
                    {editMode && (
                        <Grid onClick={(e) => { e.preventDefault(); handleItemDisplay && handleItemDisplay(!hidden) }} className={classes.displayIconContainer}>
                            {hidden ? (
                                <img src={urlJoin(settings.staticFiles.endpoint, '/images/system/AppDashboard_Invisible.svg')} title={t('app_dashboard.show_item')} alt="" className={classes.editIcon} />
                            ) : (
                                <img src={urlJoin(settings.staticFiles.endpoint, '/images/system/AppDashboard_Visible.svg')} title={t('app_dashboard.hide_item')} alt="" className={classes.editIcon} />
                            )}
                        </Grid>
                    )}
                    {bannerInformationIconUrl && !editMode && (
                        <Grid onClick={(e) => { e.preventDefault(); bannerInformationClick && bannerInformationClick(e) }} className={classes.informationIconContainer}>
                            <img src={bannerInformationIconUrl} className={classes.informationIcon} title={t('app_dashboard.item_information')} alt="" />
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12} id={id} className={`${classes.bannerSubContainer} ${bannerClass} ${!editMode && classes.cursor} ${editMode && hidden && classes.itemHidden}`} onClick={handleBannerClick} onMouseEnter={() => setIconSrc(bannerIconHover)} onMouseLeave={() => setIconSrc(bannerIcon)}>

                    <Grid item xs={12} className={`${classes.bannerIconContainer} ${bannerIconBorderClass}`}>
                        <span className={classes.bannerHelper} />
                        <span className={classes.bannerArrowSubContainer}>
                            <img src={iconSrc} alt="" className={classes.bannerImage} />
                        </span>
                    </Grid>
                    <Grid item xs={12} className={classes.bannerTextContainer}>
                        <span className={classes.bannerHelper} />
                        <span className={classes.bannerTextSubContainer}>
                            <Typography className={bannerTextClass}>
                                <span translate="no">{parse(bannerText)}</span>
                            </Typography>
                        </span>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default Banner;
