import fetchApiWrapper from './fetchApiWrapper';
import settings from '../config/settings';
import { getRegistrationCenterApiKey } from '../utils';

export interface DIPLegalInfo {
    dip_terms_and_conditions: DIPTermsAndConditions,
    privacy_policy: PrivacyPolicy
  }

export interface DIPTermsAndConditions {
    latest_version: string,
    url: string
}

export interface PrivacyPolicy {
    latest_version: string,
    url: string
}

export const getDipLegalInfo = (pageLanguage: string): Promise<DIPLegalInfo> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', getRegistrationCenterApiKey(settings.regCenterApi.hashKey));
    
    if (pageLanguage) {
        requestHeaders.append('Accept-Language', pageLanguage)
    }
    
    return fetchApiWrapper(`${settings.regCenterApi.endpoint}/dip-legal-info`, {
        headers: requestHeaders,
        method: 'GET',
      });
}