import { ProfileActionTypes, Profile } from "./types";
import { createReducer } from 'typesafe-actions';
import { UserActionType } from './actions';

export const initialState = null;

const reducer = createReducer<Profile, UserActionType>(initialState as unknown as Profile, {
  [ProfileActionTypes.LOGIN]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [ProfileActionTypes.LOGOUT]: () => {
    return null as unknown as Profile;
  },
  [ProfileActionTypes.CLEAR_PROFILE]: () => {
    return null as unknown as Profile;
  },
  [ProfileActionTypes.ENHANCE_USER]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [ProfileActionTypes.UPDATE_USER]: (state, action) => {
    return { ...state, ...action.payload };
  }
});

export { reducer as profileReducer };

