import React, { useEffect } from 'react';
import { Cultures, Culture } from '../../store/cultures/types';
import { Menu, MenuItem, Modal } from '@material-ui/core';
import { urlJoin } from 'url-join-ts';
import settings from '../../config/settings';
import { Transition } from 'react-transition-group';

// Graphics
import { useStyles } from './Styles';

interface LanguageSelectorProps {
  cultures: Cultures,
  onSelect: (culture: Culture) => void
}



const LanguageSelector: React.FC<LanguageSelectorProps> = ({ onSelect, cultures, }) => {
  const currentCultureName = (cultures.cultures.find(c => c.id === cultures.currentCulture && c.has_been_translated) || { text: cultures.currentCulture }).text;
  const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>(null);
  const classes = useStyles();

  useEffect(() => {
    window.addEventListener('resize', setMenuStyles, true);

    return () => {
      // When unmounting make sure to remove the eventListener again
      document.removeEventListener('resize', setMenuStyles, true);
    }

  }, []);

  function isVisible(e: HTMLSpanElement) {
    return e && !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
  }

  function handleClick() {
    if (anchorEl === null) {
      var elements = document.getElementsByClassName("DIPLanguage");
      
      Array.from(elements).forEach((element) => {
        if (isVisible(element as HTMLSpanElement)) {
          setAnchorEl(element as HTMLSpanElement);
        }
      });
    } else {
      setAnchorEl(null);
    }
  }

  function setMenuStyles() {
    const menu = document.getElementById("DIPLanguageMenuItems")?.children[0] as HTMLDivElement;

    if (menu && isVisible(menu)) {
      const header = document.getElementById("DIPHeader");
      const marginTop = header && (header.offsetHeight - document.documentElement.scrollTop);

      const myModal = document.getElementById("DIPLanguageModal")?.firstChild as HTMLDivElement || null;
      
      const topValue = `${marginTop}px`;
      myModal && myModal.style.removeProperty("top");
      myModal && myModal.style.setProperty("inset", `${topValue} 0px 0px`, 'important');
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleMenuItemClick(event: React.MouseEvent<HTMLElement>, culture: Culture) {
    onSelect(culture);
    setAnchorEl(null);
  }

  return (
    <React.Fragment>
      <span className={`${classes.languageContainer} ${classes.cultureNameBigScreen} triggerWarning ${classes.headerSpacer} DIPLanguage`} onClick={handleClick} data-testid="cultureNameBigScreen">
        <img src={urlJoin(settings.staticFiles.endpoint, '/images/system/Header_Globe.svg')} className={`${classes.languageIcon} triggerWarning`} alt="" /><span translate="no">{currentCultureName}</span>
      </span>
      <span className={`${classes.languageContainer} ${classes.cultureNameSmallScreen} triggerWarning DIPLanguage`} onClick={handleClick} data-testid="cultureNameSmallScreen">
        <img src={urlJoin(settings.staticFiles.endpoint, '/images/system/Header_Globe.svg')} className={`${classes.languageIcon} triggerWarning`} alt="" /><span translate="no">{cultures.currentCulture}</span>
      </span>
      <Modal
        className={`${classes.modal} triggerWarning`}
        open={Boolean(anchorEl)}
        id="DIPLanguageModal"
      >
        <Transition timeout={300} onEnter={setMenuStyles}>
          <Menu
            classes={{ paper: classes.paper }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            onClose={handleClose}
            data-testid="cultureNamesMenu"
            MenuListProps={{ "translate": "no" }}
            id="DIPLanguageMenuItems"
            marginThreshold={11}
          >
            {cultures.cultures.filter(c => c.has_been_translated).map((culture: Culture) => (
              <MenuItem
                className={classes.menuitem}
                key={culture.id}
                onClick={(event: any) => handleMenuItemClick(event, culture)}
                selected={culture.text === currentCultureName}
              >
                {culture.text}
              </MenuItem>
            ))}
          </Menu>
        </Transition>
      </Modal>
    </React.Fragment>
  );
};

export default LanguageSelector;
