import { FieldsMetadataActionType, FieldsMetaData, FieldMetaData } from './types';
import { createReducer } from 'typesafe-actions';
import { FieldsMetadataActionTypes } from './actions';

export const fieldsConfig = (obj:FieldsMetaData):any => {

	let fieldsConfigObject:any = [];

	obj.fields.forEach((field: FieldMetaData) => {
		
		fieldsConfigObject = {...fieldsConfigObject, [field.name]:field}
	});

	return fieldsConfigObject;
}

const reducer = createReducer<FieldsMetaData, FieldsMetadataActionTypes>({
	fields: [] as FieldMetaData[],
	fieldsObject: {} as any
}, {
	[FieldsMetadataActionType.SET_FIELDSMETADATA]: (state: FieldsMetaData, action) => {
		return { ...action.payload,  fieldsObject: {...fieldsConfig(action.payload)}};
	}
});

export { reducer as fieldsMetadataReducer };
