import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	margin: {
		width: '100%',
		margin: '20px 0 20px',
		maxWidth: '466px'
	},
	informationIcon: {
		marginRight: '11px'
	},
	disabledFieldsDescriptionContainer: {
		display: 'flex',
		margin: '10px 0'
	},
	disabledFieldsDescriptionText: {
		lineHeight: '24px',
		fontSize: '14px',
		marginTop: '-5px'

	},
	formEditProfileroot: {
		[theme.breakpoints.up('sm')]: {
			padding: '0px 80px 0 10px',
			
		}
	},
	fieldContainer: {
		width: '100%',
		position: 'relative',
		[theme.breakpoints.up('sm')]: {
			margin: '16px 20px 0 0'
		}
	},
	header: {
		fontSize: '36px',
		fontWeight: 'bold',
		letterSpacing: '0.14px',
		lineHeight: '48px',
		color: colors.webexuiDarkGrayish,
		marginBottom: '11px'
	},
	button: {
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			margin: '0px 0px 10px !important'
		},
		[theme.breakpoints.up('sm')]: {
			marginRight: '20px'
		}
	},
	linkText: {
		fontSize: '18px',
		fontWeight: 600,
		letterSpacing: '0.18px',
		lineHeight: '28px',
		textDecoration: 'none',
		color: colors.webexuiDarkGrayish,
		cursor: 'pointer'
	},
	icon: {
		marginRight: '7px'
	},
	buttonContainer: {
		margin: '15px 0 15px'
	},
	buttonContainerSpace: {
		margin: '15px 0 40px'
	},
	confirmationPopupContainer: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		position: 'absolute',
		top: '-50px'
	},
	confirmationPopup: {
		top: '100px',
		maxWidth: '522px',
		position: 'relative'
	},
	confirmationHeader: {
		color: colors.webexuiDarkGrayish,
		fontSize: '24px',
		fontWeight: 'bold',
		lineHeight: '25px',
		margin: '26px'
	},
	confirmationLink: {
		marginLeft: '26px'
	},
	validationErrorContainer: {
		marginBottom: '40px',
		maxWidth: '468px',
		flexFlow: 'row'
	},
	validationErrorContainerLeaveConfirm: {
		maxWidth: '468px',
		flexFlow: 'row',
		marginTop: '10px'
	},
	validationErrorText: {
		color: colors.webexuiRed,
		fontSize: '16px'
	},
	validationErrorIcon: {
		lineHeight: '28px'
	},
	descriptionText: {
		color: '#616161'
	},
	blackText: {
		color: colors.webexuiBlack,
	},
	leaveConfirmContainer: {
		top: '-50px',
		width: '100%',
		display: 'flex',
		position: 'relative',
		justifyContent: 'center'
	},
	leaveConfirmPopup: {
		padding: '0px !important',
		zIndex: 10000,
		position: 'absolute',
		maxWidth: '562px',
		margin: 'auto'
	}
}));