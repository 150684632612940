import { makeStyles } from '@material-ui/core';
import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';

export const useStyles = makeStyles(theme => ({
    root: {
        margin: '0',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '10px'
        }
    },
    banner: {
        backgroundColor: colors.webexuiGraySoft,
        borderRadius: '0px 0px 2px 2px',
        boxShadow: '0px -4px 0px #E2000F',
        "&:hover": {    
            boxShadow: '0px -4px 0px #E2000F, 0px 12px 17px 2px rgba(66, 78, 84, 0.14)'
        }
    },
    bannerIconBorder: {
        borderRight: '1px solid #D8D8D9',
    },
    bannerText: {
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '24px'
    },
    header: {
        fontSize: '36px',
        fontWeight: 700,
        letterSpacing: '0.14px',
        lineHeight: '48px',
        marginBottom: '10px',
        color: colors.webexuiDarkGrayish
    },
    subHeader: {
        color: '#575756',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        margin: '0 0 20px 0',
        [theme.breakpoints.up('sm')]: {
            margin: '19px 0 17px 10px'
        }
    },
    description: {
        color: '#575756',
        fontSize: '16px',
        lineHeight: '24px',
        maxWidth: '954px',
        [theme.breakpoints.up('sm')]: {
            margin: '1px 0 40px 10px'
        }
    },
    bannerIconContainer: {
        margin: '15px 0 0 10px',
        flexGrow: 1,
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            margin: '15px 0 0 0'
        }
    }
}));