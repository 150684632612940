import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputLabel, InputAdornment } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { TextFieldProps, BaseTextFieldProps } from "@material-ui/core/TextField/TextField";
import Grid from '@material-ui/core/Grid';
import parse from 'html-react-parser';
// @ts-ignore
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive';
import theme from "../RouteWrapper/MuiTheme";
import { ToolTip } from '../ToolTip';
import { useStyles } from './Styles';
import './RenderFields.scss';
import { urlJoin } from 'url-join-ts';
import settings from '../../config/settings';


interface RenderProps {
  placeholder: string,
  input: any,
  autocomplete: string,
  meta: {
    touched: boolean,
    error: boolean,
    active: boolean,
    invalid: boolean,
    submitFailed: boolean,
    pristine: boolean
  },
  label: string,
  defaultValue: string,
  value: string,
  className: string,
  fields: any,
  showsubmitfailed?: boolean,
  inputProps: any
}

interface Input {
  name: string,
  value: string,
  onChange?: Function
}

interface SelectBoxRenderProps extends RenderProps {
  options: Option[],
  required: boolean,
  disabled: boolean,
  type: string
}

interface Option {
  id: string,
  text: string,
  selected?: boolean
}

const ArrowDownIcon = () => {
  const classes = useStyles();

  return <img src={urlJoin(settings.staticFiles.endpoint,'/images/system/ChevronDown.svg')} className={classes.iconStyle} alt="" />
}

const LockIcon = () => {
  const classes = useStyles();

  return <img src={urlJoin(settings.staticFiles.endpoint,'/images/system/lock.svg')} className={classes.iconStyle} alt="" />
}

// Added to prevent downtime during release 2021.6.0
const getTooltipFieldName = (input:any) => {
  let tooltipFieldName = input.name;

  if (input.name === 'phone_number_cleaned') {
    tooltipFieldName = 'phone_number';
  }

  if (input.name === 'phone_calling_code') {
    tooltipFieldName = 'calling_code';
  }

  return tooltipFieldName;
}

export const LabeledTextbox: React.FC<RenderProps & TextFieldProps & BaseTextFieldProps> = ({
    className,  meta: { error, active, invalid }, required, inputProps,
    input, label, placeholder, type, autocomplete, defaultValue, showsubmitfailed, ...rest
  }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
      <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row" className={classes.marginTop}>
        <Grid item xs={12}>
          {label && (
            <InputLabel shrink htmlFor={input.name} required={required} className={classes.editText}>
              {label} {inputProps && inputProps.usetooltip && <ToolTip key="test" toolTip={t(`user_profile.${getTooltipFieldName(input)}.tooltip`)} type="information"/>}
            </InputLabel>
          )}
          {error && (
            <div className={classes.labelError}>{(invalid && Array.isArray(error) ? error : t(`${error}`))}</div>
          )}
          {active && inputProps && inputProps.maxLength && (inputProps.maxLength === (defaultValue && defaultValue.length)) && (
            <div className={classes.labelError}>{t(`validation_messages.max_len`, { property_name: label, max_len: inputProps.maxLength })}</div>
          )}
           {type === 'password' && active && (
              <ReactIsCapsLockActive>
                  {(isActive: boolean) => isActive && type==='password' ? <div className={classes.labelError}>{t('caps_lock_on')}</div> : null}
              </ReactIsCapsLockActive>
            )}
        </Grid>
       
                  
        <Grid item xs={12}>
          <TextField  {...input} {...rest}
            className={`${className} ${classes.element} ${error ? classes.ErrorShadow : ''}`}
            type={type}
            autoComplete={autocomplete}
            value={defaultValue}
            inputProps={{...inputProps}}
          />
        </Grid>
      </Grid>
    )
};

export const LabeledDisabledTextboxWithIcon: React.FC<RenderProps & TextFieldProps & BaseTextFieldProps> = ({
  className, required, inputProps,
  input, label, placeholder, type, autocomplete, defaultValue, showsubmitfailed, ...rest
}) => {
  const classes = useStyles(theme);
  
  return (
    <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row" className={classes.marginTop}>
      <Grid item xs={12}>
        {label && (
          <InputLabel shrink htmlFor={input.name} required={required} className={classes.editText}>
            {label}
          </InputLabel>
        )}
      </Grid>
     
                
      <Grid item xs={12}>
        
        <TextField  {...input} {...rest}
          className={`${className} ${classes.element}`}
          type={type}
          value={defaultValue}
          autoComplete={autocomplete}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" >
                <i
                  className={classes.lock}
                />
              </InputAdornment>
            ),
            classes: {
              root: classes.grayTextRoot,
              disabled: classes.disabled
            }
          }}
        />
      </Grid>
    </Grid>
  )
};

export const LabeledSelectbox: React.FC<SelectBoxRenderProps> = ({
  className, meta: { error }, required, inputProps, input, label, type,
  value, defaultValue, options, placeholder, disabled, ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  return (
      <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row" className={classes.marginTop}>
        <Grid item xs={12}>
          {label && (
            <InputLabel shrink htmlFor={input.name} required={required} className={classes.editText}>
              {label} {inputProps && inputProps.usetooltip && !disabled && <ToolTip key="test" toolTip={t(`user_profile.${getTooltipFieldName(input)}.tooltip`)} type="information"/>}
            </InputLabel>
          )}
           {error && (
            <div className={classes.labelError}>{error}</div>
          )}
        </Grid>
        <Grid item xs={12}>
          <Select 
            inputProps={{ 
              name: input.name, 
              id: input.name,
              translate: 'no'
            }}  
            
            value={typeof(defaultValue) === 'string' ? [defaultValue] : defaultValue}
            onChange={(event) => input.onChange(event.target)}
            className={`${className} ${classes.element} ${classes.select} select ${error ? classes.ErrorShadow : ''}`}
            IconComponent={disabled ? LockIcon : ArrowDownIcon}
            disabled={disabled}
            multiple={inputProps?.isMultiSelect || false}
            {...rest}
            >
              {!inputProps?.isMultiSelect &&
                <MenuItem key='unselected' value=''>&nbsp;</MenuItem>
              }
             {(options || []).map((option => { 
                  return <MenuItem key={option.id} value={option.id}>{parse(option.text)}</MenuItem>;
              }))}   
            </Select>
        </Grid>
    </Grid>
  )
};

export const LabeledDisplayText: React.FC<RenderProps> = ({
  className, label, value, defaultValue, ...rest
}) => {

  const classes = useStyles();

    return (
      <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row" className={classes.gridMargin}>
        <Grid item xs={12} sm={12} md={4}>
          {label && (
            <InputLabel shrink className={`${classes.label} ${classes.text}`}>
              {label}:
            </InputLabel>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          { defaultValue && (
            <span className={classes.text} >
            {parse(defaultValue)}
            </span>
          )}
        </Grid>
      </Grid>
    )
};

