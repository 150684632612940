import React, { useState, useEffect, Dispatch } from 'react';
import queryString from 'query-string';
import settings from '../../config/settings';
import Footer from '../Footer/Footer';
import { Application, getApplication } from '../../api/application';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import parse from 'html-react-parser';
import { Container, Grid } from '@material-ui/core';
import { useSharedStyles } from '../../sharedStyles/Styles';
import { Spinner } from '@danfoss/webex-ui';
import Information from '../Information/Information';
import { pushEvent } from '../../Services/AnalyticsService';
import { goToApplication } from '../../utils/applications';
import LogoAndBackground from '../Header/LogoAndBackground';
import { useAuthentication } from '../../Providers/AuthenticationProvider';
import { AppState } from '../../store';
import { cultureChange, CultureChange } from '../../store/cultures/actions';
import { connect } from 'react-redux';
import { getUserLanguage } from '../../utils/userLanguage';
import DOMPurify from 'dompurify';

const getCustomErrorContent = (errorDescription: string, application: Application, goToApp: Function, t: Function) => {
    if (errorDescription.toLowerCase() === 'access expired.') {
        return <Information
            handleButtonClick={() => goToApp}
            headerText={t('custom_error_page.access_expired.header')}
            bodyText={parse(t('custom_error_page.access_expired.body'))}
            detailedErrorDescription=""
            buttonText={parse(t('custom_error_page.button', { application: (application.translations?.name || application.name || '') }))}
            dip_app_type={application.dip_app_type}
        />
    } else if (errorDescription.toLowerCase().startsWith('password login via oidc-conformant clients')) {
        return <Information
            handleButtonClick={() => goToApp}
            headerText={t('custom_error_page.login_expired.header')}
            bodyText={parse(t('custom_error_page.login_expired.body'))}
            detailedErrorDescription=""
            buttonText={parse(t('custom_error_page.button_restart_login'))}
            dip_app_type={application.dip_app_type}
        />
    } else if (errorDescription.toLowerCase() !== 'access expired.' && !errorDescription.toLowerCase().startsWith('password login via oidc-conformant clients')) {
        return <Information
            handleButtonClick={() => goToApp}
            headerText={t('custom_error_page.custom_error.header')}
            bodyText={parse(t('custom_error_page.custom_error.body'))}
            detailedErrorDescription={parse(errorDescription || '')}
            buttonText={parse(t('custom_error_page.button', { application: (application.translations?.name || application.name || '') }))}
            dip_app_type={application.dip_app_type}
        />
    }
}

const getRuleErrorContent = (errorDescription: string, errorCode: string, application: Application, goToApp: Function, t: Function) => {
    if (errorCode) {

        const headerText = i18n.exists(`custom_error_page.${errorCode}`) ? t(`custom_error_page.${errorCode}.header`) : t('custom_error_page.unknown_error.header', { error_code: errorCode });
        const bodyText = i18n.exists(`custom_error_page.${errorCode}`) ? parse(t(`custom_error_page.${errorCode}.body`)) : parse(t('custom_error_page.unknown_error.body'));

        return <Information
            handleButtonClick={() => goToApp}
            headerText={headerText}
            bodyText={bodyText}
            detailedErrorDescription={parse(errorDescription || '')}
            buttonText={parse(t('custom_error_page.button', { application: (application.translations?.name || application.name || '') }))}
            dip_app_type={application.dip_app_type}
        />
    } else {
        return <Information
            handleButtonClick={() => goToApp}
            headerText={t('custom_error_page.unknown_error.header', { error_code: errorCode })}
            bodyText={parse(t('custom_error_page.unknown_error.body'))}
            detailedErrorDescription={parse(errorDescription || '')}
            buttonText={parse(t('custom_error_page.button', { application: (application.translations?.name || application.name || '') }))}
            dip_app_type={application.dip_app_type}
        />
    }
}

const getContent = (errorType: string, errorCode: string, errorDescription: string, application: Application, goToApp: Function, t: Function) => {


    if (errorType === 'login_error') {
        return <Information
            handleButtonClick={() => goToApp}
            headerText={t('custom_error_page.login_error.header')}
            bodyText={parse(t('custom_error_page.login_error.body'))}
            detailedErrorDescription={parse(errorDescription || '')}
            expandDetailedErrorDescription={true}
            buttonText={parse(t('custom_error_page.button', { application: (application.translations?.name || application.name || '') }))}
            dip_app_type={application.dip_app_type}
        />
    } else if (errorType === 'blocked_user' && errorDescription.toLowerCase() === 'user is blocked') {
        return <Information
            handleButtonClick={() => goToApp}
            headerText={t('custom_error_page.blocked_user.header')}
            bodyText={parse(t('custom_error_page.blocked_user.body'))}
            detailedErrorDescription=""
            buttonText={parse(t('custom_error_page.button', { application: (application.translations?.name || application.name || '') }))}
            dip_app_type={application.dip_app_type}
        />
    } else if (errorType === 'custom_error') {
        return getCustomErrorContent(errorDescription, application, goToApp, t)
    } else if (errorType === 'saml_error') {
        return <Information
            handleButtonClick={() => goToApp}
            headerText={t('custom_error_page.saml_error.header')}
            bodyText={parse(t('custom_error_page.saml_error.body'))}
            detailedErrorDescription={parse(errorDescription || '')}
            buttonText={parse(t('custom_error_page.button', { application: (application.translations?.name || application.name || '') }))}
            dip_app_type={application.dip_app_type}
        />
    } else if (errorType === 'rule_error') {
        return getRuleErrorContent(errorDescription, errorCode, application, goToApp, t);
    } else {
        return getCustomErrorContent(errorDescription, application, goToApp, t)
    }
}

const ErrorPage: React.FC<Props> = ({ profile, cultures, changeCulture }) => {
    const { t } = useTranslation();
    const classes = useSharedStyles();
    const { logoutWithRedirect } = useAuthentication();

    const [application, setApplication] = useState({} as Application);
    const [errorDescription, setErrorDescription] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [errorType, setErrorType] = useState('');
    const [loading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);

        //Get querystring parameters
        const parsed = queryString.parse(window.location.search);
        const returnFromLogout = DOMPurify.sanitize(parsed.returnFromLogout as string);
        // Do a logout of the user to invalidate unfinished redirect from rule. With seamless SSO eneabled the user will continue with any unfinished
        // when he goes to the application and will not be asked to loin again   
        if (!(returnFromLogout && returnFromLogout === 'true')) {
            sessionStorage.setItem("redirectAfterLogout", `${window.location.pathname}${window.location.search}&returnFromLogout=true${window.location.hash}`);
            return logoutWithRedirect();
        }

        const lan = getUserLanguage(profile, cultures.cultures)

        changeCulture(lan);

        const getApp = async () => {

            // take clientId if part of querystring otherwise default to My Profile clientId
            // @ts-ignore
            const cId = DOMPurify.sanitize(parsed.client_id) || settings.auth0.clientId;

            setApplication(await getApplication(cId as string, lan));
            setErrorCode((DOMPurify.sanitize(parsed.error_code as string)).toLowerCase());
            setErrorType(DOMPurify.sanitize(parsed.error_type as string));
            setErrorDescription(DOMPurify.sanitize(parsed.error_description as string));
            setIsLoading(false);
        }

        getApp();

        // Push an event that a user entered the errorpage page
        pushEvent(
            {
                'event': 'virtualPageview',
                'page': `${(window as any).location.hostname}/error/${errorType}/${errorDescription}`
            }
        );

    }, []);

    const goToApp = () => {
        goToApplication(application);
    }

    return loading ? (<Spinner visible={loading} />) : (
        <React.Fragment>
            <LogoAndBackground classes={classes} />
            <Container className={classes.containerRoot}>
                <Container maxWidth="md" className={`${classes.outerContainer} ${classes.relativeContainer}`}>
                    <Grid container className={classes.contentContainerRoot}>
                        <Grid item xs={12}>
                            {getContent(errorType, errorCode, errorDescription, application, goToApp, t)}
                        </Grid>
                    </Grid>
                </Container>
            </Container>
            <Footer contentClass={classes.footerContent} />
        </React.Fragment>
    );
}

const mapStateToProps = (state: AppState) => ({
    cultures: state.cultures,
    profile: state.profile
});

const mapDispatchToProps = (dispatch: Dispatch<CultureChange>) => ({
    changeCulture: (newCulture: string) => dispatch(cultureChange(newCulture))
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
