import React from 'react';
import { Application } from '../../api/application';
import { goToApplication } from '../../utils/applications';
import { Container, Grid } from '@material-ui/core';

import Information from '../Information/Information';
import Resend from '../Verification/Resend';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

interface ConfirmationContentProps {
    classes: any;
    success: boolean;
    message: string;
    application: Application;
    usedOnceHeader: string;
    usedOnceBody: any | string;
    usedOnceButton: any | string;
    blockedHeader: string;
    blockedBody: any | string;
    blockedButton: any | string;
    expiredHeader?: string;
    expiredBody?: any | string;
    expiredButton?: any | string;
    showResendIfSuccessFalse?: boolean
    resendPassword?: Function
}

const ConfirmationContent: React.FC<ConfirmationContentProps> = ({ classes, success, message, application, usedOnceHeader, usedOnceBody, usedOnceButton, blockedHeader, blockedBody, blockedButton, expiredHeader, expiredBody, expiredButton, showResendIfSuccessFalse, resendPassword }) => {

    const { t } = useTranslation();

    const goToApp = () => {
        goToApplication(application);
    }

    return (
        <Container className={classes.containerRoot}>
            <Container maxWidth="md" className={`${classes.outerContainer} ${classes.relativeContainer}`}>
                <Grid container className={classes.contentContainerRoot}>
                    <Grid item xs={12}>
                        {!success && message === 'this url can be used only once' && !showResendIfSuccessFalse && (
                            <Information
                                handleButtonClick={() => goToApplication(application)}
                                headerText={usedOnceHeader}
                                bodyText={usedOnceBody}
                                buttonText={usedOnceButton}
                                dip_app_type={application.dip_app_type}
                            />
                        )}
                        {!success && message === 'this url can be used only once' && showResendIfSuccessFalse && (
                            <Resend
                                callback={() => resendPassword && resendPassword()}
                                headerText={usedOnceHeader}
                                bodyText={usedOnceBody}
                                buttonText={usedOnceButton}
                                links={application.dip_app_type !== 'client_app' ? [
                                    {
                                        linkText:`${parse(t('email_verification.congratulations.button', { application: (application.translations?.name || application.name || ''), url: application.url }))}`,
                                        onClick: () => goToApplication(application)
                                    }
                                ] : []}
                                type="passwordReset"
                                showPasswordField={false}
                            />
                        )}
                        {!success && message === 'access expired.' && showResendIfSuccessFalse && (
                            <Resend
                                callback={() => resendPassword && resendPassword()}
                                headerText={expiredHeader}
                                bodyText={expiredBody}
                                buttonText={expiredButton}
                                type="passwordReset"
                                showPasswordField={false}
                            />
                        )}
                        {success && (
                            <Information
                                handleButtonClick={() => goToApp}
                                headerText={blockedHeader}
                                bodyText={blockedBody}
                                buttonText={blockedButton}
                                dip_app_type={application.dip_app_type}
                            />
                        )}

                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
}

export default ConfirmationContent