import React, {useEffect, useState} from 'react';
import { AppState } from "../../store";
import { connect } from 'react-redux';
import { Profile } from '../../store/profile/types';
import { Cultures } from '../../store/cultures/types';
import settings from '../../config/settings';
import { useAuthentication } from '../../Providers/AuthenticationProvider';
import { getCookieInformationLangauges } from '../../api/localization';

interface CookieInformationProps {
    profile: Profile | undefined,
    cultures: Cultures,
  }

  // This function will select the cookie user settings from Cookieinformation
export const getCookieCategoriesAcceptance = () => {
    const cookieInstance = (window as any)["CookieInformation"];
    const functionalCookiesCategory = "cookie_cat_functional";
    const marketingCookiesCategory = "cookie_cat_marketing";
    const statisticCookiesCategory = "cookie_cat_statistic";

    if (!cookieInstance || !cookieInstance.getConsentGivenFor) {
        return {
            functional: false,
            marketing: false,
            statistic: false
        };
    }

    return {
        functional: cookieInstance.getConsentGivenFor(functionalCookiesCategory),
        marketing: cookieInstance.getConsentGivenFor(marketingCookiesCategory),
        statistic: cookieInstance.getConsentGivenFor(statisticCookiesCategory)
    };
}

const CookieInformation: React.FC<CookieInformationProps> = (props) => {
    
    const { webkitActive  } = useAuthentication();
    const [cookieConsentLoaded, setCookieConsentLoaded] = useState(false);
    const [currentCookieCulture, setCurrentCookieCulture] = useState('');
    let supportedLanguages:any;
    
    const getLanguageSupportedByCookieInformation = () => {
        const { cultures: {currentCulture} } = props;
        
        if (supportedLanguages[currentCulture] !== undefined) {
            return supportedLanguages[currentCulture]
        }

        return "en";
    }

    useEffect(() => {

        const getCookieInformationSupportedLanguages = async () => {
            supportedLanguages = await getCookieInformationLangauges();

            const currentSupportedCulture = getLanguageSupportedByCookieInformation();

            if (!webkitActive) {
                const scriptTag = document.createElement('script');
                scriptTag.setAttribute(
                'src',
                settings.cookieinformation.url
                );
                scriptTag.setAttribute('id', 'CookieConsent');
                scriptTag.setAttribute('type', 'text/javascript');
                scriptTag.setAttribute('async', '');
               
                // Add a generated nonce to the script include for Cookieinformation to use when adding inline scripts to our page                
                scriptTag.setAttribute('nonce', (document.getElementById('cookieNonce') as HTMLMetaElement).content);
                
                // Cookie Information specific
                scriptTag.setAttribute(
                'data-cbid',
                settings.cookieinformation.id
                );
                scriptTag.setAttribute('data-culture', currentSupportedCulture);
                scriptTag.setAttribute('data-gcm-enabled', 'false');

                const existingScriptTag = document.getElementById("CookieConsent");

                // Append it
                if (!cookieConsentLoaded) {
                    document.head.appendChild(scriptTag);
                }
                // Change culture on CookieConsent script tag and reload consent script
                if (cookieConsentLoaded && currentSupportedCulture !== currentCookieCulture && existingScriptTag) {
                    existingScriptTag.setAttribute('data-culture', currentSupportedCulture);

                    (window as any).CookieInformationScriptLoaded = false;
                    (window as any).CookieInformation && (window as any).CookieInformation.loadConsent()
                } 
                setCookieConsentLoaded(true);
                setCurrentCookieCulture(currentSupportedCulture);    

            }
            else {
                // Dispatch dummy CookieInformationConsentGiven event for native apps in order to disable all statistic and functional cookies
                const event = new Event("CookieInformationConsentGiven");
                window.dispatchEvent(event);
            }
        }

        getCookieInformationSupportedLanguages();

        
    }, [props.cultures.currentCulture]);

    return null;
}

const mapStateToProps = (state: AppState) => ({
    profile: state.profile,
    cultures: state.cultures
});

export default connect(mapStateToProps)(CookieInformation)
