import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';
import { urlJoin } from 'url-join-ts';
import settings from '../../config/settings';

export const useStyles = makeStyles(theme => ({
	root: {
		marginLeft: '0px',
		position: 'relative',
		flexDirection: 'column'
	},
	header: {
		fontSize: '36px',
		fontWeight: 'bold',
		letterSpacing: '0.14px',
		lineHeight: '48px',
		width: '100%',
		marginBottom: '11px',
		color: colors.webexuiDarkGrayish,
		[theme.breakpoints.up('sm')]: {
			marginLeft: '10px'
		}
	},
	mobileHeaderEdit: {
		fontSize: '28px',
		fontWeight: 'bold',
		letterSpacing: '0.14px',
		lineHeight: '48px',
		width: '100%',
		marginBottom: '5px',
		color: colors.webexuiDarkGrayish,
	},
	welcomeText: {
		color: '#575756',
		fontSize: '16px',
		lineHeight: '24px',
		maxWidth: '954px',
		[theme.breakpoints.up('sm')]: {
			marginLeft: '10px'
		}
	},
	welcomeTextSpinner: {
		height: '30px',
		width: '60px',
		position: 'relative',
		[theme.breakpoints.up('sm')]: {
			marginLeft: '10px'
		}
	},
	DashboardItemSpinner: {
		marginLeft: 0,
		marginTop: '19px',
		width: '60px',
		height: '30px',
		position: 'relative'
	},
	welcomeTextTopSpacer: {
		marginTop: '10px'
	},
	applicationContainer: {
		backgroundColor: colors.webexuiGraySoft,
		backgroundImage: `url(${urlJoin(settings.staticFiles.endpoint, "/images/system/AppDashboard_watermark.svg")})`,
		backgroundRepeat: 'no-repeat',
		backgroundPositionX: 'right',
		backgroundSize: 'contain',
		flexGrow: 1
	},
	applicationTextContainer: {
		backgroundColor: colors.webexuiWhite,
		flexWrap: 'nowrap',
		display: 'flex',
		justifyContent: 'center'
	},
	applicationTextSubContainer: {
		maxWidth: '1280px',
		[theme.breakpoints.up('lg')]: {
            margin: '42px 80px 50px 80px'
        },
		[theme.breakpoints.between('sm', 'md')]: {
            margin: '42px 40px 50px 40px'
        },
        [theme.breakpoints.only('xs')]: {
            margin: '42px 16px 50px 16px'
        },
		
		flexWrap: 'nowrap'
	},	
	innerContentMargin: {
		margin: '0 80px',
		alignItems: 'center',
		position: 'relative',
		alignContent: 'flex-start',
		[theme.breakpoints.between('sm', 'md')]: {
			margin: '0 40px'
		},
		[theme.breakpoints.only('xs')]: {
			margin: '0 16px'
		}
	},
	editPageMobileHeaderContainer: {
		margin: '15px 16px 0',
	},
	editPageMobiledescriptionContainer: {
		margin: '0 16px 20px',
	},
	applicationContainerMaxWidth: {
		position: 'relative',
		margin: 'auto',
		maxWidth: '1280px',
		marginBottom: '151px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: '10px'
		},
		[theme.breakpoints.only('xs')]: {
			marginBottom: '10px'
		}
	},
	applicationIconContainer: {
		margin: '18px 0px',
		[theme.breakpoints.only('xs')]: {
			width: '100%'
		},
		flexGrow: 1,
	},
	applicationTypeHeader: {
		margin: '41px 0 0 0',
		fontSize: '16px',
		fontWeight: 600
	},
	popup: {
		top: '233px',
		maxWidth: '650px',
		display: 'table',
		[theme.breakpoints.up('sm')]: {
			top: '400px'
		}
	},
	overlay: {
		[theme.breakpoints.only('xs')]: {
			marginTop: '10px',
			height: '100%'
		}
	},
	popupHeader: {
		color: colors.webexuiDarkGrayish,
		fontSize: '24px',
		fontWeight: 'bold',
		lineHeight: '25px',
		textAlign: 'left',
		margin: '26px 26px 26px 0'
	},
	popupContent: {
		fontSize: '16px',
		color: '#686868',
		marginBottom: '12px'
	},
	popupContainer: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		position: 'absolute',
		top: '-50px'
	},
	popupContainerMobile: {
		visibility: 'hidden'
	},
	popupContainerShow: {
		visibility: 'visible'
	},
	close: {
		cursor: 'pointer',
		width: '16px',
		[theme.breakpoints.only('xs')]: {
			margin: '10px -16px',
		},
		[theme.breakpoints.up('sm')]: {
			right: '16px'
		},
		position: 'absolute'
	},
	nativeAppContainer: {
		margin: '36px 26px 26px 0'
	},
	innerContentWidth: {
		maxWidth: '1280px',
		margin: 'auto',
		marginTop: '42px',
		marginBottom: '50px',
		backgroundColor: colors.webexuiWhite,
		position: 'relative'
	},
	banner: {
		backgroundColor: colors.webexuiWhite,
		boxShadow: '0px -4px 0px #E2000F',
		borderRadius: '0px 0px 2px 2px',
		"&:hover": {
			boxShadow: '0px -4px 0px #E2000F, 0px 12px 17px 2px rgba(66, 78, 84, 0.14)'
		}
	},
	bannerInactive: {
		backgroundColor: colors.webexuiWhite,
		boxShadow: '0px -4px 0px #B0B0B1',
		borderRadius: '0px 0px 2px 2px',
		"&:hover": {
			boxShadow: '0px -4px 0px #B0B0B1, 0px 12px 17px 2px rgba(66, 78, 84, 0.14)'
		}
	},
	bannerText: {
		fontSize: '20px',
		fontWeight: 600,
		lineHeight: '24px'
	},
	bannerTextSmallFont: {
		fontSize: '16px',
		fontWeight: 600,
		lineHeight: '24px'
	},
	bannerIconBorder: {
		borderRight: `1px solid ${colors.webexuiGraySoft}`,
	},
	backgroundImage: {
		width: '100%',
		height: '100%',
		verticalAlign: 'middle',
		borderStyle: 'none'
	},
	descriptionSpace: {
		marginLeft: '5px'
	},
	noApplicationsContainer: {
		width: '250px',
		position: 'absolute',
		left: 0,
		right: 0,
		top: '50%',
		margin: 'auto',
		textAlign: 'center'
	},
	noApplicationsTextContainer: {
		marginTop: '18px'
	},
	noApplicationsHeader: {
		fontSize: '18px',
		fontWeight: 600,
		lineHeight: '24px',
		letterSpacing: '0px',
		marginBottom: '8px'
	},
	noApplicationsBody: {
		fontSize: '16px',
		lineHeight: '20px',
		letterSpacing: '0px'
	},
	editItemContainer: {
		justifyContent: 'flex-end',
		[theme.breakpoints.up('sm')]: {
			alignItems: 'center',
			display: 'flex',
			minHeight: '100px',
			flexGrow: 1
		},
		[theme.breakpoints.down('xs')]: {
			display: 'grid',
    		justifyContent: 'center',
			minHeight: '100px'
		}
	},
	editItemSubContainer: {
		textAlign: 'center',
		minHeight: '80px',
		marginTop: '22px',
		[theme.breakpoints.only('xs')]: {
			marginTop: 0
		}
	},
	editButtonContainer: {
		[theme.breakpoints.up('sm')]: {
			textAlign: 'right',
		}
	},
	linkText: {
		fontSize: '16px',
		fontWeight: 600,
		letterSpacing: '0.18px',
		lineHeight: '28px',
		textDecoration: 'none',
		color: colors.webexuiDarkGrayish,
		cursor: 'pointer',
		'&::before': {
          content: '"\\203A\\0000A0"',
          fontSize: '26px',
          top: '2px',
          position: 'relative'
        }
	},
	itemContainer: {
		[theme.breakpoints.only('xs')]: {
			paddingTop: '30px'
		}
	},
	button: {
		[theme.breakpoints.only('xs')]: {
			margin: '0px 0px 10px !important'
		},
		whiteSpace: 'nowrap'
	},
	editButtonContainerShrink: {
		display: 'table-caption',
		[theme.breakpoints.only('xs')]: {
			marginBottom: '25px'
		},
	},
	editButtonContainerSmallScreen: {
        [theme.breakpoints.up('sm')]: {
          display: 'none'
        }
      },
      editButtonContainerBigScreen: {
        [theme.breakpoints.only('xs')]: {
          display: 'none'
        }
      },
	  dashboardText: {
		margin: '0 80px 0 0',
		[theme.breakpoints.only('xs')]: {
			margin: 0
		  }
	  },
	  leaveConfirmContainer: {
		top: '-50px',
		width: '100%',
		display: 'flex',
		position: 'absolute',
		justifyContent: 'center'
	  }
})
);