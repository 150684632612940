import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

const Logout: React.FC = () => {

	const nav = useNavigate();

	useEffect(() => {
		// Redirect to path saved in sessionStorage if existing - else redirect to root
		const redirectAfterLogout = sessionStorage.getItem('redirectAfterLogout');

		sessionStorage.removeItem('redirectAfterLogout');
		
		nav(redirectAfterLogout || "/");

	  }, []);

	return null; 
}

export default Logout;
